import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducers } from './ducks';
import { logoutRoutine } from './ducks/auth';

const combinedReducers = (history) => combineReducers({ router: connectRouter(history), ...reducers });

const rootReducer = (history) => (state, action) => {
  let newState = state;

  if (action.type === logoutRoutine.SUCCESS) {
    const { app, realm, firebase } = state;
    newState = { app, realm, firebase };
  }

  return combinedReducers(history)(newState, action);
};

export default rootReducer;
