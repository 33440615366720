import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import FullScreenButton from 'components/full-screen-components/full-screen-button';
import MoreMenuButton from 'components/full-screen-components/more-menu-button';
import SettingsMenu from './settings-menu';
import NavigationMenu from './navigation-menu';
import useStyles from './control-panel.styles';

const ControlPanel = memo((props) => {
  const { pdf } = props;
  const classes = useStyles();

  return (
    <Box className={classes.controlPanelContainer}>
      <FullScreenButton className={classes.controlButton} iconClassName={classes.controlIcon} />
      <SettingsMenu
        controlBtnProps={{
          className: classes.controlButton,
          iconClassName: classes.controlIcon,
        }}
      />
      <NavigationMenu
        pdf={pdf}
        controlBtnProps={{
          className: classes.controlButton,
          iconClassName: classes.controlIcon,
        }}
      />
      <MoreMenuButton
        className={classes.moreMenu}
        itemClassName={classes.moreMenuItem}
        textClassName={classes.moreMenuText}
        controlBtnProps={{
          className: classes.controlButton,
          iconClassName: classes.controlIcon,
        }}
      />
    </Box>
  );
});

ControlPanel.propTypes = {
  pdf: PropTypes.shape({}),
};

export default ControlPanel;
