import React, { memo, useState, useRef, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { queuePropType } from 'utils/prop-types';
import { CONTENT_WIDTH } from 'utils/themes/sizes';
import useWindowSize from 'hook/use-window-size';
import useMediaQuery from 'hook/use-media-query';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Virtual } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import useStyles, { FULL_PLAYER_HEADER, FULL_PLAYER_NAVIGATION, FULL_PLAYER_TIMELINE } from './gallery.styles';
import './gallery.styles.css';

// install Virtual module
SwiperCore.use([Virtual]);

const TEMP_SLIDES_PER_VIEW = 1.67;
const MAIN_SLIDES_PER_VIEW = 1.66;

const Gallery = memo((props) => {
  const isMobileFullPlayer = useMediaQuery('mobileFullPlayer');
  const { width, height } = useWindowSize();
  const { queue, activeIndex, changeActiveTrackIndexAction } = props;

  const swiperRef = useRef();

  const [swiper, setSwiper] = useState();
  const [slidesPerView, setSlidesPerView] = useState(TEMP_SLIDES_PER_VIEW);

  const cardSize = useMemo(() => {
    const maxContentWidth = Math.min(width, CONTENT_WIDTH);
    const availableWidth = isMobileFullPlayer ? maxContentWidth - 40 : maxContentWidth * 0.58;
    const availableHeight =
      height - FULL_PLAYER_HEADER - FULL_PLAYER_NAVIGATION - FULL_PLAYER_TIMELINE - (isMobileFullPlayer ? 100 : 0);

    if (swiper?.slidesSizesGrid?.[0] && !isMobileFullPlayer) {
      return Math.min(swiper.slidesSizesGrid[0], availableHeight);
    }

    return Math.min(availableWidth, availableHeight);
  }, [width, height]);

  const classes = useStyles({ cardSize });

  const handleItemClick = useCallback((swiperInst) => {
    if (swiperInst.clickedIndex >= 0 && swiperInst.clickedIndex !== swiperInst.activeIndex) {
      changeActiveTrackIndexAction(swiperInst.clickedIndex);
    }
  }, []);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeIndex);
    }
  }, [activeIndex]);

  // To display items correct on first render due to Virtual module
  useEffect(() => {
    if (queue.length === 1 || isMobileFullPlayer) {
      setSlidesPerView(1);
    } else {
      setSlidesPerView(MAIN_SLIDES_PER_VIEW);
    }
  }, [queue.length]);

  useEffect(() => {
    if (swiper) {
      swiper.on('click', handleItemClick);
    }
  }, [swiper]);

  return (
    <>
      <Box className={classes.galleryContainer}>
        <Swiper
          centeredSlides
          virtual={{
            addSlidesAfter: 1,
            addSlidesBefore: 1,
          }}
          ref={swiperRef}
          onSwiper={setSwiper}
          allowTouchMove={false}
          initialSlide={activeIndex}
          slidesPerView={slidesPerView}
          spaceBetween={slidesPerView === 1 ? 0 : 36}
        >
          {queue.map((trackItem, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              <CardMedia image={trackItem.image} className={classes.image} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </>
  );
});

Gallery.propTypes = {
  queue: queuePropType,
  activeIndex: PropTypes.number.isRequired,
  changeActiveTrackIndexAction: PropTypes.func.isRequired,
};

export default Gallery;
