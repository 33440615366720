import { firebaseReducer } from 'react-redux-firebase';
import { appReducer, watchAppSagas } from './app';
import { authReducer, watchAuthSagas } from './auth';
import { realmReducer, watchRealmSagas } from './realm';
import { playerReducer, watchPlayerSagas } from './player';
import { historyReducer, watchHistorySagas } from './history';
import { favoritesReducer, watchFavoritesSagas } from './favorites';

export const reducers = {
  app: appReducer,
  auth: authReducer,
  realm: realmReducer,
  player: playerReducer,
  history: historyReducer,
  firebase: firebaseReducer,
  favorites: favoritesReducer,
};

export const watchSagas = [
  watchAppSagas,
  watchAuthSagas,
  watchRealmSagas,
  watchPlayerSagas,
  watchHistorySagas,
  watchFavoritesSagas,
];
