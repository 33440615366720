import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  lineHeightContainer: {
    width: '100%',
    height: 44,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 41,
    paddingRight: 41,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 3.3,
  },
});

export default useStyles;
