import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  trackTimeline: {
    position: 'absolute',
    left: 0,
    top: '-3px',
    width: '100%',
    padding: '3px 0',
  },
  trackTimelineIndicator: {
    width: 18,
    height: 18,
    opacity: 0,
    marginTop: '-9px',
    transition: 'opacity 0.1s',
  },
  timeText: {
    opacity: 0,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '15px',
    position: 'absolute',
    fontFamily: 'ARSMaquetteProMedium',
    color: theme.palette.colors.surfaceOnColor,
    textShadow: '0 2px 8px rgba(255, 255, 255, 0.3)',
  },
  timeTextTopPosition: {
    top: '-24px',
  },
  timeTextBottomPosition: {
    top: 8,
  },
  currentTime: {
    left: 8,
  },
  totalTime: {
    right: 8,
  },
  onTimelineHover: {
    opacity: 1,
  },
}));

export default useStyles;
