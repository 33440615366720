import { useRef, useEffect } from 'react';

const disablePinchZoom = (e) => {
  if (e.touches.length > 1) {
    e.preventDefault();
  }
};

const useSuspendPinchZoom = ({ trigger } = {}) => {
  const ref = useRef(null);

  useEffect(() => {
    const target = ref.current;

    if (target) {
      target.addEventListener('touchmove', disablePinchZoom, { passive: false });
    }

    return () => {
      if (target) {
        target.removeEventListener('touchmove', disablePinchZoom);
      }
    };
  }, [trigger]);

  return ref;
};

export default useSuspendPinchZoom;
