import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import Play from './play';
import Glasses from './glasses';
import Download from './download';
import VolumeUp from './volume-up';
import Slideshow from './slideshow';
import ReaderTOC from './reader-toc';
import FullScreen from './full-screen';
import Headphones from './headphones';
import ReaderSettings from './reader-settings';
import ReaderBookmark from './reader-bookmark';
import ReaderBookmarkRemove from './reader-bookmark-remove';
import ReaderFontDecrease from './reader-font-decrease';
import ReaderFontIncrease from './reader-font-increase';
import ReaderBrightnessLow from './reader-brightness-low';
import ReaderBrightnessHigh from './reader-brightness-high';
import ReaderLineHeightLg from './reader-lineheight-lg';
import ReaderLineHeightSm from './reader-lineheight-sm';
import ReaderMarginXl from './reader-margin-xl';
import ReaderMarginLg from './reader-margin-lg';
import ReaderMarginMd from './reader-margin-md';
import ReaderMarginSm from './reader-margin-sm';
import ReaderThemeDark from './reader-theme-dark';
import ReaderThemeSepia from './reader-theme-sepia';
import ReaderThemeLight from './reader-theme-light';

const Icons = {
  Play,
  Glasses,
  VolumeUp,
  Download,
  Slideshow,
  ReaderTOC,
  FullScreen,
  Headphones,
  ReaderSettings,
  ReaderBookmark,
  ReaderBookmarkRemove,
  ReaderMarginSm,
  ReaderMarginMd,
  ReaderMarginLg,
  ReaderMarginXl,
  ReaderThemeDark,
  ReaderThemeSepia,
  ReaderThemeLight,
  ReaderFontDecrease,
  ReaderFontIncrease,
  ReaderLineHeightLg,
  ReaderLineHeightSm,
  ReaderBrightnessLow,
  ReaderBrightnessHigh,
};

export const PlaySmIcon = (props) => (
  <SvgIcon width="19" height="18" viewBox="0 0 19 18" {...props}>
    <g>
      <path
        d="M15.031 8.013L5.92 1.837c-.777-.527-1.828.047-1.828.993v12.337c0 .962 1.051 1.52 1.828.993l9.112-6.176c.686-.45.686-1.505 0-1.97z"
        transform="translate(-73.000000, -370.000000) translate(0.000000, -0.000000) translate(60.058594, 358.000000) translate(13.000000, 12.000000)"
      />
    </g>
  </SvgIcon>
);

export const PauseSmIcon = (props) => (
  <SvgIcon width="14" height="21" viewBox="0 0 14 21" {...props}>
    <g>
      <path
        d="M0 19.147V.853C0 .365.435 0 .951 0H4.05C4.565 0 5 .365 5 .853v18.294c0 .463-.435.853-.951.853H.95C.435 20 0 19.61 0 19.147zm9 0V.853C9 .365 9.41 0 9.956 0h3.088c.546 0 .956.365.956.853v18.294c0 .463-.41.853-.956.853H9.956C9.41 20 9 19.61 9 19.147z"
        transform="translate(-505.000000, -1012.000000) translate(0.000000, 969.000000) translate(422.000000, 34.000000) translate(69.000000, 8.500000) translate(14.000000, 1.000000)"
      />
    </g>
  </SvgIcon>
);

export const PlayIcon = (props) => (
  <SvgIcon width="56" height="56" viewBox="0 0 56 56" {...props}>
    <g>
      <path d="M28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0ZM21 18.7837V37.2127C21 38.6499 22.6521 39.4844 23.8733 38.6963L38.1919 29.4702C39.2694 28.798 39.2694 27.2217 38.1919 26.5262L23.8733 17.3002C22.6521 16.512 21 17.3697 21 18.7837Z"/>
    </g>
  </SvgIcon>
);

export const PauseIcon = (props) => (
  <SvgIcon width="56" height="56" viewBox="0 0 56 56" {...props}>
    <g>
      <path
        d="M28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28C0 12.536 12.536 0 28 0ZM24.2057 15H20.2228C19.559 15 19 15.4698 19 16.0962V39.6181C19 40.2132 19.559 40.7143 20.2228 40.7143H24.2057C24.8696 40.7143 25.4286 40.2132 25.4286 39.6181V16.0962C25.4286 15.4698 24.8696 15 24.2057 15ZM35.7705 15H31.8009C31.0984 15 30.5714 15.4698 30.5714 16.0962V39.6181C30.5714 40.2132 31.0984 40.7143 31.8009 40.7143H35.7705C36.4731 40.7143 37 40.2132 37 39.6181V16.0962C37 15.4698 36.4731 15 35.7705 15Z"
      />
    </g>
  </SvgIcon>
);

export const SpeakerIcon = (props) => (
  <SvgIcon viewBox="-4 -4 14 12" {...props}>
    <g>
      <path
        d="M7.32 0l.074.005c.296.034.563.223.601.627L8 .733v10.194c0 .473-.314.681-.648.699H7.28c-.216-.005-.432-.087-.57-.226l-.043-.048-3-3.039H.727c-.373 0-.68-.28-.722-.642L0 7.586V4.042c0-.373.28-.68.642-.722l.085-.005h2.94l3-3.056C6.8.108 7.02.014 7.245.001L7.32 0zm-.413 10.17l.001-8.727-2.784 2.922-3.032-.002-.002 2.9 3.033.001 2.784 2.906zm3.696-7.028c.689 1.065 1.033 1.955 1.033 2.67 0 .715-.345 1.612-1.036 2.69-.017.026-.036.051-.058.074-.075.079-.17.128-.27.146l-.076.008c-.127.003-.255-.042-.354-.136-.24-.228-.286-.594-.109-.874.482-.762.723-1.402.723-1.92 0-.515-.24-1.142-.717-1.88-.18-.276-.136-.64.103-.868.022-.021.046-.04.072-.057.063-.04.132-.065.202-.075l.07-.005c.162 0 .322.08.417.227zm-.943 1.56c.238.424.357.786.357 1.086 0 .304-.121.676-.364 1.118-.021.038-.048.073-.079.103-.058.056-.127.093-.2.111l-.074.012c-.1.007-.2-.02-.285-.08L8.955 7l-.022-.023c-.204-.21-.262-.52-.148-.79.064-.15.096-.284.096-.399 0-.11-.03-.233-.088-.37-.102-.24-.068-.513.084-.719l.078-.088c.03-.031.064-.057.1-.078.044-.024.089-.04.134-.05l.069-.007c.16-.005.318.077.402.226z"
        transform="translate(-966.000000, -1020.000000) translate(0.000000, 969.000000) translate(896.000000, 39.000000) translate(58.000000, 0.000000) translate(8.181818, 8.177557)"
      />
    </g>
  </SvgIcon>
);

export const SkipLeftIcon = (props) => (
  <SvgIcon width="10" height="11" viewBox="0 0 10 11" {...props}>
    <g>
      <path
        d="M5.785.744v2.454L.495.228C.444.2.387.186.33.186.273.185.216.2.165.23.06.287 0 .39 0 .507v6.258c0 .117.061.22.165.279.103.058.226.058.33 0l5.289-2.97v2.454c0 .411.333.745.744.745s.745-.334.745-.745V.744C7.273.333 6.94 0 6.529 0c-.41 0-.744.333-.744.744z"
        transform="translate(-423.000000, -1017.000000) translate(0.000000, 969.000000) translate(422.000000, 34.000000) translate(1.000000, 14.500000) translate(5.000000, 5.000000) rotate(-180.000000) translate(-5.000000, -5.000000) translate(1.363636, 1.363636)"
      />
    </g>
  </SvgIcon>
);

export const SkipRightIcon = (props) => (
  <SvgIcon width="10" height="11" viewBox="0 0 10 11" {...props}>
    <g>
      <path
        d="M7.149 2.107v2.455l-5.29-2.97c-.052-.029-.108-.043-.165-.043-.057 0-.114.014-.165.044-.104.058-.165.161-.165.278v6.258c0 .117.061.22.165.278.103.059.226.059.33 0l5.288-2.969v2.454c0 .411.334.744.745.744s.744-.333.744-.744V2.107c0-.41-.333-.743-.743-.743-.411 0-.744.333-.744.743z"
        transform="translate(-591.000000, -1017.000000) translate(0.000000, 969.000000) translate(422.000000, 34.000000) translate(169.000000, 14.500000)"
      />
    </g>
  </SvgIcon>
);

export const Backward30Icon = (props) => (
  <SvgIcon width="23" height="23" viewBox="0 0 23 23" {...props}>
    <g>
      <g>
        <path
          d="M7.092.87c.02.032.032.07.032.109v1.474c4.876.21 8.765 4.23 8.765 9.158 0 3.55-2.062 6.282-6.184 8.192l-.34.153c-.05.023-.109 0-.131-.05-.02-.045-.005-.097.036-.124 3.477-2.344 5.276-5.068 5.397-8.17.165-4.242-3.333-7.726-7.544-7.935v1.036c0 .11-.089.2-.2.2-.038 0-.076-.011-.108-.032L3.926 3.014c-.092-.06-.119-.184-.059-.277.015-.023.036-.044.06-.06L6.814.812c.093-.06.217-.034.277.059zm-3.667 0c.02.032.032.07.032.109v3.734c0 .11-.09.2-.2.2-.039 0-.076-.011-.109-.032L.26 3.014C.167 2.954.14 2.83.2 2.737c.016-.023.036-.044.06-.06L3.148.812c.093-.06.217-.034.277.059z"
          transform="translate(-459.000000, -1011.000000) translate(0.000000, 969.000000) translate(422.000000, 34.000000) translate(37.461538, 8.500000) translate(3.666667, 1.222222)"
        />
      </g>
      <path
        d="M2.946 11.642h-.33v-.593h.342c.464 0 .703-.238.703-.58 0-.343-.257-.545-.685-.545-.397 0-.709.172-.923.502l-.537-.337c.36-.519.861-.788 1.497-.788.813 0 1.369.452 1.369 1.088 0 .428-.245.727-.6.898.477.184.722.556.722.984 0 .685-.562 1.247-1.51 1.247-.788 0-1.295-.349-1.582-.905l.623-.293c.183.379.52.574.96.574.5 0 .776-.262.776-.653 0-.367-.27-.6-.825-.6zm5.182-.239c0 1.051-.355 2.115-1.534 2.115-1.173 0-1.522-1.064-1.522-2.115 0-1.045.349-2.108 1.522-2.108 1.18 0 1.534 1.063 1.534 2.108zm-.734 0c0-.825-.17-1.51-.8-1.51s-.8.685-.8 1.51c0 .831.17 1.522.8 1.522s.8-.69.8-1.522zM11.58 12.467c0 .63-.513 1.05-1.368 1.05-.654 0-1.186-.25-1.491-.72l.476-.336c.245.342.636.507 1.051.507.398 0 .666-.147.666-.428 0-.293-.293-.354-.812-.44-.673-.116-1.21-.306-1.21-.966 0-.666.592-.971 1.259-.971.647 0 1.118.28 1.38.562l-.427.391c-.214-.244-.556-.403-.96-.403-.305 0-.598.11-.598.36 0 .281.342.343.763.416.685.122 1.272.3 1.272.978z"
        transform="translate(-459.000000, -1011.000000) translate(0.000000, 969.000000) translate(422.000000, 34.000000) translate(37.461538, 8.500000) translate(3.666667, 1.222222)"
      />
    </g>
  </SvgIcon>
);

export const Forward30Icon = (props) => (
  <SvgIcon width="23" height="23" viewBox="0 0 23 23" {...props}>
    <g>
      <g>
        <path
          d="M7.092.87c.02.032.032.07.032.109v1.474c4.876.21 8.765 4.23 8.765 9.158 0 3.55-2.062 6.282-6.184 8.192l-.34.153c-.05.023-.109 0-.131-.05-.02-.045-.005-.097.036-.124 3.477-2.344 5.276-5.068 5.397-8.17.165-4.242-3.333-7.726-7.544-7.935v1.036c0 .11-.089.2-.2.2-.038 0-.076-.011-.108-.032L3.926 3.014c-.092-.06-.119-.184-.059-.277.015-.023.036-.044.06-.06L6.814.812c.093-.06.217-.034.277.059zm-3.667 0c.02.032.032.07.032.109v3.734c0 .11-.09.2-.2.2-.039 0-.076-.011-.109-.032L.26 3.014C.167 2.954.14 2.83.2 2.737c.016-.023.036-.044.06-.06L3.148.812c.093-.06.217-.034.277.059z"
          transform="translate(-542.000000, -1011.000000) translate(0.000000, 969.000000) translate(422.000000, 34.000000) translate(120.538462, 8.500000) translate(10.388889, 11.611111) scale(-1, 1) translate(-10.388889, -11.611111) translate(2.444444, 1.222222)"
        />
      </g>
      <path
        d="M7.834 12.864h-.33v-.593h.343c.464 0 .702-.238.702-.58 0-.343-.256-.544-.684-.544-.397 0-.709.17-.923.5l-.538-.335c.361-.52.862-.789 1.498-.789.812 0 1.369.453 1.369 1.088 0 .428-.245.727-.6.898.477.184.722.557.722.984 0 .685-.562 1.247-1.51 1.247-.788 0-1.295-.348-1.582-.904l.623-.294c.183.38.52.575.96.575.5 0 .775-.263.775-.654 0-.367-.268-.6-.825-.6zm5.183-.238c0 1.05-.355 2.114-1.534 2.114-1.174 0-1.522-1.063-1.522-2.114 0-1.045.348-2.109 1.522-2.109 1.18 0 1.534 1.064 1.534 2.109zm-.734 0c0-.825-.17-1.51-.8-1.51s-.8.685-.8 1.51c0 .83.17 1.521.8 1.521s.8-.69.8-1.521zM16.47 13.689c0 .63-.514 1.051-1.37 1.051-.653 0-1.185-.25-1.49-.721l.476-.336c.245.342.636.507 1.051.507.397 0 .666-.147.666-.428 0-.293-.293-.354-.812-.44-.673-.116-1.21-.305-1.21-.965 0-.666.592-.972 1.258-.972.648 0 1.119.281 1.382.562l-.428.391c-.214-.244-.556-.403-.96-.403-.305 0-.599.11-.599.36 0 .282.343.343.764.416.685.122 1.271.3 1.271.978z"
        transform="translate(-542.000000, -1011.000000) translate(0.000000, 969.000000) translate(422.000000, 34.000000) translate(120.538462, 8.500000)"
      />
    </g>
  </SvgIcon>
);

export const QueueListIcon = (props) => (
  <SvgIcon width="16" height="16" viewBox="-4 -4 14 12" {...props}>
    <g>
      <path
        d="M13.818 9.455c.402 0 .727.325.727.727 0 .401-.325.727-.727.727H.728c-.402 0-.728-.326-.728-.727 0-.402.326-.727.727-.727h13.091zm0-4.364c.402 0 .727.326.727.727 0 .402-.325.727-.727.727H.728C.325 6.545 0 6.22 0 5.818c0-.401.326-.727.727-.727h13.091zM1.355.05l2.078 1.09c.126.066.203.185.203.314 0 .128-.077.247-.203.312L1.355 2.858c-.064.033-.138.051-.212.051-.072 0-.142-.016-.204-.047-.13-.064-.211-.185-.212-.317V.364c0-.131.08-.252.21-.316.13-.065.29-.064.418.003zm12.463.676c.402 0 .727.326.727.728 0 .401-.325.727-.727.727H6.545c-.401 0-.727-.326-.727-.727 0-.402.326-.728.727-.728h7.273z"
        transform="translate(-902.000000, -1020.000000) translate(0.000000, 969.000000) translate(896.000000, 39.000000) translate(0.727273, 8.909091)"
      />
    </g>
  </SvgIcon>
);

export const SpeedIcon = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <g>
      <path d="M16 4.558c-8.109 0-14.702 6.531-14.702 14.562 0 2.85 0.735 5.412 2.207 7.688 0.156 0.239 0.422 0.394 0.724 0.394 0.201 0 0.386-0.069 0.532-0.184l-0.002 0.001c0.234-0.185 0.382-0.468 0.382-0.786 0-0.193-0.055-0.373-0.149-0.526l0.002 0.004c-1.211-1.984-1.815-3.879-1.815-5.683h2.723c0.503 0 0.911-0.408 0.911-0.911s-0.408-0.911-0.911-0.911v0h-2.723c0.207-2.873 1.347-5.445 3.115-7.451l-0.012 0.014 1.921 1.907c0.167 0.165 0.396 0.267 0.65 0.267s0.481-0.101 0.648-0.266l-0 0 0.004-0.002c0.165-0.162 0.268-0.388 0.268-0.637 0-0.251-0.104-0.479-0.271-0.641l-0-0-0.004-0.002-1.923-1.921c2.023-1.737 4.618-2.861 7.468-3.056l0.040-0.002v2.689c0 0.502 0.408 0.91 0.912 0.91h0.016c0.001 0 0.001 0 0.002 0 0.502 0 0.91-0.407 0.91-0.91 0 0 0 0 0 0v0-2.687c2.89 0.197 5.485 1.32 7.525 3.072l-0.017-0.014-1.923 1.919-0.004 0.002c-0.168 0.162-0.272 0.389-0.272 0.641 0 0.25 0.102 0.475 0.268 0.637l0 0 0.004 0.002c0.361 0.353 0.937 0.353 1.296-0.002l1.923-1.907c1.757 1.992 2.897 4.566 3.101 7.398l0.002 0.041h-2.723c-0.503 0-0.911 0.408-0.911 0.911s0.408 0.911 0.911 0.911v0h2.723c-0.139 1.939-0.751 3.83-1.831 5.675-0.088 0.147-0.139 0.324-0.139 0.513 0 0.324 0.152 0.613 0.388 0.798l0.002 0.002c0.14 0.11 0.32 0.177 0.514 0.177 0.3 0 0.563-0.158 0.711-0.395l0.002-0.004c1.488-2.423 2.231-4.983 2.231-7.678 0-8.031-6.592-14.562-14.702-14.562zM23.727 16.339l-0.020 0.014-9.331 6.167c-0.416 0.409-0.674 0.978-0.674 1.607s0.258 1.198 0.673 1.607l0 0c0.417 0.413 0.99 0.667 1.623 0.667s1.206-0.255 1.623-0.668l-0 0 6.235-9.26c0.009-0.015 0.015-0.032 0.015-0.051 0-0.053-0.043-0.096-0.096-0.096-0.019 0-0.036 0.005-0.051 0.014l0-0z" />
    </g>
  </SvgIcon>
);

export const ExpandICon = (props) => (
  <SvgIcon width="22" height="22" {...props}>
    <g>
      <path d="M4.75 19.5a2.75 2.75 0 0 1-2.745-2.582L2 16.75v-8h1.5v8c0 .647.492 1.18 1.122 1.244L4.75 18h13a1.25 1.25 0 0 0 1.244-1.122L19 16.75v-11a1.25 1.25 0 0 0-1.122-1.244L17.75 4.5h-10V3h10a2.75 2.75 0 0 1 2.745 2.582l.005.168v11a2.75 2.75 0 0 1-2.582 2.745l-.168.005h-13zm5.554-7.147-.084-.073L3.5 5.561V6.75a.75.75 0 0 1-.648.743L2.75 7.5a.75.75 0 0 1-.743-.648L2 6.75V3.72l.004-.043L2 3.75a.754.754 0 0 1 .301-.601l-.081.07a.753.753 0 0 1 .428-.212L2.75 3h3a.75.75 0 0 1 .102 1.493L5.75 4.5H4.561l6.72 6.72a.75.75 0 0 1-.977 1.133z" />
    </g>
  </SvgIcon>
);

export const BookmarkIcon = (props) => (
  <SvgIcon width="22" height="23" viewBox="0 0 22 23" {...props}>
    <g>
      <path
        d="M16.417 2.75H5.583c-.552 0-1 .448-1 1v13.983c0 .553.448 1 1 1 .136 0 .27-.027.394-.08L11 16.5l5.023 2.153c.507.217 1.095-.018 1.313-.526.053-.124.08-.258.08-.394V3.75c0-.552-.447-1-1-1zm-.834 13.75L11 14.502 6.417 16.5V4.583h9.166V16.5z"
        transform="translate(-471.000000, -574.000000) translate(-94.000000, 452.000000) translate(462.000000, 55.000000) translate(100.000000, 64.634831) translate(3.000000, 2.921348)"
      />
    </g>
  </SvgIcon>
);

export const FilledBookmarkIcon = (props) => (
  <SvgIcon width="22" height="23" viewBox="0 0 22 23" {...props}>
    <g>
      <path
        d="M 16.417 2.75 H 5.583 c -0.552 0 -1 0.448 -1 1 v 13.983 c 0 0.553 0.448 1 1 1 c 0.136 0 0.27 -0.027 0.394 -0.08 L 11 16.5 l 5.023 2.153 c 0.507 0.217 1.095 -0.018 1.313 -0.526 c 0.053 -0.124 0.08 -0.258 0.08 -0.394 V 3.75 c 0 -0.552 -0.447 -1 -1 -1 z z"
        transform="translate(-471.000000, -574.000000) translate(-94.000000, 452.000000) translate(462.000000, 55.000000) translate(100.000000, 64.634831) translate(3.000000, 2.921348)"
      />
    </g>
  </SvgIcon>
);

export const InfoIcon = (props) => (
  <SvgIcon width="23" height="22" viewBox="0 0 23 22" {...props}>
    <g>
      <path
        d="M11.005 1.63c-5.177 0-9.375 4.198-9.375 9.375s4.198 9.375 9.375 9.375 9.375-4.198 9.375-9.375-4.198-9.375-9.375-9.375zM11 18.5c-4.141 0-7.5-3.359-7.5-7.5S6.859 3.5 11 3.5s7.5 3.359 7.5 7.5-3.359 7.5-7.5 7.5z"
        transform="translate(-290.000000, -368.000000) translate(0.000000, -0.000000) translate(280.273438, 358.000000) translate(10.020508, 10.000000)"
      />
      <path
        d="M10 7c0 .552.448 1 1 1s1-.448 1-1-.448-1-1-1-1 .448-1 1zm2 3h-2v6h2v-6z"
        transform="translate(-290.000000, -368.000000) translate(0.000000, -0.000000) translate(280.273438, 358.000000) translate(10.020508, 10.000000)"
      />
    </g>
  </SvgIcon>
);

export const SearchIcon = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g>
      <path
        d="M18.724 18.193l-3.08-3.08c.982-1.308 1.512-2.9 1.51-4.536C17.155 6.4 13.756 3 9.578 3S2 6.4 2 10.577c0 4.178 3.4 7.578 7.577 7.578 1.636.001 3.228-.529 4.536-1.511l3.08 3.08c.43.383 1.083.365 1.49-.042.406-.406.424-1.06.041-1.489zM9.5 5c3.038 0 5.5 2.462 5.5 5.5S12.538 16 9.5 16c-3.036-.004-5.496-2.464-5.5-5.5C4 7.462 6.462 5 9.5 5z"
        transform="translate(-499.000000, -39.000000) translate(-1.000000, 0.000000) translate(500.000000, 39.000000)"
      />
    </g>
  </SvgIcon>
);

export const TimeIcon = (props) => (
  <SvgIcon width="22" height="23" viewBox="0 0 22 23" {...props}>
    <path
      fillRule="nonzero"
      d="M9.99.625C4.817.625.626 4.825.626 10s4.19 9.375 9.366 9.375c5.184 0 9.384-4.2 9.384-9.375S15.175.625 9.991.625zM10 17.5c-4.144 0-7.5-3.356-7.5-7.5S5.856 2.5 10 2.5s7.5 3.356 7.5 7.5-3.356 7.5-7.5 7.5zM9.766 5.312c-.389 0-.704.315-.704.704v4.921l4.343 2.606c.32.192.736.09.93-.229.193-.315.093-.725-.221-.917l-.007-.004-3.638-2.159V6.016c0-.389-.315-.704-.703-.704z"
      transform="translate(1.000000, 1.000000)"
    />
  </SvgIcon>
);

export const BroadcastIcon = (props) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <defs>
      <filter id="67n2c176ia" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.090196 0 0 0 0 0.090196 0 0 0 0 0.090196 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g>
      <g>
        <g>
          <g transform="translate(-693.000000, -32.000000) translate(682.000000, 21.000000) translate(10.000000, 10.000000)">
            <path
              d="M7.12 12.436l1.063-1.063c-.409-.41-.662-.973-.662-1.595 0-1.244 1.012-2.257 2.257-2.257 1.244 0 2.256 1.013 2.256 2.257 0 .622-.253 1.186-.661 1.595l1.063 1.063c.68-.681 1.102-1.621 1.102-2.658 0-2.074-1.687-3.76-3.76-3.76-2.074 0-3.76 1.686-3.76 3.76 0 1.037.42 1.977 1.102 2.658z"
              transform="translate(1.222222, 1.222222)"
            />
            <path d="M17.299 19.556L9.778 12.034 2.256 19.556z" transform="translate(1.222222, 1.222222)" />
            <path
              d="M3.93 15.625c-1.498-1.498-2.426-3.566-2.426-5.847 0-4.562 3.712-8.274 8.274-8.274 4.562 0 8.273 3.712 8.273 8.274 0 2.28-.928 4.35-2.426 5.847l1.064 1.064c1.77-1.771 2.867-4.216 2.867-6.911C19.556 4.386 15.169 0 9.778 0 4.386 0 0 4.386 0 9.778c0 2.695 1.097 5.14 2.867 6.91l1.063-1.063z"
              transform="translate(1.222222, 1.222222)"
            />
            <path
              d="M6.057 13.499c-.954-.953-1.544-2.27-1.544-3.721 0-2.903 2.362-5.265 5.265-5.265 2.903 0 5.265 2.362 5.265 5.265 0 1.451-.59 2.768-1.544 3.72l1.063 1.064c1.226-1.226 1.985-2.918 1.985-4.784 0-3.733-3.037-6.77-6.77-6.77-3.732 0-6.768 3.037-6.768 6.77 0 1.866.759 3.558 1.984 4.784L6.057 13.5z"
              transform="translate(1.222222, 1.222222)"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default Icons;
