import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { readerRefPropType } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ControlButton from 'components/common/control-button';
import PopperOverlay from 'components/common/popper-overlay';
import clsx from 'clsx';
import useStyles from './dropdown-menu.styles';

const DropDownMenu = memo((props) => {
  const classes = useStyles();
  const { icon: Icon, items, placement, readerRef, controlBtnProps, popperClassName, dividerClassName } = props;

  const menuAnchorRef = useRef(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMoreMenuClick = useCallback(() => {
    setIsMenuOpen((prevOpen) => !prevOpen);
  }, [setIsMenuOpen]);

  const handleCloseMenu = useCallback(
    (event) => {
      if (menuAnchorRef.current && menuAnchorRef.current.contains(event?.target)) {
        return;
      }

      setIsMenuOpen(false);
    },
    [setIsMenuOpen],
  );

  const handleItemClick = useCallback(
    (callback, closeMenuAfterAction) => () => {
      callback?.();

      if (closeMenuAfterAction) {
        setIsMenuOpen(false);
      }
    },
    [handleCloseMenu],
  );

  const handlePointerdown = useCallback(
    (event) => {
      // Close annotation overlay on click anywhere in the reader document
      if (event.frameworkComponent === 'READER_DOCUMENT' || event.frameworkComponent === 'READER_VIEW') {
        handleCloseMenu();
      }
    },
    [handleCloseMenu],
  );

  useEffect(() => {
    if (readerRef) {
      readerRef.current.addEventListener('pointerdown', handlePointerdown);
    }

    return () => {
      if (readerRef) {
        readerRef.current.stop;
      }
    };
  }, []);

  return (
    <>
      <ControlButton icon={Icon} buttonRef={menuAnchorRef} onClick={handleMoreMenuClick} {...controlBtnProps} />
      <PopperOverlay
        isOpen={isMenuOpen}
        placement={placement}
        onClose={handleCloseMenu}
        anchor={menuAnchorRef.current}
        popperClassName={popperClassName}
      >
        <MenuList className={classes.menuList}>
          {items.map(
            (
              {
                label,
                onClick,
                icon: ItemIcon,
                className = '',
                iconProps = {},
                labelClassName = '',
                closeMenuAfterAction = false,
              },
              index,
            ) => (
              <Box key={index}>
                {index !== 0 && <Divider className={dividerClassName} />}
                <MenuItem
                  onClick={handleItemClick(onClick, closeMenuAfterAction)}
                  className={clsx(classes.menuItem, className)}
                >
                  <Typography noWrap component="span" className={clsx(classes.menuItemText, labelClassName)}>
                    {label}
                  </Typography>
                  {ItemIcon && <ItemIcon {...iconProps} />}
                </MenuItem>
              </Box>
            ),
          )}
        </MenuList>
      </PopperOverlay>
    </>
  );
});

DropDownMenu.propTypes = {
  icon: PropTypes.elementType.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType,
      label: PropTypes.string,
      onClick: PropTypes.func,
      iconProps: PropTypes.shape({}),
      className: PropTypes.string,
      labelClassName: PropTypes.string,
      closeMenuAfterAction: PropTypes.bool,
    }),
  ),
  controlBtnProps: PropTypes.shape({
    className: PropTypes.string,
    iconClassName: PropTypes.string,
  }),
  readerRef: readerRefPropType,
  placement: PropTypes.string,
  popperClassName: PropTypes.string,
  dividerClassName: PropTypes.string,
};

DropDownMenu.defaultProps = {
  items: [],
  placement: 'bottom-end',
  popperClassName: '',
};

export default DropDownMenu;
