import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  playerContainer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 11,
    position: 'fixed',
    paddingBottom: 'env(safe-area-inset-bottom)',
    backgroundColor: theme.palette.colors.surfaceColor,
  },
  playerContent: {
    padding: 0,
    zIndex: 9,
    width: '100%',
    height: '100%',
    maxWidth: 1024,
    display: 'flex',
    margin: '0 auto',
    position: 'relative',
    flexDirection: 'column',
  },
  trackTitleContainer: {
    width: 'calc(100% - 40px)',
    margin: '24px auto 0',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // opacity: 0.6,
    // filter: 'blur(150px)',
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    // transition: 'background-image 0.2s ease-in-out',
    // backgroundImage: ({ backgroundImage }) => `url(${backgroundImage})`,
  },
  imageContainer: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    aspectRatio: 1 / 1,
    width: 'auto',
    height: '100%',
    maxHeight: '512px',
    borderRadius: theme.palette.colors.cornerRadius,
    boxShadow: '0px 0px 100px rgba(0,0,0,0.2)',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  timelineContainer: {
    position: 'relative',
    margin: '44px 60px 44px',
    height: 28,
    minHeight: 28,
    maxHeight: 28,
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      margin: '0 20px 44px',
    },
  },
  timelineRail: {
    color: theme.palette.colors.backdropMutedColor,
  },
  timelineText: {
    color: theme.palette.colors.backdropOnColor,
  },
  bottomFadeEffect: {
    position: 'absolute',
    width: '100%',
    height: 529,
    bottom: 0,
    // backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.colors.backdropColor, 0)}, ${
    //   theme.palette.colors.backdropColor
    // } 100%)`,
  },
  navigation: {
    width: '100%',
    minHeight: 108,
    maxHeight: 108,
    height: 275,
    margin: '32px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& >:not(:last-child)': {
      marginRight: 79,
      [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
        marginRight: 25,
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      '& > :first-child': {
        marginLeft: 32,
      },
      '& > :last-child': {
        marginRight: 32,
      },
    },
  },
  navigationIcon: {
    color: theme.palette.colors.backdropOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  navigationIconBtn: {
    padding: 0,
  },
  navigationSmallIcon: {
    width: 32,
    height: 32,
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      width: 25,
      height: 25,
    },
  },
  navigationMediumIcon: {
    width: 56,
    height: 56,
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      width: 40,
      height: 40,
    },
  },
  navigationLargeIcon: {
    width: 108,
    height: 108,
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      width: 80,
      height: 80,
    },
  },
}));

export default useStyles;
