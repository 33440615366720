import { initialSetup, initialState } from '../constants/state.constants';

export const createState = (props) => {
  let _a;
  let _b;
  let _c;
  let _d;

  return {
    previousScale: (_a = props.initialScale) !== null && _a !== undefined ? _a : initialState.scale,
    scale: (_b = props.initialScale) !== null && _b !== undefined ? _b : initialState.scale,
    positionX: (_c = props.initialPositionX) !== null && _c !== undefined ? _c : initialState.positionX,
    positionY: (_d = props.initialPositionY) !== null && _d !== undefined ? _d : initialState.positionY,
  };
};
export const createSetup = (props) => {
  const newSetup = { ...initialSetup };
  Object.keys(props).forEach((key) => {
    const validValue = typeof props[key] !== 'undefined';
    const validParameter = typeof initialSetup[key] !== 'undefined';
    if (validParameter && validValue) {
      const dataType = Object.prototype.toString.call(initialSetup[key]);
      const isObject = dataType === '[object Object]';
      const isArray = dataType === '[object Array]';
      if (isObject) {
        newSetup[key] = { ...initialSetup[key], ...props[key] };
      } else if (isArray) {
        newSetup[key] = [...initialSetup[key], ...props[key]];
      } else {
        newSetup[key] = props[key];
      }
    }
  });
  return newSetup;
};
