import { connect } from 'react-redux';
import { updateEBookDataRoutine } from 'redux-state/ducks/player';
import SettingsMenu from './settings-menu.component';

const mapStateToProps = ({ app, player }) => ({
  defaultTheme: app.config?.style,
  ebookData: player.ebookData,
  isEbookReaderOpen: player.isEbookReaderOpen,
});

const mapDispatchToProps = {
  updateEBookDataAction: updateEBookDataRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsMenu);
