import React, { memo } from 'react';
import useMediaQuery from 'hook/use-media-query';
import { playerSharedPropTypes } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import PlaybackRateOption from 'components/player-components/playback-rate-option';
import FullScreenButton from 'components/full-screen-components/full-screen-button';
import MoreMenuButton from 'components/full-screen-components/more-menu-button';
import QueueOption from 'components/player-components/queue-option';
import VolumeOption from 'components/player-components/volume-option';
import useStyles from './control-panel.styles';

const ControlPanel = memo((props) => {
  const classes = useStyles();
  const { playerRef } = props;
  const isTablet = useMediaQuery('tablet');

  return (
    <Box className={classes.controlPanelContainer}>
      <FullScreenButton className={classes.controlButton} iconClassName={classes.controlIcon} />
      <QueueOption
        placement={isTablet ? 'bottom-end' : 'top-end'}
        className={classes.controlButton}
        iconClassName={classes.controlIcon}
        paperClassName={classes.popperPaper}
        dividerClassName={classes.menuListDivider}
        menuListClassName={classes.bgPanel}
        menuListItemClassName={classes.textColor}
      />
      <VolumeOption
        placement={isTablet ? 'bottom-end' : 'top-end'}
        playerRef={playerRef}
        className={classes.controlButton}
        iconClassName={classes.controlIcon}
        paperClassName={classes.popperPaper}
      />
      <PlaybackRateOption
        placement={isTablet ? 'bottom-end' : 'top-end'}
        className={classes.controlButton}
        iconClassName={classes.controlIcon}
        paperClassName={classes.popperPaper}
        menuListClassName={classes.bgPanel}
        menuListItemClassName={classes.textColor}
      />
      <MoreMenuButton
        placement={isTablet ? 'bottom-end' : 'top-end'}
        textClassName={classes.textColor}
        className={classes.popperPaper}
        itemClassName={classes.bgPanel}
        dividerClassName={classes.menuListDivider}
        controlBtnProps={{
          className: classes.controlButton,
          iconClassName: classes.controlIcon,
        }}
      />
    </Box>
  );
});

ControlPanel.propTypes = {
  playerRef: playerSharedPropTypes.playerRef,
};

export default ControlPanel;
