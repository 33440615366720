import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useContentPermission from 'hook/use-content-permission';
import usePermissions from 'hook/use-permissions';
import { addListenerForUserData, addListenerForUserSubscriptions, addListenerForUserEntitlements } from 'utils/firestore';

const AppHandlers = memo(({ userId, initRealmDataAction, updateUserDataAction, updateUserSubscriptionAction, updateUserEntitlementsAction }) => {
  const { hasSubscriptionFlow } = useContentPermission();
  const usesPermissions = usePermissions();

  useEffect(() => {
    if (userId) {
      // Init Realm for history/favorite assets
      initRealmDataAction();

      // Listen for user data
      addListenerForUserData(updateUserDataAction);

      if (hasSubscriptionFlow) {
        // Listen user subscriptions
        addListenerForUserSubscriptions(updateUserSubscriptionAction);
        addListenerForUserEntitlements(updateUserEntitlementsAction)
      }
    }
  }, [userId]);

  return null;
});

AppHandlers.propTypes = {
  userId: PropTypes.string,
  initRealmDataAction: PropTypes.func.isRequired,
  updateUserDataAction: PropTypes.func.isRequired,
  updateUserSubscriptionAction: PropTypes.func.isRequired,
  updateUserEntitlementsAction: PropTypes.func.isRequired,
};

export default AppHandlers;
