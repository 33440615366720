import makeStyles from '@material-ui/core/styles/makeStyles';
import { CONTENT_WIDTH, HEADER_LOGO_IMAGE_WIDTH } from 'utils/themes/sizes';

const useStyles = makeStyles((theme) => ({
  header: {
    zIndex: 9,
    height: 70,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      height: 60,
    },
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    maxWidth: CONTENT_WIDTH,
    margin: '0 auto',
    paddingTop: 13,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 70,
    minHeight: 70,
    paddingLeft: 30,
    paddingRight: 30,
    zIndex: 2,
    backgroundColor: theme.palette.colors.backdropColor,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      height: 60,
      minHeight: 60,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.colors.backdropColor,
    },
  },
  navBar: {
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    cursor: 'pointer',
    marginRight: 20,
  },
  burgerMenuItem: {
    height: 54,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.colors.backdropMutedColor}`,
    backgroundColor: theme.palette.colors.backdropColor,
    fontFamily: 'ARSMaquetteProMedium',
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  burgerItemText: {
    cursor: 'pointer',
    fontFamily: 'ARSMaquetteProMedium',
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  burgerMenuContainer: {
    padding: 0,
    position: 'absolute',
    left: 0,
    width: '100%',
    zIndex: '1',
    top: 75,
    [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
      top: 70,
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      top: 60,
    },
  },
  burgerMenuList: {
    padding: 0,
  },
  paper: {
    backgroundColor: theme.palette.colors.backdropColor,
  },
  burgerIconBtn: {
    padding: 0,
    marginLeft: 16,
    color: theme.palette.colors.backdropOnColor,
  },
  searchInputContainer: {
    position: 'relative',
    margin: 7,
  },
  logoImage: {
    maxWidth: HEADER_LOGO_IMAGE_WIDTH,
    maxHeight: '36px !important',
  },
  itemLoader: {
    top: 7,
    width: 40,
    bottom: 7,
    height: 40,
    position: 'absolute',
    left: 'calc(50% - 20px)',
    right: 'calc(50% - 20px)',
  },
}));

export default useStyles;
