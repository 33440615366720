/* eslint-disable no-console */
import Hls from 'hls.js';
import logger from 'utils/logger';

class HlsPlayer {
  constructor(audioEl) {
    this.hls = null;
    this.audioEl = audioEl;
  }

  loadUrl(streamUrl) {
    try {
      if (Hls.isSupported()) {
        if (this.hls) {
          this.hls.stopLoad();
          this.hls.detachMedia();
          this.hls.destroy();
        }

        // Need to create a new instance, alternatively detach and re-attach
        // when switching source
        this.hls = new Hls();

        this.hls.loadSource(streamUrl);
        this.hls.attachMedia(this.audioEl);

        return new Promise((resolve) => {
          this.hls.on(Hls.Events.MANIFEST_PARSED, resolve);
        });
      }
      if (this.audioEl.canPlayType('application/vnd.apple.mpegurl')) {
        this.audioEl.src = streamUrl;

        return new Promise((resolve) => {
          this.audioEl.addEventListener('loadedmetadata', resolve);
        });
      }
      throw new Error('Media format is not supported');
    } catch (error) {
      logger.log('HlsPlayer', error.message, error);

      throw new Error(error);
    }
  }
}

export default HlsPlayer;
