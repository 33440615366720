import React, { memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icons from 'components/icons';
import PopperOverlay from 'components/common/popper-overlay';
import ControlButton from 'components/common/control-button';
import Box from '@material-ui/core/Box';
import Portal from '@material-ui/core/Portal';
import BrightnessPanel from './brightness-panel';
import useStyles from './settings-menu.styles';

const SettingsMenu = memo((props) => {
  const { controlBtnProps, isEbookReaderOpen } = props;
  const classes = useStyles();

  const settingsRef = useRef(null);
  const [brightness, setBrightness] = useState('100');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const toggleSettings = useCallback(() => {
    setIsSettingsOpen((currentFlag) => !currentFlag);
  }, []);

  const closeSettingsMenu = useCallback((event) => {
    if (settingsRef.current && settingsRef.current.contains(event?.target)) {
      return;
    }

    setIsSettingsOpen(false);
  }, []);

  return (
    <>
      <ControlButton
        buttonRef={settingsRef}
        onClick={toggleSettings}
        icon={Icons.ReaderSettings}
        {...controlBtnProps}
      />
      <PopperOverlay
        isOpen={isSettingsOpen}
        placement="bottom"
        onClose={closeSettingsMenu}
        anchor={settingsRef.current}
        popperClassName={classes.paper}
      >
        <BrightnessPanel brightness={brightness} setBrightness={setBrightness} />
      </PopperOverlay>
      {isEbookReaderOpen && (
        <Portal container={document.getElementById('root')}>
          <Box
            className={classes.brightnessOverlay}
            style={{ opacity: 1 - parseInt(`${brightness ?? '100'}`, 10) / 100 }}
          />
        </Portal>
      )}
    </>
  );
});

SettingsMenu.propTypes = {
  controlBtnProps: PropTypes.shape({
    className: PropTypes.string,
    iconClassName: PropTypes.string,
  }),
  isEbookReaderOpen: PropTypes.bool.isRequired,
};

export default SettingsMenu;
