/* eslint-disable import/no-cycle */
import { createRoutine } from 'redux-saga-routines';
import { put, call, takeLatest } from 'redux-saga/effects';
import logger from 'utils/logger';


import { loadUserFavorites, saveFavorite, removeFavorite } from 'utils/firestore';


export const getFavoritesRoutine = createRoutine('GET_FAVOURITES');
export const addToFavoritesRoutine = createRoutine('ADD_TO_FAVOURITES');
export const removeFromFavoritesRoutine = createRoutine('REMOVE_FROM_FAVOURITES');

export const initialState = Object.freeze({
  favorites: [],
});

export const favoritesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getFavoritesRoutine.SUCCESS:
      return {
        ...state,
        favorites: payload,
      };
    case addToFavoritesRoutine.SUCCESS:
      return {
        ...state,
        favorites: [...state.favorites, payload],
      };
    case removeFromFavoritesRoutine.SUCCESS:
      return {
        ...state,
        favorites: payload,
      };
    default:
      return { ...state };
  }
};

export function* getFavoritesSaga() {
  try {
    const userFavoriteIds = yield loadUserFavorites();

    yield put(getFavoritesRoutine.success(userFavoriteIds));

    return userFavoriteIds;
  } catch (error) {
    logger.error('getFavoritesSaga', 'Unable to get favorites', error);
  }

  return null;
}

export function* addToFavoritesSaga({ payload: trackId }) {
  try {
    yield saveFavorite(trackId)
    yield call(getFavoritesSaga);
  } catch (error) {
    logger.error('addToFavoritesSaga', 'Unable to add favorite item', error);
  }
}

export function* removeFromFavoritesSaga({ payload: trackId }) {

  try {
    yield removeFavorite(trackId)
    yield call(getFavoritesSaga);
  } catch (error) {
    logger.error('getFavoritesSaga', 'Unable to remove favorite', error);
  }
}

export function* watchFavoritesSagas() {
  yield takeLatest(getFavoritesRoutine.REQUEST, getFavoritesSaga);
  yield takeLatest(addToFavoritesRoutine.REQUEST, addToFavoritesSaga);
  yield takeLatest(removeFromFavoritesRoutine.REQUEST, removeFromFavoritesSaga);
}
