import { connect } from 'react-redux';
import { logoutRoutine } from 'redux-state/ducks/auth';
import AccountMenu from './account-menu.component';
import { getUserProps } from 'utils/auth/firebase';

const mapStateToProps = ({ app, firebase }) => ({
  // displayName: firebase?.auth?.uid?.split(':')[1] ?? '',
  displayName: getUserProps(app).displayName,
  firebaseFunctionLocation: app?.config?.firebaseFunctionLocation,
});

const mapDispatchToProps = {
  logoutAction: logoutRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
