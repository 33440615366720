const ERROR_PARSE_REGEXP = /^Firebase:(.*).\((.*)\).$/;

const FIREBASE_ERROR_CODES = Object.freeze({
    INVALID_EMAIL: 'auth/invalid-email',
    INVALID_PASSWORD: 'auth/invalid-password',
    USER_NOT_FOUND: 'auth/user-not-found',
    USER_DISABLED: 'auth/user-disabled',
    INTERNAL_ERROR: 'auth/internal-error',
    EMAIL_ALREADY_IN_USE: 'auth/email-already-exists',
    WEAK_PASSWORD: 'auth/weak-password',
});

const firebaseErrorTranslations = {
    [FIREBASE_ERROR_CODES.INVALID_EMAIL]: "auth_firebase_error_invalidemail",
    [FIREBASE_ERROR_CODES.INVALID_PASSWORD]: "auth_firebase_error_wrongpassword",
    [FIREBASE_ERROR_CODES.USER_NOT_FOUND]: "auth_firebase_error_usernotfound",
    [FIREBASE_ERROR_CODES.USER_DISABLED]: "auth_firebase_error_userdisabled",
    [FIREBASE_ERROR_CODES.INTERNAL_ERROR]: "auth_firebase_error_internalerror",
    [FIREBASE_ERROR_CODES.EMAIL_ALREADY_IN_USE]: "auth_firebase_error_emailalreadyinuse",
    [FIREBASE_ERROR_CODES.WEAK_PASSWORD]: "auth_firebase_error_weakpassword",
}

/**
 * Firebase throws errors in English only.
 * We take the exception here and extract the message.
 * If possible, we return the translation key if we have it,
 * otherwise the English message
 **/
export const parseFirebaseError = (error) => {
    const translationKey = firebaseErrorTranslations[error?.code];
    if (error.code && translationKey) {
        return {
            ...error,
            message: translationKey,
        }
    }

    if (error.message) {
        const [, errorMessage] = error.message.match(ERROR_PARSE_REGEXP);
        return {
            ...error,
            message: errorMessage.trim(),
        };
    }

    return error;
};
