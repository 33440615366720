import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  animationSwitchContainer: {
    width: '100%',
    height: 49,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
  },
  animationSwitchLabel: {
    margin: 0,
    width: '100%',
    justifyContent: 'space-between',
  },
  animationSwitchText: {
    fontSize: 12,
    fontFamily: 'ARSMaquetteProMedium',
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
}));

export const AnimationSwitch = withStyles((theme) => ({
  root: {
    width: 51,
    height: 31,
    padding: 0,
  },
  switchBase: {
    padding: 0,
    margin: 2,
    marginLeft: 5,
    transitionDuration: '300ms',
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + $track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
      },
    },
  },
  thumb: {
    marginLeft: '-1px',
    boxSizing: 'border-box',
    width: 27,
    height: 27,
    backgroundColor: '#FFF',
  },
  track: {
    borderRadius: 31 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
  checked: {},
}))(Switch);

export default useStyles;
