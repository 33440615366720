import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderThemeDarkIcon = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <g fillRule="evenodd">
      <g stroke="#FFFAF5">
        <g transform="translate(-883.000000, -290.000000) translate(698.000000, 79.000000) translate(13.000000, 197.000000) translate(172.000000, 14.000000)">
          <rect width="31" height="31" x=".5" y=".5" rx="15.5" />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ReaderThemeDarkIcon;
