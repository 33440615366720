import { connect } from 'react-redux';
import { changeActiveTrackIndexRoutine } from 'redux-state/ducks/player';
import Gallery from './gallery.component';

const mapStateToProps = ({ player }) => ({
  queue: player.queue,
  activeIndex: player.activeIndex,
});

const mapDispatchToProps = {
  changeActiveTrackIndexAction: changeActiveTrackIndexRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
