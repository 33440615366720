import useMediaQueryMui from '@material-ui/core/useMediaQuery';

const useMediaQuery = (screen) => {
  const isMatch = useMediaQueryMui((theme) => theme.breakpoints.down(theme.breakpoints.values[screen]), {
    noSsr: true,
  });

  return isMatch;
};

export default useMediaQuery;
