import { connect } from 'react-redux';
import { changeEbookReaderOpenRoutine, resetPlayerRoutine, updateEBookDataRoutine } from 'redux-state/ducks/player';
import PubhubReader from './pubhub-reader.component';

const mapStateToProps = ({ player }) => ({
  queue: player.queue,
  ebookData: player.ebookData,
  activeIndex: player.activeIndex,
  isEbookReaderOpen: player.isEbookReaderOpen,
});

const mapDispatchToProps = {
  resetPlayerAction: resetPlayerRoutine.request,
  updateEBookDataAction: updateEBookDataRoutine.request,
  changeEbookReaderOpenAction: changeEbookReaderOpenRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PubhubReader);
