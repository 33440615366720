import { connect } from 'react-redux';
import { changeVolumeRoutine } from 'redux-state/ducks/player';
import VolumeOption from './volume-option.component';

const mapStateToProps = ({ player }) => ({
  volume: player.volume,
});

const mapDispatchToProps = {
  changeVolumeAction: changeVolumeRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(VolumeOption);
