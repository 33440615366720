import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderThemeSepiaIcon = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <g fillRule="evenodd">
      <path
        d="M15.685.503c4.42-.001 8.361 1.719 11.205 4.507C29.752 7.816 31.5 11.704 31.5 16c0 4.28-1.735 8.155-4.54 10.96-2.805 2.805-6.68 4.54-10.96 4.54-4.28 0-8.155-1.735-10.96-4.54C2.235 24.155.5 20.28.5 16c0-4.225 1.69-8.055 4.432-10.851C7.679 2.347 11.48.584 15.685.503z"
        transform="translate(-807.000000, -290.000000) translate(698.000000, 79.000000) translate(13.000000, 197.000000) translate(96.000000, 14.000000)"
      />
    </g>
  </SvgIcon>
);

export default ReaderThemeSepiaIcon;
