import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isiOS } from 'utils/user-agent';
import { playerSharedPropTypes } from 'utils/prop-types';
import Slider from '@material-ui/core/Slider';
import { SpeakerIcon } from 'components/icons';
import ControlButton from 'components/common/control-button';
import PopperOverlay from 'components/common/popper-overlay';
import clsx from 'clsx';
import useStyles from './volume-option.styles';

const VolumeOption = memo((props) => {
  const classes = useStyles();
  const isIOSDevice = isiOS();
  const { placement, className, volume, playerRef, iconClassName, paperClassName, changeVolumeAction } = props;

  const volumeControlBtnRef = useRef(null);
  const [volumeValue, setVolumeValue] = useState(volume * 100);
  const [isVolumePanelOpened, setIsVolumePanelOpened] = useState(false);

  useEffect(() => {
    setVolumeValue(volume * 100);
  }, [volume]);

  const toggleVolumePanel = useCallback(() => {
    setIsVolumePanelOpened((currentFlag) => !currentFlag);
  }, []);

  const handleCloseVolumePanel = useCallback((event) => {
    if (volumeControlBtnRef.current && volumeControlBtnRef.current.contains(event?.target)) {
      return;
    }

    setIsVolumePanelOpened(false);
  }, []);

  const onVolumeChange = useCallback((_, value) => {
    setVolumeValue(value);
    playerRef.current.volume = value / 100;
  }, []);

  const setVolumeValueCommitted = useCallback((_event, value) => {
    changeVolumeAction(value / 100);
  }, []);

  return (
    !isIOSDevice && (
      <>
        <ControlButton
          icon={SpeakerIcon}
          className={className}
          onClick={toggleVolumePanel}
          buttonRef={volumeControlBtnRef}
          iconClassName={clsx(classes.icon, iconClassName)}
        />
        <PopperOverlay
          isOpen={isVolumePanelOpened}
          placement={placement}
          onClose={handleCloseVolumePanel}
          anchor={volumeControlBtnRef.current}
          popperClassName={clsx(classes.paper, paperClassName)}
        >
          <Slider
            aria-label="Volume"
            min={0}
            max={100}
            orientation="vertical"
            value={volumeValue}
            onChange={onVolumeChange}
            onChangeCommitted={setVolumeValueCommitted}
          />
        </PopperOverlay>
      </>
    )
  );
});

VolumeOption.propTypes = {
  volume: PropTypes.number.isRequired,
  className: PropTypes.string,
  placement: PropTypes.string.isRequired,
  playerRef: playerSharedPropTypes.playerRef,
  iconClassName: PropTypes.string,
  paperClassName: PropTypes.string,
  changeVolumeAction: PropTypes.func.isRequired,
};

VolumeOption.defaultProps = {
  className: '',
  iconClassName: '',
  paperClassName: '',
};

export default VolumeOption;
