import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { playerSharedPropTypes, queuePropType } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Icons, { Backward30Icon, Forward30Icon, PauseIcon, PlayIcon, SkipLeftIcon, SkipRightIcon } from 'components/icons';
import clsx from 'clsx';
import useStyles from './navigation.styles';

const Navigation = memo((props) => {
  const classes = useStyles();
  const {
    queue,
    isPlaying,
    className,
    activeIndex,
    playerRef,
    iconClassName,
    iconBtnClassName,
    playTrackAction,
    pauseTrackAction,
    skipLeftIconClassName,
    backwardIconClassName,
    pauseIconClassName,
    playIconClassName,
    forwardIconClassName,
    skipRightIconClassName,
    playPrevTrackInQueueAction,
    playNextTrackInQueueAction,
    displayQueueControls,
    displayTimeControls,
  } = props;

  const handlePlayClick = useCallback(() => {
    playTrackAction();
  }, []);

  const handlePauseClick = useCallback(() => {
    pauseTrackAction();
  }, []);

  const handleSkipLeftClick = useCallback(() => {
    const playedSeconds = playerRef.current.currentTime;

    if (playedSeconds > 3) {
      playerRef.current.currentTime = 0;
    } else {
      playPrevTrackInQueueAction();
    }
  }, []);

  const handleSkipRightClick = useCallback(() => {
    playNextTrackInQueueAction();
  }, []);

  const handleBackwardClick = useCallback(() => {
    const playedSeconds = playerRef.current.currentTime;
    const playedMinus30 = playedSeconds - 30;
    const newTime = playedMinus30 < 0 ? 0 : playedMinus30;

    playerRef.current.currentTime = newTime;
  }, []);

  const handleForwardClick = useCallback(() => {
    const { duration } = playerRef.current;
    const playedSeconds = playerRef.current.currentTime;
    const playedPlus30 = playedSeconds + 30;
    const newTime = playedPlus30 > duration ? duration : playedPlus30;

    playerRef.current.currentTime = newTime;
  }, []);

  return (
    <Box className={clsx(classes.trackNavigation, className)}>
      {displayQueueControls && <IconButton onClick={handleSkipLeftClick} className={iconBtnClassName}>
        <SkipLeftIcon className={clsx(classes.icon, iconClassName, skipLeftIconClassName)} />
      </IconButton>}
      {displayTimeControls && <IconButton onClick={handleBackwardClick} className={iconBtnClassName}>
        <Backward30Icon className={clsx(classes.icon, iconClassName, backwardIconClassName)} />
      </IconButton>}
      {isPlaying ? (
        <IconButton className={iconBtnClassName} onClick={handlePauseClick}>
          <PauseIcon className={clsx(classes.icon, iconClassName, pauseIconClassName)} />
        </IconButton>
      ) : (
        <IconButton className={iconBtnClassName} onClick={handlePlayClick}>
          <PlayIcon className={clsx(classes.icon, iconClassName, playIconClassName)} />
        </IconButton>
      )}
      {displayTimeControls && <IconButton onClick={handleForwardClick} className={iconBtnClassName}>
        <Forward30Icon className={clsx(classes.icon, iconClassName, forwardIconClassName)} />
      </IconButton>}
      {displayQueueControls && <IconButton onClick={handleSkipRightClick} className={iconBtnClassName}>
        <SkipRightIcon className={clsx(classes.icon, iconClassName, skipRightIconClassName)} />
      </IconButton>}
    </Box>
  );
});

Navigation.propTypes = {
  queue: queuePropType,
  playerRef: playerSharedPropTypes.playerRef,
  isPlaying: PropTypes.bool.isRequired,
  className: PropTypes.string,
  activeIndex: PropTypes.number,
  iconClassName: PropTypes.string,
  playTrackAction: PropTypes.func.isRequired,
  pauseTrackAction: PropTypes.func.isRequired,
  iconBtnClassName: PropTypes.string,
  skipLeftIconClassName: PropTypes.string,
  backwardIconClassName: PropTypes.string,
  pauseIconClassName: PropTypes.string,
  playIconClassName: PropTypes.string,
  forwardIconClassName: PropTypes.string,
  skipRightIconClassName: PropTypes.string,
  playPrevTrackInQueueAction: PropTypes.func.isRequired,
  playNextTrackInQueueAction: PropTypes.func.isRequired,
  displayQueueControls: PropTypes.bool.isRequired,
  displayTimeControls: PropTypes.bool.isRequired,
};

Navigation.defaultProps = {
  className: '',
  iconClassName: '',
  iconBtnClassName: '',
  skipLeftIconClassName: '',
  backwardIconClassName: '',
  pauseIconClassName: '',
  playIconClassName: '',
  forwardIconClassName: '',
  skipRightIconClassName: '',
};

export default Navigation;
