// Common
export const CONTENT_WIDTH = 1024;
export const HEADER_HEIGHT = 75;
export const FOOTER_HEIGHT = 115;
export const CONTENT_PADDING = 60;

// Mini Player
export const MINI_PLAYER_HEIGHT = 54;

// Header
export const HEADER_NAV_ITEM_WIDTH = 106;
export const HEADER_LOGO_IMAGE_WIDTH = 126;

// Search bar
export const DEFAULT_SEARCH_INPUT_WIDTH = 300;
export const SEARCH_INPUT_MARGIN_RIGHT = 10;
export const MIN_DESKTOP_SEARCH_BAR_WIDTH = 200;
