import { connect } from 'react-redux';
import QueuePersistence from './queue-persistence.component';

const mapStateToProps = ({ player }) => ({
  queue: player.queue,
  volume: player.volume,
  ebookData: player.ebookData,
  playbackRate: player.playbackRate,
  activeIndex: player.activeIndex,
});

export default connect(mapStateToProps)(QueuePersistence);
