import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { favoritesPropTypes, queuePropType, readerRefPropType } from 'utils/prop-types';
import { getTrackPageRoute } from 'utils/routes';
import ASSET from 'constants/asset-types';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import DropdownMenu from 'components/common/dropdown-menu';
import { BookmarkIcon, FilledBookmarkIcon, InfoIcon } from 'components/icons';
import useStyles from './more-menu-button.styles';

const MoreMenuButton = memo((props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    queue,
    favorites,
    activeIndex,
    className,
    readerRef,
    placement,
    textClassName,
    itemClassName,
    controlBtnProps,
    dividerClassName,
    addToFavoritesAction,
    removeFromFavoritesAction,
    changeFullAudioPlayerViewAction,
    changeFullVideoPlayerViewAction,
    changeEbookReaderOpenAction,
  } = props;

  const { trackId, contentType } = queue?.[activeIndex] ?? {};

  const isInFavorites = useMemo(() => favorites.indexOf(trackId) !== -1, [favorites, trackId]);

  const handleBookmarkClick = useCallback(() => {
    if (isInFavorites) {
      removeFromFavoritesAction(trackId);
    } else {
      addToFavoritesAction(trackId);
    }
  }, [trackId, isInFavorites]);

  const handleMoreInfoClick = useCallback(() => {
    switch (contentType) {
      case ASSET.contentType.audio:
        changeFullAudioPlayerViewAction(false);
        break;
      case ASSET.contentType.video:
        changeFullVideoPlayerViewAction(false);
        break;
      case ASSET.contentType.ebook:
        changeEbookReaderOpenAction(false);
        break;
      default:
        break;
    }

    history.push(getTrackPageRoute(trackId));
  }, [trackId, contentType]);

  return (
    <DropdownMenu
      placement={placement}
      dividerClassName={dividerClassName}
      controlBtnProps={controlBtnProps}
      popperClassName={className}
      readerRef={readerRef}
      icon={MoreIcon}
      items={[
        {
          className: itemClassName,
          labelClassName: textClassName,
          label: t`generic_button_details`,
          onClick: handleMoreInfoClick,
          closeMenuAfterAction: true,
          icon: InfoIcon,
          iconProps: {
            className: textClassName,
          },
        },
      ]}
    />
  );
});

MoreMenuButton.propTypes = {
  queue: queuePropType,
  placement: PropTypes.string,
  favorites: favoritesPropTypes,
  activeIndex: PropTypes.number.isRequired,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  dividerClassName: PropTypes.string,
  readerRef: readerRefPropType,
  controlBtnProps: PropTypes.shape({
    className: PropTypes.string,
    iconClassName: PropTypes.string,
  }),
  addToFavoritesAction: PropTypes.func.isRequired,
  removeFromFavoritesAction: PropTypes.func.isRequired,
  changeFullAudioPlayerViewAction: PropTypes.func.isRequired,
  changeFullVideoPlayerViewAction: PropTypes.func.isRequired,
  changeEbookReaderOpenAction: PropTypes.func.isRequired,
};

MoreMenuButton.defaultProps = {
  className: '',
  textClassName: '',
};

export default MoreMenuButton;
