import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  readerHeaderContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: '99px',
    zIndex: 999,
    backgroundColor: ({ activeTheme }) => `${theme.reader[activeTheme]?.mainColors?.backgroundDark} !important`,
    borderBottom: ({ activeTheme }) => `0.5px solid ${theme.reader[activeTheme]?.mainColors?.foregroundDark} !important`,
  },
  readerViewContainer: {
    width: '100%',
    height: '100%',
    left: 0,
    right: 0,
    top: 0,
    position: 'absolute',
    // backgroundColor: 'transparent',
    // [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
    //   width: 'calc(100% - 40px)',
    // },
  },
  readerView: {
    top: 0, // ({ activeQueueItem }) => activeQueueItem.fixedLayout ? 0 : 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 20,
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    boxShadow: 'none',
    overflowY: ({ zoomLevel }) => `${zoomLevel > 100 ? 'scroll' : 'hidden'} !important`,
    overflowX: ({ zoomLevel }) => `${zoomLevel > 200 ? 'scroll' : 'hidden'} !important`,
  },
  iframeWrapper: {
    overflow: 'hidden !important',
    minHeight: '100vh',
    height: ({ zoomLevel }) => `${zoomLevel > 200 ? '282vw' : zoomLevel > 100 ? '141vw' : '100vh'} !important`,
    width: ({ zoomLevel }) => `${zoomLevel > 200 ? '200vw' : '100vw'} !important`,
  },
  loading: {
    height: '100vh',
    width: '100vw',
  },

  arrowBg: {
    width: 48,
    height: 290,
    opacity: 0.5,
    display: 'flex',
    borderRadius: 6,
    zIndex: 999,
    top: 'calc(50% - 145px)',
    position: 'absolute',
    transition: 'opacity 0.3s',
    "&.MuiBox-Root": {
      backgroundColor: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.backgroundDark,
    },
  },
  arrowIconBtn: {
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  leftArrow: {
    left: '0px',
  },
  rightArrow: {
    right: '0px',
  },
  icon: {
    "&.MuiSvgIcon-root": {
      color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
    },
  },
  textThemeColor: {
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  timelineContainerEpub: {
    backgroundColor: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.backgroundDark,
    borderTop: ({ activeTheme }) => `0.5px solid ${theme.reader[activeTheme]?.mainColors?.foregroundDark}`,
  },
}));

export default useStyles;
