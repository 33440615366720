import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { READER_THEMES } from 'utils/themes';
import { readerRefPropType } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import Icons from 'components/icons';
import ControlButton from 'components/common/control-button';
import FullScreenButton from 'components/full-screen-components/full-screen-button';
import MoreMenuButton from 'components/full-screen-components/more-menu-button';
import SettingsMenu from './settings-menu';
import NavigationMenu from './navigation-menu';
import useStyles from './control-panel.styles';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';

const ControlPanel = memo((props) => {
  const { readerRef, activeTheme, toggleBookmark, isBookmarkActive, isZoomActive, toggleZoom } = props;
  const classes = useStyles({ activeTheme });
  const isFixedLayout = !readerRef.current.navigator.publication.isReflowable;

  return (
    <Box className={classes.controlPanelContainer}>
      {isFixedLayout && (
      <ControlButton
        onClick={toggleZoom}
        className={classes.controlButton}
        iconClassName={classes.controlIcon}
        icon={isZoomActive ? ZoomOut : ZoomIn}
      />)}
      <FullScreenButton className={classes.controlButton} iconClassName={classes.controlIcon} />
      <ControlButton
        onClick={toggleBookmark}
        className={classes.controlButton}
        iconClassName={classes.controlIcon}
        icon={isBookmarkActive ? Icons.ReaderBookmarkRemove : Icons.ReaderBookmark}
      />
      <SettingsMenu
        readerRef={readerRef}
        controlBtnProps={{
          className: classes.controlButton,
          iconClassName: classes.controlIcon,
        }}
      />
      <NavigationMenu
        readerRef={readerRef}
        controlBtnProps={{
          className: classes.controlButton,
          iconClassName: classes.controlIcon,
        }}
      />
      <MoreMenuButton
        className={classes.moreMenu}
        readerRef={readerRef}
        itemClassName={classes.moreMenuItem}
        textClassName={classes.moreMenuText}
        controlBtnProps={{
          className: classes.controlButton,
          iconClassName: classes.controlIcon,
        }}
      />
    </Box>
  );
});

ControlPanel.propTypes = {
  readerRef: readerRefPropType,
  activeTheme: PropTypes.oneOf(Object.keys(READER_THEMES)),
  toggleBookmark: PropTypes.func.isRequired,
  isBookmarkActive: PropTypes.bool.isRequired,
  toggleZoom: PropTypes.func,
  isZoomActive: PropTypes.bool,
};

export default ControlPanel;
