import React, { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { playerSharedPropTypes } from 'utils/prop-types';
import logger from 'utils/logger';
import { isiOS } from 'utils/user-agent';
import { READER_THEMES } from 'utils/themes';
import { readerRefPropType } from 'utils/prop-types';
import useMediaQuery from 'hook/use-media-query';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Icons from 'components/icons';
import Loader from 'components/loader';
import ControlButton from 'components/common/control-button';
import useStyles from './navigation-menu.styles';
import { formatTime } from 'utils/time';
import formatDate from 'date-fns/format';
import {
  TAB_OPTIONS,
  getEmptyMessage,
  NAVIGATION_TABS,
  loadBookmarkItems,
  saveBookmark,
  deleteBookmark,
} from './navigation-menu.utils';

const MAX_ANNOTATION_TEXT_LENGTH = 40;

const TabPanel = memo((props) => {
  const classes = useStyles();
  const { children, value, index } = props;

  return (
    <div
      className={classes.tabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const NavigationMenu = memo((props) => {
  const { playerRef, activeTrack } = props;
  const classes = useStyles({});
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState({
    [NAVIGATION_TABS.bookmarks]: false,
  });
  const [bookmarkItems, setBookmarkItems] = useState();
  const [activeTab, setActiveTab] = useState(NAVIGATION_TABS.bookmarks);

  const handleGoToBookmarkClick = useCallback(
    (progress) => () => {
      playerRef.current.currentTime = progress | 0;
    },
    [],
  );

  const handleRemoveBookmarkClick = useCallback(
    (progress) => {
      deleteBookmark(activeTrack.assetId, progress).then ((items) => {
        loadBookmarkItems(activeTrack.assetId).then((items) => {
          setBookmarkItems(items);
        });
      });
    },
    [],
  );

  const handleAddBookmarkClick = useCallback(
    (items) => () => { //use items to check if progess exists
      saveBookmark(activeTrack.assetId, playerRef.current.currentTime, playerRef.current.duration).then ((items) => {
        loadBookmarkItems(activeTrack.assetId).then((items) => {
          setBookmarkItems(items);
        });
      });
    },
    [],
  );

  const handleTabChange = useCallback((_, value) => {
    setActiveTab(value);
  }, []);

  const loadAndSetNavigationItems = useCallback(() => {
    setIsLoading({
      [NAVIGATION_TABS.bookmarks]: true,
    });
    loadBookmarkItems(activeTrack.assetId).then((items) => {
      setBookmarkItems(items);
      setIsLoading((current) => ({ ...current, [NAVIGATION_TABS.bookmarks]: false }));
    });
  }, []);

  useEffect(() => {
    loadAndSetNavigationItems();
  }, []);

  const TAB_TO_VALUES = {
    [NAVIGATION_TABS.bookmarks]: bookmarkItems,
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.tabHeader}>
        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          variant="fullWidth"
          value={activeTab}
          onChange={handleTabChange}
        >
          {TAB_OPTIONS.map(({ value, label, ...restProps }) => (
            <Tab key={value} value={value} label={t(label)} {...restProps} />
          ))}
        </Tabs>
      </Box>
      {TAB_OPTIONS.map(({ value }) => {
        const items = TAB_TO_VALUES[value];
        const emptyMessage = !items?.length ? getEmptyMessage(value) : null;

        let marks = []
        if (items) {
          marks = items.map((item) => {
            return {
              page: formatTime(item.progress),
              progress: item.progress,
              title: t('generic_bookmark'),
              note: '',
              sort: item.progress,
              date: item.timestamp.toDate(),
              assetId: item.asset_id
            };
          })
          if (value != NAVIGATION_TABS.chapters) {
            marks = marks.sort((a, b) => a.sort - b.sort)
          }
        }
        return (
          <TabPanel key={value} value={activeTab} index={value}>
            {isLoading[value] && <Loader />}
            {emptyMessage && (
              <>
                <Typography noWrap className={classes.emptyMessageTitle}>
                  {t(emptyMessage.title)}
                </Typography>
                <Typography noWrap className={classes.emptyMessageSubtitle}>
                  {t(emptyMessage.subtitle)}
                </Typography>
              </>
            )}
            <List>
              <ListItem
                button
                key={value}
                className={classes.navItemButton}
                onClick={handleAddBookmarkClick(items)}
              >
                <Typography noWrap className={classes.navItemButtonTitle}>
                {t('audio_bookmarks_button_add')}
                </Typography>
              </ListItem>
            </List>
            {!!marks?.length && (
              <List>
                {marks.map((item) => (
                  <ListItem
                    button
                    key={item.progress}
                    className={classes.navItem}
                    onClick={handleGoToBookmarkClick(item.progress)}
                  >
                    <Box className={classes.navItemMetadata}>
                      <Typography noWrap className={classes.navItemSubTitle}>
                        {formatDate(new Date(item.date), 'MMM d yyyy')}
                      </Typography>
                      <Typography noWrap className={classes.navItemSubTitle}>
                        {item.page}
                      </Typography>
                    </Box>
                    <Box className={classes.navItemText}>
                    <Typography noWrap className={classes.navItemTitle}>
                      {item.title}
                    </Typography>
                    <ControlButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleRemoveBookmarkClick(item.progress);
                        }}
                        className={classes.controlButton}
                        iconClassName={classes.controlIcon}
                        icon={Icons.ReaderBookmarkRemove}
                      />
                    </Box>
                  </ListItem>
                ))}
              </List>
            )}

          </TabPanel>
        );
      })}
    </Box>
  );
});

NavigationMenu.propTypes = {
  playerRef: playerSharedPropTypes.playerRef,
  activeTheme: PropTypes.oneOf(Object.keys(READER_THEMES)),
};

const NavigationMenuWrapper = memo((props) => {
  const classes = useStyles();
  const isIOSDevice = isiOS();
  const isTablet = useMediaQuery('tablet');
  const { controlBtnProps, ...navigationMenuProps } = props;
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  const toggleDrawer = useCallback(
    (openState) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }

      setIsNavigationOpen((currentState) => openState ?? !currentState);
    },
    [],
  );

  return (
    <>
    {/* //icon={isBookmarkActive ? Icons.ReaderBookmarkRemove : Icons.ReaderBookmark} */}
      <ControlButton onClick={toggleDrawer()} icon={Icons.ReaderBookmark} {...controlBtnProps} />
      <SwipeableDrawer
        disableScrollLock
        disableSwipeToOpen
        disableDiscovery={isIOSDevice}
        disableBackdropTransition={!isIOSDevice}
        anchor={isTablet ? 'bottom' : 'right'}
        open={isNavigationOpen}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        PaperProps={{
          className: classes.paper,
        }}
      >
        <NavigationMenu {...navigationMenuProps} />
      </SwipeableDrawer>
    </>
  );
});

NavigationMenuWrapper.propTypes = {
  controlBtnProps: PropTypes.shape({
    className: PropTypes.string,
    iconClassName: PropTypes.string,
  }),
};

export default NavigationMenuWrapper;
