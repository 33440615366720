import { connect } from 'react-redux';
import {
  changeEbookReaderOpenRoutine,
  changeFullAudioPlayerViewRoutine,
  changeFullVideoPlayerViewRoutine,
} from 'redux-state/ducks/player';
import { addToFavoritesRoutine, removeFromFavoritesRoutine } from 'redux-state/ducks/favorites';
import MoreMenuButton from './more-menu-button.component';

const mapStateToProps = ({ player, favorites }) => ({
  queue: player.queue,
  activeIndex: player.activeIndex,
  favorites: favorites.favorites,
});

const mapDispatchToProps = {
  addToFavoritesAction: addToFavoritesRoutine.request,
  removeFromFavoritesAction: removeFromFavoritesRoutine.request,
  changeFullAudioPlayerViewAction: changeFullAudioPlayerViewRoutine.request,
  changeFullVideoPlayerViewAction: changeFullVideoPlayerViewRoutine.request,
  changeEbookReaderOpenAction: changeEbookReaderOpenRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(MoreMenuButton);
