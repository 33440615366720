import { connect } from 'react-redux';
import { replaceQueueRoutine } from 'redux-state/ducks/player';
import { addToFavoritesRoutine, removeFromFavoritesRoutine } from 'redux-state/ducks/favorites';
import ListResult from './list-result.component';

const mapStateToProps = ({ favorites }) => ({
  favorites: favorites.favorites,
});

const mapDispatchToProps = {
  replaceQueueAction: replaceQueueRoutine.request,
  addToFavoritesAction: addToFavoritesRoutine.request,
  removeFromFavoritesAction: removeFromFavoritesRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListResult);
