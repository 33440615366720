import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from 'hook/use-media-query';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import useStyles from './full-screen-header.styles';
import TrackTitle from '../track-title';

const FullScreenHeader = memo((props) => {
  const isMobileFullPlayer = useMediaQuery('mobileFullPlayer');
  const { item, height, onClose, creatorClassName, nameClassName, renderControlPanel } = props;

  const classes = useStyles({ height });

  const handleClosePlayerView = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Box className={classes.playerHeader}>
      <Button onClick={handleClosePlayerView} className={classes.closeIconBtn} />
      {!isMobileFullPlayer && (
        <TrackTitle item={item} nameClassName={nameClassName} creatorClassName={creatorClassName} />
      )}
      {renderControlPanel()}
    </Box>
  );
});

FullScreenHeader.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    creator: PropTypes.string,
    creatorImage: PropTypes.string,
  }).isRequired,
  height: PropTypes.number.isRequired,
  onClose: PropTypes.func,
  nameClassName: PropTypes.string,
  creatorClassName: PropTypes.string,
  renderControlPanel: PropTypes.func,
};

FullScreenHeader.defaultProps = {
  nameClassName: '',
  creatorClassName: '',
  renderControlPanel: () => null,
};

export default FullScreenHeader;
