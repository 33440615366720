import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 237,
    top: 18,
    left: 0,
    position: 'absolute',
    borderRadius: 13,
  },
  navItem: {
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '18px',
    width: 106,
    cursor: 'pointer',
    textDecoration: 'none',
    fontFamily: 'ARSMaquetteProMedium',
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  activeNavItem: {
    color: theme.palette.colors.primaryColor,
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    height: 62,
    fontSize: 14,
    width: '100%',
    paddingLeft: 18,
    paddingRight: 16,
    position: 'relative',
    backgroundColor: theme.palette.colors.surfaceColor,
  },
  menuItemText: {
    letterSpacing: 0,
    lineHeight: '24px',
    fontSize: 14,
    fontFamily: 'ARSMaquetteProRegular',
    color: theme.palette.colors.surfaceOnColor,
  },
  activeMenuItemText: {
    color: theme.palette.colors.primaryColor,
  },
}));

export default useStyles;
