import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    zIndex: 11,
  },
  popperPaper: {
    marginTop: 19,
    boxShadow: '0px 0px 50px rgba(0,0,0,0.2)',
    borderRadius: theme.palette.colors.cornerRadius,
    backgroundColor: alpha(theme.palette.colors.surfaceColor, 0.95),
    // border: `0.5px solid ${theme.palette.colors.borderColor}`,
    '& .MuiList-root': {
      '& > .MuiListItem-root:first-child, & .MuiBox-root:first-child > .MuiListItem-root': {
        borderTopLeftRadius: 13,
        borderTopRightRadius: 13,
      },
      '& > .MuiListItem-root:last-child, & .MuiBox-root:last-child > .MuiListItem-root': {
        borderBottomLeftRadius: 13,
        borderBottomRightRadius: 13,
      },
    },
  },

}));

export default useStyles;
