import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { queuePropType } from 'utils/prop-types';
import ASSET from 'constants/asset-types';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { ExpandICon } from 'components/icons';
import { getFullTrackName } from 'utils/formatters';
import clsx from 'clsx';
import useStyles from './track-info.styles';

const TrackInfo = memo((props) => {
  const classes = useStyles();
  const {
    queue,
    activeIndex,
    isTablet,
    changeFullAudioPlayerViewAction,
    changeFullVideoPlayerViewAction,
    changeEbookReaderOpenAction,
  } = props;
  const activeQueueItem = queue?.[activeIndex];
  const contentType = activeQueueItem?.contentType;
  const [isTrackInfoHovered, setIsTrackInfoHovered] = useState(false);

  const handleTrackInfoHover = useCallback(
    (flag) => () => {
      setIsTrackInfoHovered(flag);
    },
    [],
  );

  const openFullAudioPlayerView = useCallback(() => {
    switch (contentType) {
      case ASSET.contentType.audio:
        changeFullAudioPlayerViewAction(true);
        break;
      case ASSET.contentType.video:
        changeFullVideoPlayerViewAction(true);
        break;
      case ASSET.contentType.ebook:
        changeEbookReaderOpenAction(true);
        break;
      default:
        break;
    }
  }, [contentType]);

  const fullTrackName = getFullTrackName(activeQueueItem);

  const infoDataEvents = isTablet
    ? {}
    : {
        onMouseEnter: handleTrackInfoHover(true),
        onMouseLeave: handleTrackInfoHover(false),
      };

  return (
    <Box onClick={openFullAudioPlayerView} className={classes.trackInfoContainer} {...infoDataEvents}>
      <Box className={classes.trackImageContainer}>
        {activeQueueItem?.image && <CardMedia image={activeQueueItem.image} className={classes.trackImage} />}
        {!isTablet && (
          <IconButton className={clsx(classes.expandIconBtn, isTrackInfoHovered && classes.onTrackInfoHover)}>
            <ExpandICon className={clsx(classes.playerIcon, classes.expandIcon)} />
          </IconButton>
        )}
      </Box>
      <Box className={classes.trackInfo}>
        <Typography noWrap title={activeQueueItem?.creator} className={classes.trackCreator}>
          {activeQueueItem?.creator}
        </Typography>
        <Typography noWrap title={fullTrackName} className={classes.trackName}>
          {fullTrackName}
        </Typography>
      </Box>
    </Box>
  );
});

TrackInfo.propTypes = {
  queue: queuePropType,
  isTablet: PropTypes.bool,
  activeIndex: PropTypes.number,
  changeFullAudioPlayerViewAction: PropTypes.func.isRequired,
  changeFullVideoPlayerViewAction: PropTypes.func.isRequired,
  changeEbookReaderOpenAction: PropTypes.func.isRequired,
};

export default TrackInfo;
