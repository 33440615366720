import React, { lazy, useMemo, Suspense } from 'react';
import PropTypes from 'prop-types';
import ROUTES from 'utils/routes';
import { Route, Switch } from 'react-router-dom';
import AUTH_TYPE from 'constants/auth-type';
import useContentPermission from 'hook/use-content-permission';
import MainLayout from 'components/main-layout';
import AuthGuard from 'components/helpers/auth-guard';
import AccessTokenGuard from 'components/helpers/access-token-guard';
import AccessSubscriptionGuard from 'components/helpers/access-subscription-guard';
import AudioVideoPlayer from 'components/audio-video-player';
import ErrorBoundary from 'components/error-boundary';
import AppHandlers from 'components/app-handlers';
import SessionHandler from 'components/session-handler';
import EBookReader from 'components/ebook-reader';
import Loader from 'components/loader';

const FeedPage = lazy(() => import('pages/feed-page'));
const LoginPage = lazy(() => import('pages/login-page'));
const PasswordResetPage = lazy(() => import('pages/password-reset-page'));
const TrackPage = lazy(() => import('pages/track-page'));
const SignUpPage = lazy(() => import('pages/signup-page'));
const BrowsePage = lazy(() => import('pages/browse-page'));
const LandingPage = lazy(() => import('pages/landing-page'));
const PlaylistPage = lazy(() => import('pages/playlist-page'));
const LibraryPage = lazy(() => import('pages/library-page'));
const PaywallPage = lazy(() => import('pages/paywall-page'));

const App = ({ authType }) => {
  const { hasSubscriptionFlow } = useContentPermission();

  const redirectUnAuthenticatedTo = useMemo(() => {
    if (authType === AUTH_TYPE.firebase) {
      return ROUTES.login;
    }
    if (authType === AUTH_TYPE.web) {
      return ROUTES.root;
    }

    return ROUTES.login;
  }, [authType]);

  return (
    <AccessTokenGuard>
      <MainLayout>
        <ErrorBoundary>
          <Suspense fallback={<Loader withOverlay />}>
            <Switch>
              {authType === AUTH_TYPE.firebase && (
                <Route exact path={ROUTES.login}>
                  <AuthGuard redirectToFirstTab redirectAuthenticated>
                    <LoginPage />
                  </AuthGuard>
                </Route>
              )}
              {authType === AUTH_TYPE.firebase && (
                <Route exact path={ROUTES.passwordReset}>
                  <AuthGuard redirectToFirstTab redirectAuthenticated>
                    <PasswordResetPage />
                  </AuthGuard>
                </Route>
              )}
              {authType === AUTH_TYPE.firebase && (
                <Route exact path={ROUTES.signup}>
                  <AuthGuard redirectToFirstTab redirectAuthenticated>
                    <SignUpPage />
                  </AuthGuard>
                </Route>
              )}
              <Route exact path={ROUTES.root}>
                <AuthGuard redirectToFirstTab redirectAuthenticated>
                  <LandingPage />
                </AuthGuard>
              </Route>
              <Route path={ROUTES.root}>
                <AuthGuard redirectLink={redirectUnAuthenticatedTo}>
                  <AppHandlers />
                  <SessionHandler />
                  <Switch>
                    {hasSubscriptionFlow && (
                      <Route exact path={ROUTES.paywall}>
                        <PaywallPage />
                      </Route>
                    )}
                    <Route path={ROUTES.root}>
                      <AccessSubscriptionGuard>
                        <AuthGuard checkSubscription redirectLink={ROUTES.paywall}>
                          <AudioVideoPlayer />
                          <EBookReader />
                          <Switch>
                            <Route exact path={ROUTES.browse}>
                              <BrowsePage />
                            </Route>
                            <Route exact path={ROUTES.feed}>
                              <FeedPage />
                            </Route>
                            <Route exact path={ROUTES.library}>
                              <LibraryPage />
                            </Route>
                            <Route exact path={ROUTES.playlist}>
                              <PlaylistPage />
                            </Route>
                            <Route exact path={ROUTES.track}>
                              <TrackPage />
                            </Route>
                          </Switch>
                        </AuthGuard>
                      </AccessSubscriptionGuard>
                    </Route>
                  </Switch>
                </AuthGuard>
              </Route>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </MainLayout>
    </AccessTokenGuard>
  );
};

App.propTypes = {
  authType: PropTypes.string.isRequired,
};

export default App;
