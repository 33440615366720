import { TRACK_ASSET_TYPE } from './constants';

export const getPrimaryAssetsTypes = (assets = []) =>
  assets.reduce((acc, asset) => (asset?.type === TRACK_ASSET_TYPE.primary ? [...acc, asset?.content_type] : acc), []);

export const mapTrackToAsset = (track, assetIndex, additionalProperties = {}) => {
  let asset;

  // If a specific assetIndex is provided, we don't need to include all Assets
  // Ditto if there's only one Asset, although that's just a performance tweak
  const includeAllAssets = typeof assetIndex === 'undefined' && track?.assets?.length > 1;

  if (assetIndex) {
    asset = track?.assets?.[assetIndex];
  } else {
    asset = track?.assets?.find(({ type }) => type === TRACK_ASSET_TYPE.primary) ?? track?.assets?.[0];
  }

  return {
    id: track.id, // To detect item loaded state for lazy loading
    trackId: track.id,
    assetId: asset?.id,
    pages: asset?.pages,
    fixedLayout: asset?.fixed_layout,
    banner: track.banner,
    trackName: track.name,
    assetName: asset?.name,
    image: track.thumbnail,
    tallImage: track.tall_thumbnail,
    creator: track.creator,
    assetType: asset?.type,
    createdDate: track.created,
    extraData: track.extra_data,
    duration: asset?.duration_ms,
    description: track.description,
    contentType: asset?.content_type,
    mediaType: asset?.media_type,
    downloadUrl: asset?.download_url,
    downloadType: asset?.download_type,
    creatorImage: track.creator_thumbnail,
    displayMode: track?.display_mode,
    // Hack added by Joe to unwrap Assets (see `replaceQueueSaga`)
    allAssets: includeAllAssets
      ? track?.assets?.map((i) => ({
          assetId: i?.id,
          pages: i?.pages,
          fixedLayout: i?.fixed_layout,
          assetName: i?.name,
          assetType: i?.type,
          duration: i?.duration_ms,
          contentType: i?.content_type,
          mediaType: i?.media_type,
          downloadUrl: i?.download_url,
          downloadType: i?.download_type,
        }))
      : [],
    // End hack
    primaryAssets: track?.assets?.reduce(
      (acc, i) => [
        ...acc,
        ...(i.type === TRACK_ASSET_TYPE.primary
          ? [{ contentType: i.content_type, duration: i?.duration_ms, pages: i.pages }]
          : []),
      ],
      [],
    ),
    primaryAssetsTypes: [...new Set(getPrimaryAssetsTypes(track?.assets))],
    ...additionalProperties,
  };
};
