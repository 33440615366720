class Ref {
  constructor({ num, gen }) {
    this.num = num;
    this.gen = gen;
  }

  toString() {
    let str = `${this.num}R`;
    if (this.gen !== 0) {
      str += this.gen;
    }
    return str;
  }
}

const getDestination = async (item, pdf) =>
  new Promise((resolve, reject) => {
    if (typeof item.dest === 'string') {
      pdf.getDestination(item.dest).then(resolve).catch(reject);
    } else {
      resolve(item.dest);
    }
  }).then((destination) => destination);

const getOutlineItemContent = (item, pdf) =>
  new Promise((resolve, reject) => {
    getDestination(item, pdf).then((destination) => {
      if (!destination) {
        return;
      }

      const [ref] = destination;
      pdf
        .getPageIndex(new Ref(ref))
        .then((pageIndex) => resolve({ pageNumber: pageIndex + 1, title: item.title }))
        .catch(reject);
    });
  }).then((pageIndex) => pageIndex);

const getOutlineItem = (item, pdf) => [
  getOutlineItemContent(item, pdf),
  ...(item?.items ?? []).map((i) => getOutlineItemContent(i, pdf)),
];

const getOutlineItems = async (outline, pdf) => {
  if (!outline) {
    return [];
  }
  return Promise.all(outline.reduce((acc, item) => [...acc, ...getOutlineItem(item, pdf)], []));
};

export const getTOCItems = async (pdf) => {
  const outline = await pdf.getOutline();
  const tocItems = await getOutlineItems(outline, pdf);

  return tocItems;
};
