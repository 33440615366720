import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FullScreenIcon = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <path
      d="M1.933 2.752c0-.452.367-.819.819-.819H7.66c.451 0 .818.367.818.819 0 .451-.367.818-.818.818H3.57v4.09c0 .452-.367.819-.818.819-.452 0-.819-.367-.819-.818V2.75zm11.455 0c0-.452.366-.819.818-.819h4.91c.451 0 .817.367.817.819V7.66c0 .451-.366.818-.818.818-.452 0-.818-.367-.818-.818V3.57h-4.09c-.453 0-.82-.367-.82-.818zM2.752 13.388c.451 0 .818.366.818.818v4.091h4.09c.452 0 .819.366.819.818 0 .452-.367.818-.818.818H2.75c-.451 0-.818-.366-.818-.818v-4.909c0-.452.367-.818.819-.818zm16.363 0c.452 0 .818.366.818.818v4.91c0 .451-.366.817-.818.817h-4.909c-.452 0-.818-.366-.818-.818 0-.452.366-.818.818-.818h4.091v-4.09c0-.453.366-.82.818-.82z"
      transform="translate(-632.000000, -31.000000) translate(622.000000, 21.000000) translate(10.000000, 10.000000)"
    />
  </SvgIcon>
);

export default FullScreenIcon;
