import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  trackOptions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  optionButton: {
    height: '100%',
    backgroundColor: 'unset',
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
  },
});

export default useStyles;
