import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderTOCIcon = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g>
      <path
        d="M9.073 18c.496 0 .898.448.898 1s-.402 1-.898 1H2.898C2.402 20 2 19.552 2 19s.402-1 .898-1zM19 18c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-4.594-5c.497 0 .899.448.899 1s-.402 1-.899 1H2.898C2.402 15 2 14.552 2 14s.402-1 .898-1zM19 13c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-8.927-5c.496 0 .898.448.898 1s-.402 1-.898 1H2.898C2.402 10 2 9.552 2 9s.402-1 .898-1zM19 8c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-6.26-5c.496 0 .898.448.898 1s-.402 1-.898 1H2.898C2.402 5 2 4.552 2 4s.402-1 .898-1zM19 3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
        transform="translate(-872.000000, -38.000000) translate(862.000000, 28.000000) translate(10.000000, 10.000000)"
      />
    </g>
  </SvgIcon>
);

export default ReaderTOCIcon;
