import makeStyles from '@material-ui/core/styles/makeStyles';
import { darken } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  trackItem: {
    display: 'flex',
    width: '100%',
    maxWidth: ({ maxItemWidth }) => maxItemWidth,
    height: ({ itemHeight }) => itemHeight,
    position: 'relative',
    marginBottom: 18,
    borderRadius: theme.palette.colors.cornerRadius,
    margin: '0 auto',
    backgroundColor: theme.palette.colors.surfaceColor,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      alignItems: 'center',
      height: ({ isSmallView, itemHeight, itemHeightWithPadding }) =>
        isSmallView ? itemHeightWithPadding : itemHeight,
    },
    border: `0.5px solid ${theme.palette.colors.borderColor}`,
  },
  trackImage: {
    minWidth: ({ itemHeight, isSmallView }) => (isSmallView ? itemHeight + 6 : itemHeight),
    maxWidth: ({ itemHeight, isSmallView }) => (isSmallView ? itemHeight + 6 : itemHeight),
    height: ({ isSmallView }) => (isSmallView ? `calc(100% - 12px) ` : '100%'),
    position: 'relative',
    borderBottomLeftRadius: ({ isSmallView }) => (isSmallView ? 0 : theme.palette.colors.cornerRadius),
    borderTopLeftRadius: ({ isSmallView }) => (isSmallView ? 0 : theme.palette.colors.cornerRadius),
    marginLeft: ({ isSmallView }) => (isSmallView ? 8 : 0),
  },
  trackDescriptionContainer: {
    padding: '14px 0 14px 18px',
    width: ({ maxItemWidth, itemHeight }) => maxItemWidth - itemHeight - 22,
    maxWidth: ({ maxItemWidth, itemHeight }) => maxItemWidth - itemHeight - 22,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '8px 0 8px 8px',
    },
  },
  playIconBtn: {
    width: 48,
    minWidth: 48,
    height: 48,
    top: ({ itemHeight, isSmallView }) => (isSmallView ? itemHeight - 4 : itemHeight) / 2 - 24,
    left: ({ itemHeight, isSmallView }) => (isSmallView ? itemHeight - 4 : itemHeight) / 2 - 24,
    zIndex: 1,
    borderRadius: 24,
    cursor: 'pointer',
    position: 'absolute',
    '@media (hover: hover)': {
      '&:hover': {
        filter: 'brightness(0.9)',
      },
    },
  },
  trackCreatorContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: ({ isSmallView }) => `calc(100% - ${isSmallView ? 10 : 16}px)`,
    height: ({ isSmallView }) => (isSmallView ? 20 : 22),
  },
  trackCreatorImage: {
    display: 'inline-block',
    borderRadius: 1.1,
    marginRight: ({ isSmallView }) => (isSmallView ? 4 : 7),
    minWidth: ({ isSmallView }) => (isSmallView ? 20 : 22),
    maxWidth: ({ isSmallView }) => (isSmallView ? 20 : 22),
    height: ({ isSmallView }) => (isSmallView ? 20 : 22),
    display: 'none',
  },
  trackCreatorText: {
    fontSize: ({ isSmallView }) => (isSmallView ? 14 : 16),
    letterSpacing: 0,
    fontFamily: 'ARSMaquetteProRegular',
    color: theme.palette.colors.surfaceMutedColor,
  },
  trackName: {
    fontSize: ({ isSmallView }) => (isSmallView ? 16 : 28),
    lineHeight: ({ isSmallView }) => (isSmallView ? '16px' : 'unset'),
    letterSpacing: 0,
    marginBottom: 6,
    maxWidth: ({ isSmallView }) => `calc(100% - ${isSmallView ? 10 : 70}px)`,
    fontFamily: 'ARSMaquetteProMedium',
    color: theme.palette.colors.surfaceOnColor,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      fontSize: ({ isSmallView }) => (isSmallView ? 16 : 24),
    },
  },
  trackDescription: {
    lineHeight: '15px',
    letterSpacing: 0,
    maxWidth: ({ isSmallView }) => `calc(100% - ${isSmallView ? 10 : 70}px)`,
    height: 30,
    fontFamily: 'Roboto Regular',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    color: theme.palette.colors.surfaceOnColor,
    fontSize: ({ isSmallView }) => (isSmallView ? 13 : 14),
    marginBottom: ({ isSmallView }) => (isSmallView ? 4 : 11),
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginBottom: ({ isSmallView }) => (isSmallView ? 4 : 6),
    },
  },
  trackDetails: {
    fontSize: ({ isSmallView }) => (isSmallView ? 11 : 12),
    lineHeight: '24px',
    letterSpacing: 0,
    fontFamily: 'Roboto Bold',
    display: 'flex',
    alignItems: 'center',
    height: ({ isSmallView }) => (isSmallView ? 20 : 22),
    color: theme.palette.colors.surfaceOnColor,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      position: 'relative',
    },
  },
  trackDetailsTimeIcon: {
    width: 16,
    height: 16,
    marginRight: 4,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 14,
      height: 14,
    },
  },
  trackBookmarkIconBtn: {
    position: 'absolute',
    right: 22,
    width: 42,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    backgroundColor: theme.palette.colors.secondaryColor,
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: darken(theme.palette.colors.secondaryColor, 0.1),
      },
    },
    bottom: 66,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      right: 42,
      bottom: '-1px',
      width: 24,
      height: 24,
      padding: 0,
    },
  },
  activeBookmarkIcon: {
    width: 22,
    height: 22,
    color: theme.palette.colors.primaryColor,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 20,
      height: 20,
    },
  },
  trackBookmarkIcon: {
    width: 22,
    height: 22,
    color: theme.palette.colors.secondaryOnColor,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 20,
      height: 20,
    },
  },
  trackInfoIconBtn: {
    right: 22,
    bottom: 12,
    width: 42,
    height: 42,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: darken(theme.palette.colors.secondaryColor, 0.1),
      },
    },
    backgroundColor: theme.palette.colors.secondaryColor,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: 0,
      width: 24,
      height: 24,
      right: 12,
      bottom: '-1px',
    },
  },
  trackInfoIcon: {
    width: 22,
    height: 22,
    color: theme.palette.colors.secondaryOnColor,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 20,
      height: 20,
    },
  },
}));

export default useStyles;
