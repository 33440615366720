import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import useStyles from './footer.styles';

const Footer = ({ appName, termsLink, isMiniPlayerOpen }) => {
  const classes = useStyles({ isMiniPlayerOpen });
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const copyrightText = `${appName} © ${currentYear}`;

  return (
    <AppBar position="static" component="footer" className={classes.footer}>
      <div>
        <Link href={termsLink} rel="noopener noreferrer" target="_blank">{t`paywall_button_terms`}</Link>
        {' • '}
        <span>{copyrightText}</span>
      </div>
    </AppBar>
  );
};

Footer.propTypes = {
  appName: PropTypes.string.isRequired,
  termsLink: PropTypes.string.isRequired,
  isMiniPlayerOpen: PropTypes.bool.isRequired,
};

export default Footer;
