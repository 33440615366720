import React, { Component } from 'react';
import {
  getContext,
  createSetup,
  createState,
  handleCallback,
  getTransformStyles,
  makePassiveEventOption,
  getCenterPosition,
} from '../utils';
import { contextInitialState } from '../constants/state.constants';
import { handleCancelAnimation } from '../core/animations/animations.utils';
import { isWheelAllowed } from '../core/wheel/wheel.utils';
import { isPinchAllowed, isPinchStartAllowed } from '../core/pinch/pinch.utils';
import { handleCalculateBounds } from '../core/bounds/bounds.utils';
import { handleWheelStart, handleWheelZoom, handleWheelStop } from '../core/wheel/wheel.logic';
import { isPanningAllowed, isPanningStartAllowed } from '../core/pan/panning.utils';
import { handlePanning, handlePanningEnd, handlePanningStart } from '../core/pan/panning.logic';
import { handlePinchStart, handlePinchStop, handlePinchZoom } from '../core/pinch/pinch.logic';
import { handleDoubleClick, isDoubleClickAllowed } from '../core/double-click/double-click.logic';

const Context = React.createContext(contextInitialState);
class TransformContext extends Component {
  constructor() {
    super(...arguments);
    this.timer = 0;
    this.delay = 200;
    this.prevent = false;
    this.mounted = true;
    this.transformState = createState(this.props);
    this.setup = createSetup(this.props);
    // Components
    this.wrapperComponent = null;
    this.contentComponent = null;
    // Initialization
    this.isInitialized = false;
    this.bounds = null;
    // wheel helpers
    this.previousWheelEvent = null;
    this.wheelStopEventTimer = null;
    this.wheelAnimationTimer = null;
    // panning helpers
    this.isPanning = false;
    this.startCoords = null;
    this.lastTouch = null;
    // pinch helpers
    this.distance = null;
    this.lastDistance = null;
    this.pinchStartDistance = null;
    this.pinchStartScale = null;
    this.pinchMidpoint = null;
    // velocity helpers
    this.velocity = null;
    this.velocityTime = null;
    this.lastMousePosition = null;
    // animations helpers
    this.animate = false;
    this.animation = null;
    this.maxBounds = null;
    // key press
    this.pressedKeys = {};
    this.handleInitializeWrapperEvents = (wrapper) => {
      // Zooming events on wrapper
      const passive = makePassiveEventOption();
      wrapper.addEventListener('wheel', this.onWheelZoom, passive);
      wrapper.addEventListener('dblclick', this.onDoubleClick, passive);
      wrapper.addEventListener('click', this.onClick, passive);
      wrapper.addEventListener('touchstart', this.onTouchPanningStart, passive);
      wrapper.addEventListener('touchmove', this.onTouchPanning, passive);
      wrapper.addEventListener('touchend', this.onTouchPanningStop, passive);
    };
    this.handleInitialize = () => {
      const { centerOnInit } = this.setup;
      this.applyTransformation();
      this.forceUpdate();
      if (centerOnInit) {
        // this has to be redone once the right solution is found
        // problem is - we need to execute it after mounted component specify it's height / width, images are fetched async so it's tricky
        setTimeout(() => {
          if (this.mounted) {
            this.setCenter();
          }
        }, 50);
        setTimeout(() => {
          if (this.mounted) {
            this.setCenter();
          }
        }, 100);
        setTimeout(() => {
          if (this.mounted) {
            this.setCenter();
          }
        }, 200);
      }
    };
    /// ///////
    // Zoom
    /// ///////
    this.onWheelZoom = (event) => {
      const { disabled } = this.setup;
      if (disabled) return;
      const isAllowed = isWheelAllowed(this, event);
      if (!isAllowed) return;
      const keysPressed = this.isPressingKeys(this.setup.wheel.activationKeys);
      if (!keysPressed) return;
      handleWheelStart(this, event);
      handleWheelZoom(this, event);
      handleWheelStop(this, event);
    };
    /// ///////
    // Pan
    /// ///////
    this.onPanningStart = (event) => {
      const { disabled } = this.setup;
      const { onPanningStart } = this.props;
      if (disabled) return;
      const isAllowed = isPanningStartAllowed(this, event);
      if (!isAllowed) return;
      const keysPressed = this.isPressingKeys(this.setup.panning.activationKeys);
      if (!keysPressed) return;
      event.stopPropagation();
      handleCancelAnimation(this);
      handlePanningStart(this, event);
      handleCallback(getContext(this), event, onPanningStart);
    };
    this.onPanning = (event) => {
      const { disabled } = this.setup;
      const { onPanning } = this.props;
      if (disabled) return;
      const isAllowed = isPanningAllowed(this);
      if (!isAllowed) return;
      const keysPressed = this.isPressingKeys(this.setup.panning.activationKeys);
      if (!keysPressed) return;
      event.preventDefault();
      event.stopPropagation();
      handlePanning(this, event.clientX, event.clientY);
      handleCallback(getContext(this), event, onPanning);
    };
    this.onPanningStop = (event) => {
      const { onPanningStop } = this.props;
      if (this.isPanning) {
        handlePanningEnd(this);
        handleCallback(getContext(this), event, onPanningStop);
      }
    };
    /// ///////
    // Pinch
    /// ///////
    this.onPinchStart = (event) => {
      const { disabled } = this.setup;
      const { onPinchingStart, onZoomStart } = this.props;
      if (disabled) return;
      const isAllowed = isPinchStartAllowed(this, event);
      if (!isAllowed) return;
      handlePinchStart(this, event);
      handleCancelAnimation(this);
      handleCallback(getContext(this), event, onPinchingStart);
      handleCallback(getContext(this), event, onZoomStart);
    };
    this.onPinch = (event) => {
      const { disabled } = this.setup;
      const { onPinching, onZoom } = this.props;
      if (disabled) return;
      const isAllowed = isPinchAllowed(this);
      if (!isAllowed) return;
      event.preventDefault();
      event.stopPropagation();
      handlePinchZoom(this, event);
      handleCallback(getContext(this), event, onPinching);
      handleCallback(getContext(this), event, onZoom);
    };
    this.onPinchStop = (event) => {
      const { onPinchingStop, onZoomStop } = this.props;
      if (this.pinchStartScale) {
        handlePinchStop(this);
        handleCallback(getContext(this), event, onPinchingStop);
        handleCallback(getContext(this), event, onZoomStop);
      }
    };
    /// ///////
    // Touch
    /// ///////
    this.onTouchPanningStart = (event) => {
      const { disabled } = this.setup;
      const { onPanningStart } = this.props;
      if (disabled) return;
      const isAllowed = isPanningStartAllowed(this, event);
      if (!isAllowed) return;
      const isDoubleTap = this.lastTouch && +new Date() - this.lastTouch < 200;
      if (isDoubleTap && event.touches.length === 1) {
        this.onDoubleClick(event);
      } else {
        this.lastTouch = +new Date();
        handleCancelAnimation(this);
        const { touches } = event;
        const isPanningAction = touches.length === 1;
        const isPinchAction = touches.length === 2;
        if (isPanningAction) {
          handleCancelAnimation(this);
          handlePanningStart(this, event);
          handleCallback(getContext(this), event, onPanningStart);
        }
        if (isPinchAction) {
          this.onPinchStart(event);
        }
      }
    };
    this.onTouchPanning = (event) => {
      const { disabled } = this.setup;
      const { onPanning } = this.props;
      if (this.isPanning && event.touches.length === 1) {
        if (disabled) return;
        const isAllowed = isPanningAllowed(this);
        if (!isAllowed) return;
        event.preventDefault();
        event.stopPropagation();
        const touch = event.touches[0];
        handlePanning(this, touch.clientX, touch.clientY);
        handleCallback(getContext(this), event, onPanning);
      } else if (event.touches.length > 1) {
        this.onPinch(event);
      }
    };
    this.onTouchPanningStop = (event) => {
      this.onPanningStop(event);
      this.onPinchStop(event);
    };
    /// ///////
    // Click
    /// ///////
    this.onClick = (event) => {
      const { disabled } = this.setup;
      if (disabled) return;
      const { onClick } = this.props;
      this.timer = setTimeout(() => {
        if (!this.prevent) {
          handleCallback(getContext(this), event, onClick);
        }
      }, this.delay);
    };
    /// ///////
    // Double Click
    /// ///////
    this.onDoubleClick = (event) => {
      clearTimeout(this.timer);
      this.prevent = true;
      const { disabled } = this.setup;
      if (disabled) return;
      const isAllowed = isDoubleClickAllowed(this, event);
      if (!isAllowed) return;
      const { onDoubleClick: onDbClick } = this.props;
      if (onDbClick) {
        handleCallback(getContext(this), event, onDbClick);
      } else {
        handleDoubleClick(this, event);
      }

      setTimeout(() => {
        this.prevent = false;
      }, this.delay * 2);
    };
    /// ///////
    // Helpers
    /// ///////
    this.clearPanning = (event) => {
      if (this.isPanning) {
        this.onPanningStop(event);
      }
    };
    this.setKeyPressed = (e) => {
      this.pressedKeys[e.key] = true;
    };
    this.setKeyUnPressed = (e) => {
      this.pressedKeys[e.key] = false;
    };
    this.isPressingKeys = (keys) => {
      if (!keys.length) {
        return true;
      }
      return Boolean(keys.find((key) => this.pressedKeys[key]));
    };
    this.setComponents = (wrapperComponent, contentComponent) => {
      this.wrapperComponent = wrapperComponent;
      this.contentComponent = contentComponent;
      handleCalculateBounds(this, this.transformState.scale);
      this.handleInitializeWrapperEvents(wrapperComponent);
      this.handleInitialize();
      this.handleRef();
      this.isInitialized = true;
      handleCallback(getContext(this), undefined, this.props.onInit);
    };
    this.setTransformState = (scale, positionX, positionY) => {
      if (!isNaN(scale) && !isNaN(positionX) && !isNaN(positionY)) {
        if (scale !== this.transformState.scale) {
          this.transformState.previousScale = this.transformState.scale;
          this.transformState.scale = scale;
        }
        this.transformState.positionX = positionX;
        this.transformState.positionY = positionY;
        this.applyTransformation();
      } else {
        console.error('Detected NaN set state values');
      }
    };
    this.setCenter = () => {
      if (this.wrapperComponent && this.contentComponent) {
        const targetState = getCenterPosition(this.transformState.scale, this.wrapperComponent, this.contentComponent);
        this.setTransformState(targetState.scale, targetState.positionX, targetState.positionY);
      }
    };
    this.applyTransformation = () => {
      if (!this.mounted || !this.contentComponent) return;
      const { positionX, positionY } = this.transformState;
      const transform = getTransformStyles(positionX, positionY);
      this.contentComponent.style.transform = transform;
      this.handleRef();
    };
    this.handleRef = () => {
      this.props.setRef(getContext(this));
    };
  }

  componentDidMount() {
    const passive = makePassiveEventOption();
    // Panning on window to allow panning when mouse is out of component wrapper
    window.addEventListener('mousedown', this.onPanningStart, passive);
    window.addEventListener('mousemove', this.onPanning, passive);
    window.addEventListener('mouseup', this.onPanningStop, passive);
    document.addEventListener('mouseleave', this.clearPanning, passive);
    window.addEventListener('keyup', this.setKeyUnPressed, passive);
    window.addEventListener('keydown', this.setKeyPressed, passive);
    this.handleRef();
  }

  componentWillUnmount() {
    const passive = makePassiveEventOption();
    window.removeEventListener('mousedown', this.onPanningStart, passive);
    window.removeEventListener('mousemove', this.onPanning, passive);
    window.removeEventListener('mouseup', this.onPanningStop, passive);
    window.removeEventListener('keyup', this.setKeyUnPressed, passive);
    window.removeEventListener('keydown', this.setKeyPressed, passive);
    handleCancelAnimation(this);
  }

  componentDidUpdate(oldProps) {
    if (oldProps !== this.props) {
      handleCalculateBounds(this, this.transformState.scale);
      this.setup = createSetup(this.props);
    }
  }

  render() {
    const value = getContext(this);
    const { children } = this.props;
    const content = typeof children === 'function' ? children(value) : children;

    return (
      <Context.Provider
        value={{
          ...this.transformState,
          setComponents: this.setComponents,
          contextInstance: this,
        }}
      >
        {content}
      </Context.Provider>
    );
  }
}
export { Context, TransformContext };
