import React from 'react';
import PropTypes from 'prop-types';
import { Helmet as ReactHelmet } from 'react-helmet';
import { useTheme } from '@material-ui/core/styles';

const Helmet = ({ appName }) => {
  const theme = useTheme();

  return (
    <ReactHelmet>
      <title>{appName}</title>
      <meta name="description" content={appName} />
      <meta name="theme-color" content={theme.palette.colors.backdropColor} />
    </ReactHelmet>
  );
};

Helmet.propTypes = {
  appName: PropTypes.string,
};

export default Helmet;
