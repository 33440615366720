import { connect } from 'react-redux';
import {
  changeFullAudioPlayerViewRoutine,
  pauseTrackRoutine,
  playNextTrackInQueueRoutine,
  preloadQueueTracksRoutine,
  resetPlayerRoutine,
} from 'redux-state/ducks/player';
import AudioPlayer from './audio-player.component';

const mapStateToProps = ({ player, app }) => ({
  queue: player.queue,
  volume: player.volume,
  position: player.position,
  isPlaying: player.isPlaying,
  activeIndex: player.activeIndex,
  playbackRate: player.playbackRate,
  isEbookReaderOpen: player.isEbookReaderOpen,
  isMiniPlayerOpen: Number.isInteger(player.activeIndex),
  isFullAudioPlayerOpened: player.isFullAudioPlayerOpened,
  playback: app.config?.playback,
});

const mapDispatchToProps = {
  pauseTrackAction: pauseTrackRoutine.request,
  resetPlayerAction: resetPlayerRoutine.request,
  preloadQueueTracks: preloadQueueTracksRoutine.request,
  playNextTrackInQueueAction: playNextTrackInQueueRoutine.request,
  changeFullAudioPlayerViewAction: changeFullAudioPlayerViewRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayer);
