import { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { INACTIVE_SESSION_LIFE } from 'constants/env';
import { getPersistentData, SESSION_PERSIST_KEY, setPersistentData } from 'utils/localstorage';

const SessionHandler = memo(({ userId, isPlaying }) => {
  const createSession = useCallback(() => {
    const newSession = {
      id: v4(),
      expirationTime: null,
    };

    setPersistentData(SESSION_PERSIST_KEY, newSession);

    return newSession;
  }, []);

  const checkSessionState = useCallback(() => {
    const session = getPersistentData(SESSION_PERSIST_KEY);

    if (session?.id) {
      const currentTime = new Date().getTime();

      if (session.expirationTime && session.expirationTime < currentTime) {
        createSession();
      } else {
        setPersistentData(SESSION_PERSIST_KEY, { ...session, expirationTime: null });
      }
    } else {
      createSession();
    }
  }, []);

  const onVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      checkSessionState();
    } else if (!isPlaying) {
      let session = getPersistentData(SESSION_PERSIST_KEY);

      if (!session?.id) {
        session = createSession();
      }

      const currentTime = new Date().getTime();
      const expirationTime = currentTime + INACTIVE_SESSION_LIFE;

      setPersistentData(SESSION_PERSIST_KEY, { ...session, expirationTime });
    }
  }, [isPlaying]);

  useEffect(() => {
    if (userId) {
      checkSessionState();
    }
  }, [userId]);

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [isPlaying]);

  return null;
});

SessionHandler.propTypes = {
  userId: PropTypes.string,
  isPlaying: PropTypes.bool.isRequired,
};

export default SessionHandler;
