import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderBookmarkRemoveIcon = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      id="path"
      d="M 12 5.749 C 12 8.762 14.442 11.204 17.454 11.204 C 18.23 11.204 18.968 11.042 19.636 10.75 L 19.636 21.55 C 19.636 21.605 19.627 21.659 19.609 21.711 C 19.52 21.973 19.236 22.112 18.974 22.023 L 18.974 22.023 L 12 19.636 L 5.026 22.023 C 4.973 22.041 4.919 22.05 4.864 22.05 C 4.588 22.05 4.364 21.827 4.364 21.55 L 4.364 21.55 L 4.364 3.773 C 4.364 3.497 4.588 3.273 4.864 3.273 L 4.864 3.273 L 12.593 3.272 C 12.214 4.016 12 4.857 12 5.749 Z M 17.454 1.385 C 19.864 1.385 21.818 3.339 21.818 5.749 C 21.818 8.159 19.864 10.113 17.454 10.113 C 15.045 10.113 13.091 8.159 13.091 5.749 C 13.091 3.339 15.045 1.385 17.454 1.385 Z M 19.511 5.022 L 15.398 5.022 C 15.329 5.022 15.273 5.078 15.273 5.147 L 15.273 6.351 C 15.273 6.42 15.329 6.476 15.398 6.476 L 19.511 6.476 C 19.58 6.476 19.636 6.42 19.636 6.351 L 19.636 5.147 C 19.636 5.078 19.58 5.022 19.511 5.022 Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default ReaderBookmarkRemoveIcon;
