import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import clsx from 'clsx';
import useStyles from './popper-overlay.styles';

const PopperOverlay = memo((props) => {
  const classes = useStyles();
  const { isOpen, anchor, children, onClose, placement, popperClassName, ...restProps } = props;

  return (
    <Popper
      open={isOpen}
      anchorEl={anchor}
      placement={placement}
      className={classes.popover}
      container={document.getElementById('root')}
      {...restProps}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <ClickAwayListener onClickAway={onClose}>
            <Paper className={clsx(classes.popperPaper, popperClassName)}>{children}</Paper>
          </ClickAwayListener>
        </Fade>
      )}
    </Popper>
  );
});

PopperOverlay.propTypes = {
  anchor: PropTypes.shape({}),
  isOpen: PropTypes.bool.isRequired,
  placement: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  popperClassName: PropTypes.string,
  onClose: PropTypes.func,
};

PopperOverlay.defaultProps = {
  placement: 'bottom-end',
  popperClassName: '',
};

export default PopperOverlay;
