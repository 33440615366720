import ASSET from 'constants/asset-types';

export const isAudioContentType = ({ contentType } = {}) => contentType === ASSET.contentType.audio;

export const isVideoContentType = ({ contentType } = {}) => contentType === ASSET.contentType.video;

export const isEbookContentType = ({ contentType } = {}) => contentType === ASSET.contentType.ebook;

export const isPDFEbookType = ({ mediaType, contentType, downloadType } = {}) =>
  isEbookContentType({ contentType }) &&
  downloadType === ASSET.downloadType.fileUrl &&
  mediaType === ASSET.mediaType.pdf;

export const isEpubEbookType = ({ mediaType, contentType, downloadType } = {}) => false

export const isPubhubEbookType = ({ mediaType, contentType, downloadType } = {}) =>
  isEbookContentType({ contentType }) &&
  downloadType === ASSET.downloadType.fileUrl &&
  mediaType === ASSET.mediaType.epubZip;
