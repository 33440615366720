import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import logger from 'utils/logger';
import ERROR_CODES from 'constants/error-codes';
import ROUTES, { getTabPageRoute } from 'utils/routes';
import useContentPermission from 'hook/use-content-permission';

const AuthGuard = (props) => {
  const { state } = useLocation();
  const {
    children,
    redirectLink,
    logoutAction,
    firstTabTarget,
    isAuthenticated,
    firstTabTargetId,
    checkSubscription,
    redirectToFirstTab,
    redirectAuthenticated,
  } = props;
  const { hasSubscriptionFlow, hasContentPermissions } = useContentPermission();
  const availableBySubscription = hasSubscriptionFlow && checkSubscription ? hasContentPermissions : true;
  const renderContent = isAuthenticated && availableBySubscription ? !redirectAuthenticated : redirectAuthenticated;
  const redirectTo = redirectToFirstTab ? getTabPageRoute(firstTabTarget, firstTabTargetId) : redirectLink;
  useEffect(() => {
    if (state?.error && state.error?.response?.status === ERROR_CODES.FORBIDDEN) {
      logger.log('AuthGuard', `Unauthorized error ${ERROR_CODES.FORBIDDEN}`, state?.error);

      logoutAction();
    }
  }, [state]);

  if (state?.error) {
    const routeToRedirect = getTabPageRoute(firstTabTarget, firstTabTargetId);
    logger.log('AuthGuard', `Error to load. Redirecting to ${routeToRedirect}`, state?.error);

    return <Redirect to={routeToRedirect} />;
  }
  return renderContent ? children : <Redirect to={redirectTo} />;
};

AuthGuard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  redirectLink: PropTypes.string,
  firstTabTarget: PropTypes.string,
  firstTabTargetId: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  checkSubscription: PropTypes.bool,
  redirectAuthenticated: PropTypes.bool,
  redirectToFirstTab: PropTypes.bool,
  logoutAction: PropTypes.func.isRequired,
};

AuthGuard.defaultProps = {
  redirectLink: ROUTES.root,
  checkSubscription: false,
  redirectAuthenticated: false,
  redirectToFirstTab: false,
};

export default AuthGuard;
