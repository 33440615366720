import { connect } from 'react-redux';
import { changeActiveTrackIndexRoutine, updateQueueRoutine } from 'redux-state/ducks/player';
import QueueOption from './queue-option.component';

const mapStateToProps = ({ player }) => ({
  queue: player.queue,
  activeIndex: player.activeIndex,
});

const mapDispatchToProps = {
  updateQueueAction: updateQueueRoutine.request,
  changeActiveTrackIndexAction: changeActiveTrackIndexRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(QueueOption);
