import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import ONBOARDING_FLOW_TYPES from 'constants/onboarding-flow-types';

const usePermissions = () => {
  const usesPermissions = useSelector(
    ({ app }) => app?.config?.uses_permissions ?? false,
  );

  return usesPermissions;
};

export default usePermissions;
