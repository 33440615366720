import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderLineHeightLgIcon = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g>
      <path
        d="M13.351 17c.359 0 .649.323.649.722 0 .399-.29.722-.649.722H1.65c-.359 0-.649-.323-.649-.722 0-.399.29-.722.649-.722zM18 5c.114 0 .223.053.303.147l1.571 1.998c.08.094.126.221.126.354 0 .133-.045.26-.126.354-.08.094-.19.147-.303.147-.114 0-.223-.053-.304-.147l-.767-.997v8.288l.768-.997c.108-.127.266-.176.414-.13.148.046.264.181.303.354.04.173-.002.357-.11.483l-1.572 1.999c-.08.094-.19.147-.303.147-.114 0-.223-.053-.303-.147l-1.571-1.999c-.08-.093-.126-.22-.126-.353 0-.133.045-.26.126-.354.08-.094.19-.147.303-.147.114 0 .223.053.304.147l.767.995V6.857l-.768.996c-.108.126-.266.176-.414.13-.148-.047-.264-.182-.303-.354-.04-.173.002-.357.11-.484l1.572-1.998c.08-.094.19-.147.303-.147zm-4.649 5c.359 0 .649.323.649.722 0 .399-.29.722-.649.722H1.65c-.359 0-.649-.323-.649-.722 0-.399.29-.722.649-.722zm0-7c.359 0 .649.323.649.722 0 .399-.29.722-.649.722H1.65C1.29 4.444 1 4.12 1 3.722 1 3.323 1.29 3 1.649 3z"
        transform="translate(-872.000000, -239.000000) translate(698.000000, 79.000000) translate(13.000000, 148.750000) translate(161.000000, 11.250000)"
      />
    </g>
  </SvgIcon>
);

export default ReaderLineHeightLgIcon;
