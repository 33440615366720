import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderBrightnessLowIcon = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g>
      <path
        d="M3.831 10.178c-.459 0-.831.365-.831.822 0 .454.362.822.831.822h.793c.458 0 .83-.365.83-.822 0-.454-.362-.822-.83-.822H3.83zM11 3c-.452 0-.818.358-.818.82v.784c0 .453.363.82.818.82.452 0 .818-.357.818-.82V3.82c0-.453-.363-.821-.818-.821zm5.545 8c0 .454.363.822.831.822h.793c.459 0 .831-.365.831-.822 0-.454-.362-.822-.831-.822h-.793c-.458 0-.83.365-.83.822zM11 16.575l.103.007c.406.05.715.396.715.814v.783c0 .463-.366.821-.818.821-.455 0-.818-.368-.818-.82v-.784c0-.463.366-.82.818-.82zm-4.797-1.94c.331-.332.848-.328 1.169-.007l.074.085c.246.324.217.787-.08 1.084l-.561.56c-.332.332-.848.328-1.169.007l-.074-.085c-.246-.324-.217-.786.08-1.084zm8.53-.06c.323-.245.782-.217 1.075.076l.554.554c.328.327.321.84.002 1.159-.322.322-.838.319-1.159-.002l-.554-.554c-.327-.327-.321-.839-.002-1.159zm.462-8.933c.332-.331.848-.327 1.169-.006l.074.085c.246.324.217.786-.08 1.084l-.56.56c-.332.332-.849.328-1.17.007-.323-.324-.318-.845.007-1.17zm-9.559-.006c.322-.322.838-.319 1.159.002l.554.554c.327.327.321.839.002 1.159l-.085.073c-.322.246-.78.218-1.074-.075l-.554-.554c-.328-.327-.321-.84-.002-1.159zM11 8c1.657 0 3 1.347 3 3s-1.343 3-3 3-3-1.347-3-3 1.343-3 3-3z"
        transform="translate(-723.000000, -103.000000) translate(698.000000, 79.000000) translate(13.000000, 13.000000) translate(12.000000, 11.000000)"
      />
    </g>
  </SvgIcon>
);

export default ReaderBrightnessLowIcon;
