import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  menuList: {
    padding: 0,
  },
  menuItem: {
    width: 206,
    height: 62,
    fontSize: 14,
    display: 'flex',
    paddingLeft: 18,
    paddingRight: 14,
    justifyContent: 'space-between',
    backgroundColor: theme.palette.colors.surfaceColor,
  },
  menuItemText: {
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '24px',
    fontFamily: 'ARSMaquetteProRegular',
    color: theme.palette.colors.surfaceOnColor,
  },
}));

export default useStyles;
