import logger from 'utils/logger';

const RETRY_TIMEOUT = 1500;
const MAX_NUMBER_OF_RETRIES = 3;

const getTOCItems = (readerRef) =>
  readerRef.current.navigator.publication.TOC.map((item) => {
    return {
      title: item.Title,
      href: item.Href,
    }
  })

const getBookmarkItems = (readerRef) => readerRef.current.bookmarks;

const getAnnotationItems = (readerRef) => readerRef.current.annotations;

export const loadNavItems = async (params) => {
  const { readerRef, callback, logName, retry = 0 } = params;

  try {
    const items = await callback(readerRef);

    logger.log(logName, `Items were successfully loaded`, items);

    return items;
  } catch (error) {
    if (retry <= MAX_NUMBER_OF_RETRIES) {
      logger.log(logName, `Retrying to load items. Attempt: ${retry}.`, error);

      return new Promise((resolve) => {
        setTimeout(async () => {
          const retryItems = await loadNavItems({ ...params, retry: retry + 1 });

          resolve(retryItems);
        }, RETRY_TIMEOUT);
      });
    }
    logger.log(logName, `Failed to load items. Attempted ${retry} times.`, error);

    return [];
  }
};

export const loadTOCItems = async (readerRef) =>
  loadNavItems({ readerRef, callback: getTOCItems, logName: 'getTOCItems' });

export const loadBookmarkItems = async (readerRef) =>
  loadNavItems({ readerRef, callback: getBookmarkItems, logName: 'getBookmarkItems' });

export const loadAnnotationItems = async (readerRef) =>
  loadNavItems({ readerRef, callback: getAnnotationItems, logName: 'getAnnotationItems' });

export const NAVIGATION_TABS = Object.freeze({
  chapters: 'chapters',
  bookmarks: 'bookmarks',
  annotation: 'annotation',
});

export const getTabA11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

export const getEmptyMessage = (value) =>
({
  [NAVIGATION_TABS.bookmarks]: {
    title: 'ebook_no_bookmarks_title',
    subtitle: 'ebook_no_bookmarks_subtitle',
  },
  [NAVIGATION_TABS.annotation]: {
    title: 'ebook_no_annotations_title',
    subtitle: 'ebook_no_annotations_subtitle',
  },
}[value]);

export const TAB_OPTIONS = Object.freeze([
  {
    value: NAVIGATION_TABS.chapters,
    label: 'ebook_navigation_section_toc',
    ...getTabA11yProps(NAVIGATION_TABS.chapters),
  },
  {
    value: NAVIGATION_TABS.bookmarks,
    label: 'ebook_navigation_section_bookmarks',
    ...getTabA11yProps(NAVIGATION_TABS.bookmarks),
  },
  {
    value: NAVIGATION_TABS.annotation,
    label: 'ebook_navigation_section_annotations',
    ...getTabA11yProps(NAVIGATION_TABS.annotation),
  },
]);
