import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { PauseSmIcon, PlaySmIcon } from 'components/icons';
import useStyles from './navigation.mobile..styles';

const NavigationMobile = memo((props) => {
  const classes = useStyles();
  const { isPlaying, playTrackAction, pauseTrackAction } = props;

  const handlePlayClick = useCallback(() => {
    playTrackAction();
  }, []);

  const handlePauseClick = useCallback(() => {
    pauseTrackAction();
  }, []);

  return (
    <Box className={classes.container}>
      {isPlaying ? (
        <IconButton onClick={handlePauseClick}>
          <PauseSmIcon className={classes.icon} />
        </IconButton>
      ) : (
        <IconButton onClick={handlePlayClick}>
          <PlaySmIcon className={classes.icon} />
        </IconButton>
      )}
    </Box>
  );
});

NavigationMobile.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  playTrackAction: PropTypes.func.isRequired,
  pauseTrackAction: PropTypes.func.isRequired,
};

export default NavigationMobile;
