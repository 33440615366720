const ASSET = Object.freeze({
  contentType: Object.freeze({
    audio: 'audio',
    video: 'video',
    ebook: 'ebook',
    'live-audio': 'live-audio',
  }),
  mediaType: Object.freeze({
    pdf: 'application/pdf',
    epubZip: 'application/epub+zip',
    appleMpegurl: 'application/vnd.apple.mpegurl',
  }),
  downloadType: Object.freeze({
    pubhub: 'pubhub-order-id',
    fileUrl: 'file-url',
    hlsUrl: 'hls-url',
  }),
});

export default ASSET;
