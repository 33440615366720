import makeStyles from '@material-ui/core/styles/makeStyles';
import { darken } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  colorThemeContainer: {
    width: '100%',
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
  },
  lightThemeIcon: {
    color: '#FFF',
    strokeWidth: 1,
    '@media (hover: hover)': {
      '&:hover': {
        color: darken('#FFF', 0.1),
      },
    },
  },
  activeLightThemeIcon: {
    stroke: theme.palette.colors.primaryColor,
  },
  activeLightSepiaThemeIcon: {
    stroke: theme.palette.colors.backdropMutedColor,
  },
  sepiaThemeIcon: {
    color: '#F3E7D1',
    stroke: '#FFFAF5',
    strokeWidth: 1,
    '@media (hover: hover)': {
      '&:hover': {
        color: darken('#F3E7D1', 0.1),
      },
    },
  },
  activeSepiaThemeIcon: {
    stroke: theme.palette.colors.primaryColor,
  },
  darkThemeIcon: {
    strokeWidth: 1,
    color: '#000',
    '@media (hover: hover)': {
      '&:hover': {
        color: darken('#000', 0.1),
      },
    },
  },
  activeDarkThemeIcon: {
    stroke: theme.palette.colors.primaryColor,
  },
}));

export default useStyles;
