import { connect } from 'react-redux';
import { playTrackRoutine, pauseTrackRoutine } from 'redux-state/ducks/player';
import NavigationMobile from './navigation.mobile.component';

const mapStateToProps = ({ player }) => ({
  isPlaying: player.isPlaying,
});

const mapDispatchToProps = {
  playTrackAction: playTrackRoutine.request,
  pauseTrackAction: pauseTrackRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMobile);
