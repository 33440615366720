import React, { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, List } from 'react-virtualized';
import { useHistory } from 'react-router';
import useTrackItemSize from 'hook/use-track-item-size';
import { getTrackPageRoute } from 'utils/routes';
import { favoritesPropTypes } from 'utils/prop-types';
import TrackItem from 'components/track-list/track-item';

const ListResult = memo(
  ({ tracks, favorites, replaceQueueAction, addToFavoritesAction, removeFromFavoritesAction }) => {
    const itemStyleProps = useTrackItemSize({
      m: 0.271,
      l: 0.38,
      s: 0.35,
      xs: 0.301,
      xl: 0.36,
    });
    const history = useHistory();

    const listRef = useRef();

    const handlePlayClick = useCallback(
      (activeIndex) => (event) => {
        event.stopPropagation();
        replaceQueueAction({ queue: tracks, activeIndex });
      },
      [tracks],
    );

    const handleInfoBtnClick = useCallback(
      (trackId) => () => {
        history.push(getTrackPageRoute(trackId));
      },
      [],
    );

    const isInFavorites = useCallback((id) => favorites.indexOf(id) !== -1, [favorites]);

    const addToFavorites = useCallback((id) => {
      addToFavoritesAction(id);
    }, []);

    const removeFromFavorites = useCallback((id) => {
      removeFromFavoritesAction(id);
    }, []);

    const getRowHeight = useCallback(
      ({ index }) => (index === 0 ? itemStyleProps.calculatedHeight + 36 : itemStyleProps.calculatedHeight),
      [itemStyleProps.width],
    );

    useEffect(() => {
      if (listRef.current) {
        listRef.current.recomputeRowHeights();
      }
    }, [itemStyleProps.width]);

    return (
      <AutoSizer>
        {({ width, height }) => (
          <List
            ref={listRef}
            width={width}
            height={height}
            overscanRowCount={10}
            rowHeight={getRowHeight}
            overscanIndicesGetter={({ cellCount, overscanCellsCount, startIndex, stopIndex }) => ({
              overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
              overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
            })}
            rowCount={tracks.length}
            rowRenderer={({ key, index, style }) => (
              <div key={key} style={{ ...style, ...(index === 0 ? { top: 36 } : undefined) }}>
                <TrackItem
                  trackIndex={index}
                  isInFavorites={isInFavorites}
                  onPlayClick={handlePlayClick}
                  itemStyleProps={itemStyleProps}
                  addToFavorites={addToFavorites}
                  onInfoIconClick={handleInfoBtnClick}
                  removeFromFavorites={removeFromFavorites}
                  {...tracks[index]}
                />
              </div>
            )}
          />
        )}
      </AutoSizer>
    );
  },
);

ListResult.propTypes = {
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  favorites: favoritesPropTypes,
  replaceQueueAction: PropTypes.func.isRequired,
  addToFavoritesAction: PropTypes.func.isRequired,
  removeFromFavoritesAction: PropTypes.func.isRequired,
};

export default ListResult;
