import PropTypes from 'prop-types';

export const playerSharedPropTypes = Object.freeze({
  duration: PropTypes.number,
  playerRef: PropTypes.shape({
    current: PropTypes.shape({
      volume: PropTypes.number.isRequired,
      currentTime: PropTypes.number.isRequired,
      playbackRate: PropTypes.number.isRequired,
    }),
  }),
  currentTime: PropTypes.number,
});

export const queuePropType = PropTypes.arrayOf(
  PropTypes.shape({
    trackId: PropTypes.number,
    downloadUrl: PropTypes.string,
  }),
).isRequired;
