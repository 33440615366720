import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  activeBookmarkIcon: {
    width: 22,
    height: 22,
    color: theme.palette.colors.primaryColor,
  },
}));

export default useStyles;
