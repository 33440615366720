/* eslint-disable import/no-cycle */
import { createRoutine } from 'redux-saga-routines';
import { select, put, call, takeLatest } from 'redux-saga/effects';
import logger from 'utils/logger';
import ROUTES from 'utils/routes';
import { isEbookContentType } from 'utils/assets';
import { getActiveTrackIndex, getQueue } from '../player';
import { getUserId } from '../auth';
import { loadUserHistory, saveUserHistory } from 'utils/firestore';


export const getHistoryRoutine = createRoutine('GET_HISTORY');
export const addToHistoryRoutine = createRoutine('ADD_TO_HISTORY');

export const initialState = Object.freeze({
  history: {},
});

export const historyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case getHistoryRoutine.SUCCESS:
    case addToHistoryRoutine.SUCCESS:
      return {
        ...state,
        history: payload,
      };
    default:
      return { ...state };
  }
};

export function* getHistorySaga() {

  try {
    const userHistoryItems = yield loadUserHistory()
    yield put(getHistoryRoutine.success(userHistoryItems));
    return userHistoryItems;
  } catch (error) {
    logger.error('getHistorySaga', 'Unable to get history items', error);
  }

  return null;
}
export function* addToHistorySaga({ payload: data }) {
  const { progress, duration } = data;
  const queue = yield select(getQueue);
  const activeQueueIndex = yield select(getActiveTrackIndex);
  const activeQueueItem = queue?.[activeQueueIndex];
  const { trackId, assetId, contentType } = activeQueueItem ?? {};
  const newHistoryItem = {
    track_id: trackId,
    asset_id: assetId,
    duration: Math.floor(duration),
    timestamp: new Date(),
    progress: Math.floor(progress || 0),
    progress_percent: progress / duration,
    is_finished: isEbookContentType({ contentType }) ? false : duration - progress < 10,
  };
  try {
    if (Number.isInteger(trackId) && Number.isInteger(assetId)) {
      saveUserHistory(assetId, newHistoryItem)
      if (window?.location?.pathname?.includes(ROUTES.library)) {
        yield call(getHistorySaga);
      }
    }
  } catch (error) {
    logger.error('addToHistorySaga', 'Unable to add history item', error);
  }
}

export function* watchHistorySagas() {
  yield takeLatest(getHistoryRoutine.REQUEST, getHistorySaga);
  yield takeLatest(addToHistoryRoutine.REQUEST, addToHistorySaga);
}
