import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 17,
    height: 17,
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  paper: {
    zIndex: 9,
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
  },
  queueMenu: {
    padding: 0,
    borderRadius: 13,
    height: ({ height }) => height,
    maxHeight: 240,
    minWidth: 240,
    overflowY: 'auto',
    backgroundColor: theme.palette.colors.surfaceColor,
  },
  queueMenuItem: {
    minHeight: 36,
    color: theme.palette.colors.surfaceOnColor,
    '&:hover > *': {
      color: theme.palette.colors.primaryColor,
    },
  },
  queueMenuItemText: {
    maxWidth: 240,
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '24px',
    fontFamily: 'ARSMaquetteProRegular',
    color: 'inherit',
  },
  activeQueueMenuItem: {
    color: theme.palette.colors.primaryColor,
  },
  popover: {
    zIndex: 11,
  },
}));

export default useStyles;
