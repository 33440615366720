import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import usePlayer from 'hook/use-player';
import { isMobile } from 'utils/user-agent';
import { CONTENT_WIDTH } from 'utils/themes/sizes';
import useMediaQuery from 'hook/use-media-query';
import useWindowSize from 'hook/use-window-size';
import { playerSharedPropTypes } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Ping from 'components/ping';
import Loader from 'components/loader';
import MiniPlayer from 'components/mini-player';
import QueuePersistence from 'components/queue-persistence';
import HistoryPersistence from 'components/history-persistence';
import Timeline from 'components/player-components/timeline';
import clsx from 'clsx';
import FullScreenHeader from 'components/full-screen-components/header';
import ControlPanel from './components/control-panel';
import Navigation from './components/navigation';
import useStyles from './video-player.styles';
import useSuspendPinchZoom from '../../hook/use-suspend-pinch-zoom';

const VideoPlayer = memo((props) => {
  const {
    queue,
    volume,
    position,
    isPlaying,
    isPIPMode,
    activeIndex,
    playbackRate,
    pauseTrackAction,
    resetPlayerAction,
    isEbookReaderOpen,
    changeIsPIPModeAction,
    isFullVideoPlayerOpened,
    playNextTrackInQueueAction,
    changeFullVideoPlayerViewAction,
  } = props;

  const isMobileDevice = isMobile();
  const isTablet = useMediaQuery('tablet');
  const isPIPModeEnabled = !isMobileDevice;
  const { width, height } = useWindowSize();

  const activeTrack = queue?.[activeIndex] ?? {};

  const [isDistractionFreeMode, setIsDistractionFreeMode] = useState(false);

  const videoMaxHeight = useMemo(() => height - 227, [height]);
  const videoMaxWidth = useMemo(() => Math.min(CONTENT_WIDTH, width), [width]);

  const classes = useStyles({ videoMaxHeight, videoMaxWidth, backgroundImage: activeTrack?.image });

  const [playerRef, { duration, isLoading, currentTime }] = usePlayer({
    volume,
    position,
    isPlaying,
    activeTrack,
    playbackRate,
    pauseTrackAction,
    resetPlayerAction,
    isEbookReaderOpen,
    playNextTrackInQueueAction,
    initialPosition: position || 0,
    initialDuration: activeTrack?.duration / 1000 || 0,
  });

  useEffect(() => {
    document.body.style.overflowY = isFullVideoPlayerOpened ? 'hidden' : 'unset';
  }, [isFullVideoPlayerOpened]);

  const closeFullPlayerView = useCallback(() => {
    if (isPIPModeEnabled) {
      changeIsPIPModeAction(true);
    } else {
      pauseTrackAction();
    }

    changeFullVideoPlayerViewAction(false);
  }, [isPIPModeEnabled]);

  const closePIPMode = useCallback(() => {
    pauseTrackAction();
    changeIsPIPModeAction(false);
  }, []);

  const isDisplayPIPMode = isPIPModeEnabled && isPIPMode;

  const toggleDistractionFreeMode = useCallback(() => {
    if (!isDisplayPIPMode) {
      setIsDistractionFreeMode((current) => !current);
    }
  }, [isDisplayPIPMode]);

  const playerContainerRef = useSuspendPinchZoom({ trigger: isFullVideoPlayerOpened || isDisplayPIPMode });

  const renderControlPanel = () => <ControlPanel playerRef={playerRef} />;

  return (
    <>
      <Zoom in={isFullVideoPlayerOpened || isDisplayPIPMode} exit={false} timeout={470}>
        <Box
          ref={playerContainerRef}
          className={clsx(classes.playerContainer, isDisplayPIPMode && classes.playerContainerPIPMode)}
        >
          <Box className={classes.playerContent}>
            <Fade in={isFullVideoPlayerOpened && !isDisplayPIPMode && !isDistractionFreeMode}>
              <Box>
                <FullScreenHeader
                  height={104}
                  item={activeTrack}
                  onClose={closeFullPlayerView}
                  nameClassName={classes.textColor}
                  creatorClassName={classes.textColor}
                  renderControlPanel={isTablet ? renderControlPanel : undefined}
                />
              </Box>
            </Fade>
            <Box
              className={clsx(
                classes.videoContainer,
                isDisplayPIPMode && !isFullVideoPlayerOpened && classes.videoContainerPIPMode,
              )}
            >
              <div
                className={clsx(classes.videoContent, isDisplayPIPMode && classes.videoContentPIPMode)}
                onClick={isMobileDevice ? toggleDistractionFreeMode : undefined}
              >
                <video
                  playsInline
                  ref={playerRef}
                  autoPlay={false}
                  preload="metadata"
                  className={clsx(classes.videoElement, isDisplayPIPMode && classes.videoElementPIPMode)}
                />
                <Fade in={isDisplayPIPMode && !isFullVideoPlayerOpened}>
                  <Box>
                    <Button onClick={closePIPMode} className={classes.closeIconBtn} />
                  </Box>
                </Fade>
                {isLoading && <Loader />}
              </div>
            </Box>
            <Fade in={isFullVideoPlayerOpened && !isDisplayPIPMode && !isDistractionFreeMode}>
              <Box>
                {!!playerRef.current && (
                  <>
                    <Box className={classes.timelineContainer}>
                      <Timeline
                        duration={duration}
                        timePosition="top"
                        railClassName={classes.timelineRail}
                        elapsedTimeClassName={classes.timelineText}
                        totalTimeClassName={classes.timelineText}
                        currentTime={currentTime}
                        playerRef={playerRef}
                      />
                    </Box>
                    <Box className={classes.playerFooter}>
                      <Navigation playerRef={playerRef} iconClassName={classes.textColor} />
                      {!isTablet && <ControlPanel playerRef={playerRef} />}
                    </Box>
                  </>
                )}
              </Box>
            </Fade>
          </Box>
        </Box>
      </Zoom>
      <MiniPlayer playerRef={playerRef} duration={duration} currentTime={currentTime} />
      <QueuePersistence playerRef={playerRef} />
      <HistoryPersistence playerRef={playerRef} />
      <Ping playerRef={playerRef} duration={duration} />
    </>
  );
});

VideoPlayer.propTypes = {
  ...playerSharedPropTypes,
  isFullVideoPlayerOpened: PropTypes.bool.isRequired,
};

export default VideoPlayer;
