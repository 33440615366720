import makeStyles from '@material-ui/core/styles/makeStyles';
import { alpha } from '@material-ui/core/styles';
import CloseIcon from 'base/icons/close.png';

const useStyles = makeStyles((theme) => ({
  playerContainer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 11,
    position: 'fixed',
    transition: 'all 1.5s',
    paddingBottom: 'env(safe-area-inset-bottom)',
    backgroundColor: theme.videoPlayer.colors.videoBackdropColor,
  },
  playerContainerPIPMode: {
    width: 342,
    height: 192,
    bottom: 80.5,
    left: 'unset',
    top: 'unset',
  },
  playerContent: {
    padding: 0,
    zIndex: 9,
    width: '100%',
    height: '100%',
    maxWidth: 1024,
    display: 'flex',
    margin: '0 auto',
    position: 'relative',
    flexDirection: 'column',
  },
  videoContainer: {
    height: ({ videoMaxHeight }) => videoMaxHeight,
    width: ({ videoMaxWidth }) => videoMaxWidth,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  videoContainerPIPMode: {
    height: () => 'unset',
    marginBottom: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  videoContent: {
    position: 'relative',
  },
  videoContentPIPMode: {
    maxHeight: () => 192,
  },
  videoElement: {
    width: ({ videoMaxWidth }) => videoMaxWidth,
    maxWidth: ({ videoMaxWidth }) => videoMaxWidth,
    maxHeight: ({ videoMaxHeight }) => videoMaxHeight,
  },
  videoElementPIPMode: {
    width: () => '100%',
    maxHeight: () => 192,
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.6,
    filter: 'blur(50px)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transition: 'background-image 0.2s ease-in-out',
    backgroundImage: ({ backgroundImage }) => `url(${backgroundImage})`,
  },
  timelineContainer: {
    position: 'relative',
    margin: '14px 28px 0',
    height: 14,
    minHeight: 14,
    maxHeight: 14,
  },
  timelineRail: {
    color: theme.videoPlayer.colors.videoBackdropOnColor,
  },
  timelineText: {
    color: theme.videoPlayer.colors.videoBackdropOnColor,
  },
  bottomFadeEffect: {
    position: 'absolute',
    width: '100%',
    height: 529,
    bottom: 0,
    backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.colors.backdropColor, 0)}, ${
      theme.palette.colors.backdropColor
    } 100%)`,
  },
  playerFooter: {
    display: 'flex',
    minHeight: 75,
    maxHeight: 75,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeIconBtn: {
    width: 22,
    height: 22,
    minWidth: 22,
    maxHeight: 22,
    zIndex: 1,
    borderRadius: 11,
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${CloseIcon})`,
    position: 'absolute',
    right: 11,
    top: 11,
    '@media (hover: hover)': {
      '&:hover': {
        filter: 'brightness(0.9)',
      },
    },
  },
  textColor: {
    color: theme.videoPlayer.colors.videoBackdropOnColor,
  },
}));

export default useStyles;
