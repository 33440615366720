import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  trackNavigation: {
    flex: 1,
    display: 'flex',
    marginLeft: 16,
    marginBottom: 16,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  icon: {
    width: 42,
    height: 42,
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      width: 38,
      height: 38,
    },
  },
  iconBtn: {
    padding: 0,
    marginRight: 18,
  },
}));

export default useStyles;
