import { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { HISTORY_SAVE_INTERVAL } from 'constants/env';
import { playerSharedPropTypes } from 'utils/prop-types';

const HistoryPersistence = memo((props) => {
  const { playerRef, addToHistoryAction } = props;

  const intervalIdRef = useRef(null);
  const [timer, setTimer] = useState(0);

  const handlePlaying = useCallback(() => {
    clearInterval(intervalIdRef.current);

    const id = setInterval(() => {
      setTimer((current) => current + 1);
    }, 1000);

    intervalIdRef.current = id;
  }, []);

  const handlePause = useCallback(() => {
    clearInterval(intervalIdRef.current);
  }, []);

  const handleLoadStart = useCallback(() => {
    setTimer(0);
    clearInterval(intervalIdRef.current);
  }, []);

  useEffect(() => {
    const updateIntervalInSec = HISTORY_SAVE_INTERVAL / 1000;

    if (timer >= updateIntervalInSec) {
      addToHistoryAction({ progress: playerRef?.current?.currentTime || 0, duration: playerRef?.current?.duration });
      setTimer(0);
    }
  }, [timer]);

  useEffect(() => {
    const playerInstance = playerRef.current;

    playerInstance.addEventListener('pause', handlePause);
    playerInstance.addEventListener('playing', handlePlaying);
    playerInstance.addEventListener('loadstart', handleLoadStart);

    return () => {
      clearInterval(intervalIdRef.current);
      playerInstance.removeEventListener('pause', handlePause);
      playerInstance.removeEventListener('playing', handlePlaying);
      playerInstance.removeEventListener('loadstart', handleLoadStart);
    };
  }, []);

  return null;
});

HistoryPersistence.propTypes = {
  playerRef: playerSharedPropTypes.playerRef,
  addToHistoryAction: PropTypes.func.isRequired,
};

export default HistoryPersistence;
