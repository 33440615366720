import { mapTrackToAsset } from 'api/utils';
import { TRACK_ASSET_TYPE } from '../constants';

export const mapTracks = (loadedTracks, initialTrackAssetIds) => {
  const resultItems = [];
  const mappedLoadedTracks = loadedTracks.reduce((acc, item) => ({ ...acc, [item?.id]: item }), {});

  Object.keys(initialTrackAssetIds).forEach((key) => {
    const [trackId, assetId] = key.split('_');
    const trackItem = (
      mappedLoadedTracks[trackId] || 
      {trackId: Number(trackId), assetId: Number(assetId), itemRemoved: true}
    );

    const assetIndex = trackItem?.assets?.findIndex((asset) => `${asset?.id}` === `${assetId}`);
    
    if (assetIndex !== -1) {
      resultItems.push({ trackItem, assetIndex });
    } else {
      resultItems.push({ trackItem, assetIndex: undefined });
    }
  });
  const mappedAssets = resultItems.map(({ trackItem, assetIndex }) => { 
    if (trackItem.itemRemoved) return { ...trackItem, id: trackItem.trackId};
    else return mapTrackToAsset(trackItem, assetIndex);
  });
  return mappedAssets;
};

export const mapTrackPage = (track) => ({
  ...mapTrackToAsset(track),
  ...track?.assets.reduce(
    (acc, { type }, index) => ({
      primaryAssets:
        type === TRACK_ASSET_TYPE.primary ? [...acc.primaryAssets, mapTrackToAsset(track, index)] : acc.primaryAssets,
      secondaryAssets:
        type === TRACK_ASSET_TYPE.secondary
          ? [...acc.secondaryAssets, mapTrackToAsset(track, index)]
          : acc.secondaryAssets,
    }),
    {
      primaryAssets: [],
      secondaryAssets: [],
    },
  ),
});
