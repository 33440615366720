import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  marginContainer: {
    width: '100%',
    height: 44,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 14,
    paddingRight: 14,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default useStyles;
