import React, { memo, useCallback, useMemo } from 'react';
import Icons from 'components/icons';
import useMediaQuery from 'hook/use-media-query';
import ControlButton from 'components/common/control-button';

const isFullScreen = () =>
  window.document?.webkitIsFullScreen ||
  window.document?.fullscreenElement ||
  window.document?.webkitFullscreenElement ||
  window.document?.mozFullScreenElement ||
  window.document?.msFullscreenElement;

const isFullScreenSupported = () =>
  window.document?.fullscreenEnabled ||
  window.document?.webkitFullscreenEnabled ||
  window.document?.mozFullScreenEnabled ||
  window.document?.msFullScreenEnabled;

const FullScreenButton = memo((props) => {
  const isMobileFullPlayer = useMediaQuery('mobileFullPlayer');
  const isFullScreenEnabled = useMemo(() => !!isFullScreenSupported(), []);

  const toggleFullScreenMode = useCallback(() => {
    if (isFullScreen()) {
      if (window.document?.exitFullscreen) {
        window.document?.exitFullscreen();
      } else if (window.document?.mozCancelFullScreen) {
        window.document?.mozCancelFullScreen();
      } else if (window.document?.webkitExitFullscreen) {
        window.document?.webkitExitFullscreen();
      }
    } else if (window.document?.documentElement?.requestFullscreen) {
      window.document?.documentElement?.requestFullscreen();
    } else if (window.document?.documentElement?.mozRequestFullScreen) {
      window.document?.documentElement?.mozRequestFullScreen();
    } else if (window.document?.documentElement?.webkitRequestFullscreen) {
      window.document?.documentElement?.webkitRequestFullscreen();
    } else if (window.document?.documentElement?.msRequestFullscreen) {
      window.document?.documentElement?.msRequestFullscreen();
    }
  }, []);

  return isFullScreenEnabled && !isMobileFullPlayer ? (
    <ControlButton icon={Icons.FullScreen} onClick={toggleFullScreenMode} {...props} />
  ) : null;
});

export default FullScreenButton;
