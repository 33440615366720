/* eslint-disable import/no-cycle */
import * as Realm from 'realm-web';
import { getJWTToken } from 'api/realm';
import { createRoutine } from 'redux-saga-routines';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import logger from 'utils/logger';

const DEFAULT_ATLAS_SERVICE_NAME = 'mongodb-atlas';

export const initRealmRoutine = createRoutine('INIT_REALM');

export const initialState = Object.freeze({
  app: null,
  user: null,
  client: null,
  database: null,
  databaseName: null,
});

export const realmReducer = (state = initialState, action) => {
  switch (action.type) {
    case initRealmRoutine.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case initRealmRoutine.FAILURE:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
};

export const getRealmApp = ({ realm }) => realm.app;
export const isRealmSupported = ({ realm }) => !!realm.app;
export const getRealmDatabase = ({ realm }) => realm.database;
export const getDatabaseName = ({ realm }) => realm.databaseName;

const logInRealmUser = async ({ app, credentials }) => app.logIn(credentials);

export function* initRealmSaga() {
  const app = yield select(getRealmApp);
  const isRealmAvailable = yield select(isRealmSupported);
  const databaseName = yield select(getDatabaseName);

  try {
    let database;

    if (isRealmAvailable) {
      logger.log('initRealmUserSaga', 'Initializing Realm.');
      const jwtToken = yield call(getJWTToken);
      // Create a JWT credential
      const credentials = Realm.Credentials.jwt(jwtToken);
      // Authenticate the user
      const user = yield call(logInRealmUser, { app, credentials });
      // Create client instance
      const client = app.currentUser.mongoClient(DEFAULT_ATLAS_SERVICE_NAME);
      // Create database instance
      database = client.db(databaseName);

      yield put(initRealmRoutine.success({ user, client, database }));
    } else {
      yield put(initRealmRoutine.failure());
      logger.log('initRealmUserSaga', 'Realm is not available.');
    }

    return database;
  } catch (err) {
    logger.error('initRealmUserSaga', 'Error while authenticating Realm user.', err);
  }

  return null;
}

export function* watchRealmSagas() {
  yield takeLatest(initRealmRoutine.request, initRealmSaga);
}
