import { connect } from 'react-redux';
import Footer from './footer.component';

const mapStateToProps = ({ app, player }) => ({
  appName: app?.config?.appName,
  termsLink: app?.config?.termsLink,
  isMiniPlayerOpen: Number.isInteger(player.activeIndex),
});

export default connect(mapStateToProps)(Footer);
