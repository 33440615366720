import api from 'utils/api';
import { BASE_API_URL } from 'api/constants';
import { mapTracks, mapTrackPage } from './tracks.utils';

export const getTrackPage = async (trackId) =>
  api.get(`${BASE_API_URL}/tracks/${trackId}/`).then((response) => mapTrackPage(response.data));

export const getTracks = async (trackAssetsIds) =>
  api
    .get(`${BASE_API_URL}/tracks/?ids=${[...new Set(Object.values(trackAssetsIds))].join(',')}`)
    .then((response) => mapTracks(response.data, trackAssetsIds));
