import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderFontIncreaseIcon = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <defs>
      <filter id="6b3ht4frea" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.090196 0 0 0 0 0.090196 0 0 0 0 0.090196 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g>
      <g transform="translate(-872.000000, -151.000000) translate(698.000000, 79.000000) translate(13.000000, 60.535714) translate(161.000000, 11.464286)">
        <g>
          <path
            d="M3.271 16l1.623-4.084h7.109L13.626 16H17L10.277 0H6.723L0 16h3.271zm7.908-6.438H5.692l2.782-7.088 2.705 7.088z"
            transform="translate(1.000000, 3.000000)"
          />
        </g>
      </g>
      <path
        d="M18.418 9h-.835c-.138 0-.25.112-.25.25v1.083H16.25c-.138 0-.25.112-.25.25v.834c0 .138.112.25.25.25h1.099l-.013 1.08c-.001.138.11.251.247.253h.835c.138 0 .25-.112.25-.25v-1.083h1.082c.138 0 .25-.112.25-.25v-.834c0-.138-.112-.25-.25-.25h-1.082V9.25c0-.138-.112-.25-.25-.25z"
        transform="translate(-872.000000, -151.000000) translate(698.000000, 79.000000) translate(13.000000, 60.535714) translate(161.000000, 11.464286)"
      />
    </g>
  </SvgIcon>
);

export default ReaderFontIncreaseIcon;
