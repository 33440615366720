import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  readerViewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    width: 'calc(100% - 120px)',
    margin: '0 auto',
    maxHeight: ({ pageMaxHeight }) => pageMaxHeight,
    maxWidth: ({ pageMaxWidth }) => pageMaxWidth,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 'calc(100% - 40px)',
    },
  },
  readerContainer: {
    maxHeight: ({ pageMaxHeight }) => pageMaxHeight,
    maxWidth: ({ pageMaxWidth }) => pageMaxWidth,
  },
  emptySelection: {
    opacity: 0,
  },
  readerView: {
    maxHeight: ({ pageMaxHeight }) => pageMaxHeight,
    maxWidth: ({ pageMaxWidth }) => pageMaxWidth,
    overflow: 'hidden',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 5px 20px',
    lineHeight: 'initial',
    '& .react-pdf': {
      '&__Page__textContent': {
        width: 'calc(100% - 2px) !important',
        height: 'calc(100% - 2px) !important',
        '& span': {
          display: 'inline-block',
        },
        '& span > span': {
          opacity: 0.8,
        },
      },
    },
  },
  transformComponent: {
    userSelect: 'unset',
  },
  arrowBg: {
    width: 48,
    height: 290,
    opacity: 0.5,
    display: 'flex',
    borderRadius: 6,
    top: 'calc(50% - 145px)',
    position: 'absolute',
    transition: 'opacity 0.3s',
    backgroundColor: theme.palette.colors.backdropColor,
  },
  arrowIconBtn: {},
  leftArrow: {
    left: '-54px',
  },
  rightArrow: {
    right: '-54px',
  },
  icon: {
    color: theme.palette.colors.backdropOnColor,
  },
  textThemeColor: {
    color: theme.palette.colors.backdropOnColor,
  },
}));

export default useStyles;
