import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderSettingsIcon = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g>
      <path
        d="M6.61 20l-.668-2.042H3.015L2.347 20H1l2.768-8h1.464l1.925 5.565.004-.043c.064-.154.153-.375.266-.663l.07-.18L12.79 3h.388l5.237 13.826c.421 1.11.804 1.832 1.148 2.163.344.33.823.517 1.437.558V20h-5.936v-.453c.6-.033 1.004-.143 1.215-.331.21-.188.316-.417.316-.687 0-.36-.148-.927-.443-1.703l-.91-2.39H9.417l-1.02 2.622c-.252.646-.378 1.128-.378 1.447 0 .253.109.476.327.668.218.192.69.316 1.415.374V20H6.61zm-2.12-6.763l-1.146 3.544h2.259l-1.114-3.544zM12.543 6.4l-2.528 6.8h4.993l-2.465-6.8z"
        transform="translate(-812.000000, -38.000000) translate(802.000000, 28.000000) translate(10.000000, 10.000000)"
      />
    </g>
  </SvgIcon>
);

export default ReaderSettingsIcon;
