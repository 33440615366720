import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 17,
    height: 17,
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  paper: {
    width: 50,
    height: 170,
    zIndex: 9,
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    borderRadius: 13,
    marginTop: 19,
    backgroundColor: theme.palette.colors.surfaceColor,
  },
  popover: {
    zIndex: 11,
  },
}));

export default useStyles;
