import { connect } from 'react-redux';
import { updateEBookDataRoutine } from 'redux-state/ducks/player';
import ThemePanel from './theme-panel.component';

const mapStateToProps = ({ player }) => ({
  activeTheme: player.ebookData.activeTheme,
});

const mapDispatchToProps = {
  updateEBookDataAction: updateEBookDataRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemePanel);
