import { connect } from 'react-redux';
import { changePlaybackRateRoutine } from 'redux-state/ducks/player';
import PlaybackRateOption from './playback-rate-option.component';

const mapStateToProps = ({ player }) => ({
  playbackRate: player.playbackRate,
});

const mapDispatchToProps = {
  changePlaybackRateAction: changePlaybackRateRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaybackRateOption);
