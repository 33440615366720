import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DownloadIcon = (props) => (
  <SvgIcon width="22" height="23" viewBox="0 0 22 23" {...props}>
    <path
      d="M2.086 17H2.07C1.479 17 1 17.243 1 17.75s.48.75 1.07.75H19.93c.592 0 1.071-.243 1.071-.75s-.479-.75-1.07-.75H2.085z"
      transform="translate(-930.000000, -233.000000) translate(758.000000, 73.000000) translate(0.000000, 138.000000) translate(172.000000, 22.476923)"
    />
    <path
      d="M11.795 5.676v2.802h-1.59V5.673L8.352 7.557c-.307.303-.806.303-1.12-.005-.31-.306-.308-.803-.004-1.102l3.214-3.224c.153-.15.353-.226.554-.226.208.003.409.078.56.226L14.77 6.45c.308.303.307.794-.005 1.102-.31.305-.816.304-1.12.005l-1.85-1.881zm-1.59 4.367h1.59v5.132c0 .432-.353.782-.795.782-.44 0-.795-.35-.795-.782v-5.132zm0-1.565h1.59v1.565h-1.59V8.478z"
      transform="translate(-930.000000, -233.000000) translate(758.000000, 73.000000) translate(0.000000, 138.000000) translate(172.000000, 22.476923) translate(11.000000, 9.478261) scale(1, -1) translate(-11.000000, -9.478261)"
    />
  </SvgIcon>
);

export default DownloadIcon;
