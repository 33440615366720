import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getFullTrackName } from 'utils/formatters';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import useStyles from './track-title.styles';

const TrackTitle = memo((props) => {
  const { item, creatorClassName, nameClassName } = props;
  const { creator, creatorImage } = item ?? {};

  const classes = useStyles();

  const fullTrackName = useMemo(() => getFullTrackName(item), [item]);

  return (
    <Box className={classes.trackInfo}>
      <Box className={classes.trackCreator}>
        <CardMedia image={creatorImage} className={classes.trackCreatorImage} />
        <Typography
          noWrap
          component="span"
          className={clsx(classes.trackCreatorText, creatorClassName)}
          title={creator}
        >
          {creator}
        </Typography>
      </Box>
      <Box className={classes.trackNameContainer}>
        <Typography
          noWrap
          component="span"
          className={clsx(classes.trackNameText, nameClassName)}
          title={fullTrackName}
        >
          {fullTrackName}
        </Typography>
      </Box>
    </Box>
  );
});

TrackTitle.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    creator: PropTypes.string,
    creatorImage: PropTypes.string,
  }).isRequired,
  nameClassName: PropTypes.string,
  creatorClassName: PropTypes.string,
};

TrackTitle.defaultProps = {
  nameClassName: '',
  creatorClassName: '',
};

export default TrackTitle;
