import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderFontDecreaseIcon = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <defs>
      <filter id="kn1jq1gxoa" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.090196 0 0 0 0 0.090196 0 0 0 0 0.090196 0 0 0 1.000000 0"
        />
      </filter>
    </defs>

    <g>
      <g transform="translate(-752.000000, -151.000000) translate(698.000000, 79.000000) translate(13.000000, 60.535714) translate(41.000000, 11.464286)">
        <g>
          <path
            fillRule="nonzero"
            d="M2.31 12l1.145-3.063h5.018L9.618 12H12L7.255 0h-2.51L0 12h2.31zm5.58-4.829H4.019l1.964-5.315L7.89 7.17z"
            transform="translate(3.000000, 7.000000)"
          />
        </g>
      </g>
      <path
        d="M15.333 12.333H14.25c-.138 0-.25.112-.25.25v.834c0 .138.112.25.25.25h3.5c.138 0 .25-.112.25-.25v-.834c0-.138-.112-.25-.25-.25h-2.417z"
        transform="translate(-752.000000, -151.000000) translate(698.000000, 79.000000) translate(13.000000, 60.535714) translate(41.000000, 11.464286)"
      />
    </g>
  </SvgIcon>
);

export default ReaderFontDecreaseIcon;
