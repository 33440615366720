import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HeadphonesIcon = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g>
      <path d="M21 19.5C21 20.3284 20.3284 21 19.5 21H18C17.1716 21 16.5 20.3284 16.5 19.5V15C16.5 14.1716 17.1716 13.5 18 13.5H19.5V12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12V13.5H6C6.7732 13.5 7.40976 14.085 7.4912 14.8366L7.5 15V19.5C7.5 20.3284 6.82843 21 6 21H4.5C3.67157 21 3 20.3284 3 19.5V12C3 8.78461 4.71539 5.81347 7.5 4.20577C10.2846 2.59808 13.7154 2.59808 16.5 4.20577C19.2846 5.81347 21 8.78461 21 12V19.5Z" />
    </g>
  </SvgIcon>
);

export default HeadphonesIcon;
