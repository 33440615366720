export const HISTORY_EVENT_NAME = 'history-storage-update';

export const PLAYER_PERSIST_KEY = 'player:perstist';
export const HISTORY_PERSIST_KEY = 'history:perstist';
export const SESSION_PERSIST_KEY = 'session:perstist';
export const FAVORITES_PERSIST_KEY = 'favorites:perstist';

export const setPersistentData = (key, data) => {
  window.localStorage.setItem(key, JSON.stringify(data));

  if (key === HISTORY_PERSIST_KEY) {
    const historyEvent = new window.Event(HISTORY_EVENT_NAME);

    window.dispatchEvent(historyEvent);
  }
};

export const getPersistentData = (key) => {
  const playerRawData = window.localStorage.getItem(key);

  if (playerRawData) {
    try {
      JSON.parse(playerRawData);
    } catch (err) {
      window.localStorage.removeItem(key);
      return undefined;
    }

    return JSON.parse(playerRawData);
  }

  return undefined;
};

export const removePersistentData = (key) => {
  window.localStorage.removeItem(key);
};

export const removeAllPersistentData = () => {
  removePersistentData(PLAYER_PERSIST_KEY);
  removePersistentData(SESSION_PERSIST_KEY);
};
