import { connect } from 'react-redux';
import {
  playTrackRoutine,
  pauseTrackRoutine,
  playPrevTrackInQueueRoutine,
  playNextTrackInQueueRoutine,
} from 'redux-state/ducks/player';
import Navigation from './navigation.component';

const mapStateToProps = ({ player, app }) => ({
  queue: player.queue,
  activeIndex: player.activeIndex,
  isPlaying: player.isPlaying,
  displayQueueControls: app.config?.playback.displayQueueControls,
  displayTimeControls: app.config?.playback.displayTimeControls,
});

const mapDispatchToProps = {
  playTrackAction: playTrackRoutine.request,
  pauseTrackAction: pauseTrackRoutine.request,
  playPrevTrackInQueueAction: playPrevTrackInQueueRoutine.request,
  playNextTrackInQueueAction: playNextTrackInQueueRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
