import { connect } from 'react-redux';
import EBookReader from './ebook-reader.component';

const mapStateToProps = ({ player }) => ({
  queue: player.queue,
  ebookData: player.ebookData,
  activeIndex: player.activeIndex,
  isEbookReaderOpen: player.isEbookReaderOpen,
});

export default connect(mapStateToProps)(EBookReader);
