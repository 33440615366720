import makeStyles from '@material-ui/core/styles/makeStyles';
import { FOOTER_HEIGHT, MINI_PLAYER_HEIGHT } from 'utils/themes/sizes';

const useStyles = makeStyles((theme) => ({
  footer: {
    height: ({ isMiniPlayerOpen }) => (isMiniPlayerOpen ? FOOTER_HEIGHT + MINI_PLAYER_HEIGHT : FOOTER_HEIGHT),
    fontSize: 13,
    zIndex: 0,
    textAlign: 'center',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Roboto Regular',
    color: theme.palette.colors.surfaceOnColor,
    paddingBottom: ({ isMiniPlayerOpen }) => (isMiniPlayerOpen ? MINI_PLAYER_HEIGHT : 0),
  },
}));

export default useStyles;
