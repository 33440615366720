import makeStyles from '@material-ui/core/styles/makeStyles';

const TAB_HEADER_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      height: '80%',
    },
  },
  container: {
    width: '100%',
    height: '100vh',
    minWidth: '300px',
    backgroundColor: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.backgroundDark,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      height: '100%',
    },
  },
  tabHeader: {
    borderBottom: 1,
    borderColor: 'divider',
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  tabs: {
    height: TAB_HEADER_HEIGHT,
  },
  tabPanel: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    position: 'relative',
    maxHeight: `calc(100% - ${TAB_HEADER_HEIGHT}px)`,
  },
  navItem: {
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  itemColor: {
    width: 18,
    height: 18,
    borderRadius: 4,
    marginRight: 6,
    display: 'inline-block',
    boxShadow: 'inset 0 0 0 1px rgb(0 0 0 / 4%)',
  },
  navItemMetadata: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  navItemText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  navItemButton: {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  navItemTitle: {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  navItemSubTitle: {
    fontSize: 14,
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundLight,
  },
  emptyMessageTitle: {
    fontSize: 16,
    marginBottom: 4,
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  emptyMessageSubtitle: {
    fontSize: 14,
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundDark,
  },
  exportAnnotations: {
    marginTop: 8,
  },
}));

export default useStyles;
