import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Helmet from './helmet';
import Header from './header';
import Footer from './footer';
import useStyles from './main-layout.styles';

const PageLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Helmet />
      <Header />
      <main className={classes.main}>{children}</main>
      <Footer />
    </Box>
  );
};

PageLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default PageLayout;
