import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderMarginLgIcon = (props) => (
  <SvgIcon width="23" height="22" viewBox="0 0 23 22" {...props}>
    <g>
      <path
        d="M20 0c.552 0 1 .448 1 1v20c0 .552-.448 1-1 1H2c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h18zm0 1H2v20h18V1zm-5.599 15c.331 0 .599.298.599.666 0 .369-.268.667-.599.667H7.6c-.331 0-.599-.298-.599-.667 0-.368.268-.666.599-.666zm0-4c.331 0 .599.298.599.666 0 .369-.268.667-.599.667H7.6c-.331 0-.599-.298-.599-.667 0-.368.268-.666.599-.666zm0-4c.331 0 .599.298.599.666 0 .369-.268.667-.599.667H7.6c-.331 0-.599-.298-.599-.667C7 8.298 7.268 8 7.599 8zm0-4c.331 0 .599.298.599.666 0 .369-.268.667-.599.667H7.6c-.331 0-.599-.298-.599-.667C7 4.298 7.268 4 7.599 4z"
        transform="translate(-782.000000, -195.000000) translate(698.000000, 79.000000) translate(13.000000, 105.000000) translate(71.933333, 11.000000)"
      />
    </g>
  </SvgIcon>
);

export default ReaderMarginLgIcon;
