import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderThemeLightIcon = (props) => (
  <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <g fillRule="evenodd">
      <g transform="translate(-731.000000, -290.000000) translate(698.000000, 79.000000) translate(13.000000, 197.000000) translate(20.000000, 14.000000)">
        <rect width="31" height="31" x=".5" y=".5" rx="15.5" />
      </g>
    </g>
  </SvgIcon>
);

export default ReaderThemeLightIcon;
