import React, { useImperativeHandle, useState } from 'react';
import { TransformContext } from './transform-context';

export const TransformWrapper = React.forwardRef((props, ref) => {
  const [innerRef, setRef] = useState(null);

  useImperativeHandle(ref, () => innerRef, [innerRef]);

  return <TransformContext {...props} setRef={setRef} />;
});

export default TransformWrapper;
