import { connect } from 'react-redux';
import { preloadQueueTracksRoutine } from 'redux-state/ducks/player';
import AudioVideoPlayer from './audio-video-player.component';

const mapStateToProps = ({ player }) => ({
  queue: player.queue,
  activeIndex: player.activeIndex,
});

const mapDispatchToProps = {
  preloadQueueTracks: preloadQueueTracksRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioVideoPlayer);
