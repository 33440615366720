import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logger from 'utils/logger';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import HeaderOffset from 'components/common/header-offset';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    logger.log('ErrorBoundary', 'getDerivedStateFromError method', error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    logger.log('ErrorBoundary', 'componentDidCatch method', { error, info });
  }

  render() {
    const { children, error, containerClassName, textClassName } = this.props;
    const { hasError } = this.state;
    const message = (error && error.message) || 'Oops! Something went wrong!';

    if (error || hasError) {
      return (
        <HeaderOffset>
          <Container className={containerClassName}>
            <Typography className={textClassName}>{message}</Typography>
          </Container>
        </HeaderOffset>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.shape({ message: PropTypes.string })]),
  containerClassName: PropTypes.string,
  textClassName: PropTypes.string,
};

ErrorBoundary.defaultProps = {
  children: <></>,
  error: null,
  containerClassName: '',
  textClassName: '',
};

export default ErrorBoundary;
