import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 17,
    height: 17,
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  paper: {
    zIndex: 9,
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    borderRadius: 13,
  },
  menuList: {
    padding: 0,
    borderRadius: 13,
    backgroundColor: theme.palette.colors.surfaceColor,
  },
  menuListItem: {
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '24px',
    fontFamily: 'ARSMaquetteProRegular',
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  selectedMenuItem: {
    color: theme.palette.colors.primaryColor,
  },
  popover: {
    zIndex: 11,
    top: 19,
  },
}));

export default useStyles;
