import {default as formatDateFns} from 'date-fns/format';

export const getMinutes = (ms) => Math.floor(ms / (1000 * 60));

export const getOnlyMinutes = (ms) => Math.floor((ms / (1000 * 60)) % 60);

export const getOnlyHours = (ms) => Math.floor((ms / (1000 * 60 * 60)) % 24);

export const formatTime = (time) => {
  const totalSeconds = parseInt(time, 10);

  if (Number.isNaN(totalSeconds)) {
    return '00:00';
  }

  const hours = Math.floor(totalSeconds / 60 / 60);
  const minutes = Math.floor((totalSeconds - (hours * 60 * 60)) / 60);
  const seconds = totalSeconds - (hours * 60 * 60) - (minutes * 60);
  const formatteHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${formatteHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const formatDate = (date) => {
 return date ? formatDateFns(new Date(date), 'MMM d yyyy') : ''
};