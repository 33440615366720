import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderMarginXlIcon = (props) => (
  <SvgIcon width="23" height="22" viewBox="0 0 23 22" {...props}>
    <g>
      <path
        d="M20 0c.552 0 1 .448 1 1v20c0 .552-.448 1-1 1H2c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h18zm0 1H2v20h18V1zM9 15c.276 0 .5.195.5.435v3.13c0 .24-.224.435-.5.435s-.5-.195-.5-.435l-.001-.984-1.644 1.293c-.094.08-.222.126-.354.126-.133 0-.26-.045-.354-.126-.094-.08-.147-.19-.147-.303 0-.114.053-.223.147-.304l.995-.767H4.435c-.24 0-.435-.224-.435-.5s.195-.5.435-.5h2.709l-.997-.768c-.127-.108-.176-.266-.13-.414.047-.148.181-.264.354-.303.173-.04.357.002.484.11L8.5 16.419v-.983c0-.24.224-.435.5-.435zm6.5 0c.132 0 .26.045.353.126.094.08.147.19.147.303 0 .114-.053.223-.147.303l-.995.768h2.707c.24 0 .435.224.435.5s-.195.5-.435.5h-2.709l.997.767c.127.109.176.267.13.415-.047.148-.181.264-.354.303-.173.04-.357-.002-.484-.11L13.5 17.58v.984c0 .24-.224.435-.5.435s-.5-.195-.5-.435v-3.13c0-.24.224-.435.5-.435s.5.195.5.435v.983l1.645-1.292c.094-.08.222-.126.354-.126zM12.4 12c.331 0 .599.298.599.666 0 .335-.222.612-.51.66l-.089.007H9.6c-.331 0-.599-.298-.599-.667 0-.334.222-.611.51-.659L9.6 12H12.4zm0-4c.331 0 .599.298.599.666 0 .335-.222.612-.51.66l-.089.007H9.6c-.331 0-.599-.298-.599-.667 0-.334.222-.611.51-.659L9.6 8H12.4zm0-4c.331 0 .599.298.599.666 0 .335-.222.612-.51.66l-.089.007H9.6c-.331 0-.599-.298-.599-.667 0-.334.222-.611.51-.659L9.6 4H12.4z"
        transform="translate(-724.000000, -195.000000) translate(698.000000, 79.000000) translate(13.000000, 105.000000) translate(13.800000, 11.000000)"
      />
    </g>
  </SvgIcon>
);

export default ReaderMarginXlIcon;
