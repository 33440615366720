import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './loader.styles';

const Loader = ({ withOverlay }) => {
  const classes = useStyles({ withOverlay });

  return (
    <div className={classes.loaderContainer}>
      <div className={classes.spinner} />
    </div>
  );
};

Loader.propTypes = {
  withOverlay: PropTypes.bool,
};

Loader.defaultProps = {
  withOverlay: false,
};

export default Loader;
