import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  brightnessSlider: {
    width: 131,
    marginLeft: 12,
    marginRight: 15,
  },
  brightnessSliderRail: {
    color: theme.palette.colors.backdropMutedColor,
  },
  brightnessSliderIndicator: {
    width: 18,
    height: 18,
    marginTop: '-9px',
    transition: 'opacity 0.1s',
  },
  brightnessContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 44,
    paddingLeft: 12,
    paddingRight: 11,
    marginBottom: 3.5,
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundLight,
  },
  icon: {
    width: 22,
    height: 22,
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundLight,
  },
}));

export default useStyles;
