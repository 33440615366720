import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderBrightnessHighIcon = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g>
      <path
        d="M1.817 10.178c-.416 0-.76.31-.81.718L1 11c0 .454.365.822.817.822h2.82c.417 0 .76-.31.811-.718L5.455 11c0-.454-.365-.822-.817-.822H1.817zM11 1c-.452 0-.818.362-.818.812v2.801c0 .414.31.755.715.805l.103.007c.452 0 .818-.362.818-.812V1.812c0-.414-.31-.756-.715-.806L11 1zm0 15.575l.103.007c.369.045.658.331.708.694l.007.11v2.802c0 .45-.366.812-.818.812-.42 0-.762-.31-.812-.71l-.006-.102v-2.801c0-.415.312-.755.715-.805l.103-.007zm-4.02-1.7l.078.067.068.078c.229.293.23.7.01.991l-.074.084-1.98 1.98c-.318.319-.834.316-1.153-.004-.297-.297-.32-.757-.072-1.076l.067-.076 1.981-1.981c.294-.294.755-.314 1.076-.064zm9.023-.025l.077.068 1.995 1.994c.295.296.316.76.064 1.082l-.068.077-.078.069c-.296.231-.71.231-1.004.002l-.077-.067-1.994-1.995c-.32-.32-.318-.838.003-1.159.299-.298.762-.32 1.082-.071zm4.18-4.672c.418 0 .76.314.81.719L21 11l-.006.104c-.047.373-.34.665-.709.712l-.102.006h-2.82c-.453 0-.818-.368-.818-.822 0-.422.312-.765.715-.816l.102-.006h2.821zM4.995 3.845l.093.08L7.082 5.92c.294.293.316.753.07 1.075l-.073.084L7 7.148c-.291.227-.695.23-.988.014l-.093-.08-1.995-1.994c-.293-.293-.315-.753-.07-1.075l.074-.084.078-.069c.291-.227.695-.23.988-.015zm13.076.084l.069.077c.226.29.23.692.015.983l-.08.092-1.98 1.981c-.292.292-.749.313-1.069.069l-.084-.073-.068-.078c-.226-.29-.23-.692-.016-.982l.08-.093 1.98-1.98c.318-.319.834-.316 1.153.004zM11 6.069c-2.708 0-4.91 2.21-4.91 4.931 0 2.72 2.202 4.932 4.91 4.932S15.91 13.72 15.91 11c0-2.649-2.088-4.815-4.697-4.927L11 6.068z"
        transform="translate(-902.000000, -103.000000) translate(698.000000, 79.000000) translate(13.000000, 13.000000) translate(191.000000, 11.000000)"
      />
    </g>
  </SvgIcon>
);

export default ReaderBrightnessHighIcon;
