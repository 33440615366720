export const getTransformStyles = (x, y) => `translate3d(${x}px, ${y}px, 0)`;

export const getCenterPosition = (scale, wrapperComponent, contentComponent) => {
  const contentWidth = contentComponent.offsetWidth * scale;
  const contentHeight = contentComponent.offsetHeight * scale;
  const centerPositionX = (wrapperComponent.offsetWidth - contentWidth) / 2;
  const centerPositionY = (wrapperComponent.offsetHeight - contentHeight) / 2;
  return {
    scale,
    positionX: centerPositionX,
    positionY: centerPositionY,
  };
};
