import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from 'hook/use-media-query';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Portal from '@material-ui/core/Portal';
import TrackInfo from 'components/mini-player/components/track-info';
import useStyles from './mini-player.styles';
import EBookTimeLine from '../ebook-timeline';

const MiniPlayer = memo(({ isMiniPlayerOpen }) => {
  const classes = useStyles();
  const isTablet = useMediaQuery('tablet');

  return (
    <Portal container={document.getElementById('root')}>
      <Slide mountOnEnter unmountOnExit in={isMiniPlayerOpen} direction="up">
        <Box className={classes.playerContainer}>
          <EBookTimeLine isMiniPlayer />
          <Box className={classes.playerContent}>
            <TrackInfo isTablet={isTablet} />
          </Box>
        </Box>
      </Slide>
    </Portal>
  );
});

MiniPlayer.propTypes = {
  isMiniPlayerOpen: PropTypes.bool.isRequired,
};

export default MiniPlayer;
