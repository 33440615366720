import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import firebase from 'utils/auth/firebase';
import { NODE_ENV } from 'constants/env';
import { reduxFirestore } from 'redux-firestore';
import createRootReducer from './root-reducer';
import rootSaga from './root-saga';

export const history = createBrowserHistory();

const hasDevTools = NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const composeEnhancers = hasDevTools ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const configureStore = (preloadedState = {}, { firebaseConfig }) => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware, routerMiddleware(history)];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, reduxFirestore(firebase, firebaseConfig)];
  const composedEnhancers = composeEnhancers(...enhancers);

  const store = createStore(createRootReducer(history), preloadedState, composedEnhancers);

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
