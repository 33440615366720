import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderBookmarkIcon = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      id="path"
      d="M 12.008 5.454 L 6.545 5.454 L 6.545 19.21 L 12 17.259 L 17.455 19.209 L 17.455 11.204 C 18.23 11.204 18.968 11.042 19.636 10.75 L 19.636 21.55 C 19.636 21.605 19.627 21.66 19.609 21.711 C 19.52 21.973 19.236 22.112 18.975 22.023 L 12 19.636 L 5.026 22.023 C 4.973 22.041 4.919 22.05 4.864 22.05 C 4.587 22.05 4.364 21.827 4.364 21.55 L 4.364 3.773 C 4.364 3.497 4.587 3.273 4.864 3.273 L 12.594 3.273 C 12.256 3.933 12.049 4.672 12.008 5.454 Z M 17.455 1.385 C 19.865 1.385 21.818 3.339 21.818 5.749 C 21.818 8.159 19.865 10.113 17.455 10.113 C 15.045 10.113 13.091 8.159 13.091 5.749 C 13.091 3.339 15.045 1.385 17.455 1.385 Z M 18.058 3.567 L 16.852 3.567 C 16.783 3.567 16.727 3.623 16.727 3.692 L 16.727 5.022 L 15.397 5.022 C 15.329 5.022 15.273 5.078 15.273 5.147 L 15.273 6.35 C 15.273 6.42 15.329 6.475 15.398 6.475 L 16.744 6.475 L 16.729 7.803 C 16.728 7.873 16.783 7.929 16.852 7.93 L 18.058 7.93 C 18.128 7.93 18.183 7.874 18.183 7.805 L 18.183 6.475 L 19.511 6.475 C 19.581 6.475 19.636 6.419 19.636 6.35 L 19.636 5.147 C 19.636 5.077 19.58 5.022 19.511 5.022 L 18.183 5.022 L 18.183 3.692 C 18.183 3.623 18.127 3.567 18.058 3.567 Z"
    />
  </SvgIcon>
);

export default ReaderBookmarkIcon;
