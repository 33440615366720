import makeStyles from '@material-ui/core/styles/makeStyles';
import { HEADER_HEIGHT } from 'utils/themes/sizes';

const useStyles = makeStyles((theme) => ({
  offset: {
    marginTop: HEADER_HEIGHT,
    [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
      marginTop: 70,
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginTop: 60,
    },
  },
}));

export default useStyles;
