import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import useStyles from './nav-link.styles';

const NavLink = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const { href, as, children, isLast, ...rest } = props;
  const isActive = () => location?.pathname?.includes?.(href);

  return (
    <Link
      to={href}
      as={as}
      className={clsx(classes.navLink, isActive() && classes.activeNavLink, isLast && classes.lastNavLink)}
      {...rest}
    >
      {children}
    </Link>
  );
};

NavLink.propTypes = {
  href: PropTypes.string.isRequired,
  as: PropTypes.string,
  isLast: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

NavLink.defaultProps = {
  as: null,
  isLast: false,
};

export default NavLink;
