import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import useStyles from './control-button.styles';

const ControlButton = ({ icon: Icon, buttonRef, className, iconClassName, ...restProps }) => {
  const classes = useStyles();

  return (
    <IconButton ref={buttonRef} className={clsx(classes.button, className)} {...restProps}>
      <Icon className={clsx(classes.icon, iconClassName)} />
    </IconButton>
  );
};

ControlButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  buttonRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

ControlButton.defaultProps = {
  className: '',
  iconClassName: '',
};

export default ControlButton;
