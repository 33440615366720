import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 224,
    height: 'auto',
    zIndex: 9,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 19,
    borderRadius: 13,
    padding: 0,
    backgroundColor: theme.palette.colors.surfaceColor,
  },
  brightnessOverlay: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 20,
    opacity: 0,
    position: 'fixed',
    background: '#000',
    pointerEvents: 'none',
    transition: 'opacity 0.3s',
  },
}));

export default useStyles;
