import { getMinutes, getOnlyHours, getOnlyMinutes } from 'utils/time';
import ASSET from 'constants/asset-types';

export const getTrackDuration = ({ t, pages, duration, contentType, fullTimeFormat = false }) => {
  switch (contentType) {
    case ASSET.contentType.audio:
    case ASSET.contentType.video: {
      if (fullTimeFormat === 'unused') {
        return fullTimeFormat
          ? `${t('generic_format_hours', { arg: getOnlyHours(duration) })} ${t('generic_format_minutes', {
              arg: getOnlyMinutes(duration),
            })}`
          : t('generic_format_minutes', { arg: getMinutes(duration) });
      }
      return new Date(duration).toISOString().substr(11, 8);
    }
    case ASSET.contentType.ebook:
      return t('generic_format_pages', { arg: pages });
    default:
      return '-';
  }
};
