import makeStyles from '@material-ui/core/styles/makeStyles';
import { MINI_PLAYER_HEIGHT } from 'utils/themes/sizes';

const useStyles = makeStyles((theme) => ({
  playerContainer: {
    bottom: 0,
    width: '100%',
    zIndex: 10,
    position: 'fixed',
    paddingBottom: 'env(safe-area-inset-bottom)',
    backgroundColor: theme.palette.colors.surfaceColor,
  },
  playerContent: {
    height: MINI_PLAYER_HEIGHT,
    zIndex: 9,
    width: '100%',
    maxWidth: 1024,
    margin: '0 auto',
    padding: '0 42px',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: 0,
      paddingRight: 10,
    },
  },
}));

export default useStyles;
