import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    width: 22,
    height: 22,
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
}));

export default useStyles;
