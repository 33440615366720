import { BASE_API } from 'constants/env';

export const BASE_API_URL = `${BASE_API}/api`;

export const DISPLAY_MODE = Object.freeze({
  playlist: 2,
});

export const TRACK_ASSET_TYPE = Object.freeze({
  primary: 1,
  secondary: 2,
});
