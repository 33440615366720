import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from 'base/icons/close.png';

const useStyles = makeStyles((theme) => ({
  playerHeader: {
    width: '100%',
    height: ({ height }) => height,
    minHeight: ({ height }) => height,
    maxHeight: ({ height }) => height,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '28px',
  },
  closeIconBtn: {
    width: 42,
    height: 42,
    minWidth: 42,
    maxHeight: 42,
    zIndex: 1,
    borderRadius: 21,
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${CloseIcon})`,
    '@media (hover: hover)': {
      '&:hover': {
        filter: 'brightness(0.9)',
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobileFullPlayer)]: {
      width: 32,
      height: 32,
      minWidth: 32,
      maxHeight: 32,
      marginLeft: 20,
    },
  },
}));

export default useStyles;
