import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/loader';

const AccessTokenGuard = ({ children, isLoaded }) => (isLoaded ? children : <Loader withOverlay />);

AccessTokenGuard.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default AccessTokenGuard;
