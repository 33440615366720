import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderMarginSmIcon = (props) => (
  <SvgIcon width="23" height="22" viewBox="0 0 23 22" {...props}>
    <g>
      <path
        d="M20 0c.552 0 1 .448 1 1v20c0 .552-.448 1-1 1H2c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h18zm0 1H2v20h18V1zM5.5 15c.132 0 .26.045.353.126.094.08.147.19.147.303 0 .114-.053.223-.147.303l-.995.768h12.285l-.996-.768c-.127-.108-.176-.266-.13-.414.047-.148.181-.264.354-.303.173-.04.357.002.484.11l1.998 1.571c.094.08.147.19.147.304 0 .114-.053.223-.147.303l-1.998 1.571c-.094.08-.222.126-.354.126-.133 0-.26-.045-.354-.126-.094-.08-.147-.19-.147-.303 0-.114.053-.223.147-.304l.997-.767H4.856l.997.767c.127.109.176.267.13.415-.047.148-.181.264-.354.303-.173.04-.357-.002-.484-.11l-1.998-1.572C3.053 17.223 3 17.113 3 17c0-.114.053-.223.147-.303l1.998-1.571c.094-.08.222-.126.354-.126zM18.4 12c.331 0 .599.298.599.666 0 .369-.268.667-.599.667H3.6c-.331 0-.599-.298-.599-.667 0-.368.268-.666.599-.666zm0-4c.331 0 .599.298.599.666 0 .369-.268.667-.599.667H3.6c-.331 0-.599-.298-.599-.667C3 8.298 3.268 8 3.599 8zm0-4c.331 0 .599.298.599.666 0 .369-.268.667-.599.667H3.6c-.331 0-.599-.298-.599-.667C3 4.298 3.268 4 3.599 4z"
        transform="translate(-899.000000, -195.000000) translate(698.000000, 79.000000) translate(13.000000, 105.000000) translate(188.200000, 11.000000)"
      />
    </g>
  </SvgIcon>
);

export default ReaderMarginSmIcon;
