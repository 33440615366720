import { connect } from 'react-redux';
import {
  changeFullVideoPlayerViewRoutine,
  changeIsPIPModeRoutine,
  pauseTrackRoutine,
  playNextTrackInQueueRoutine,
  resetPlayerRoutine,
} from 'redux-state/ducks/player';
import VideoPlayer from './video-player.component';

const mapStateToProps = ({ player }) => ({
  queue: player.queue,
  volume: player.volume,
  position: player.position,
  isPIPMode: player.isPIPMode,
  isPlaying: player.isPlaying,
  activeIndex: player.activeIndex,
  playbackRate: player.playbackRate,
  isEbookReaderOpen: player.isEbookReaderOpen,
  isFullVideoPlayerOpened: player.isFullVideoPlayerOpened,
});

const mapDispatchToProps = {
  pauseTrackAction: pauseTrackRoutine.request,
  resetPlayerAction: resetPlayerRoutine.request,
  changeIsPIPModeAction: changeIsPIPModeRoutine.request,
  playNextTrackInQueueAction: playNextTrackInQueueRoutine.request,
  changeFullVideoPlayerViewAction: changeFullVideoPlayerViewRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
