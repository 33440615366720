/* eslint-disable no-console */
import { CONSOLE_LOGS_ENABLED } from 'constants/env';

class Logger {
  empty = () => {
    // Skipping Errors
  };

  log = (moduleName, message, data) => {
    if (CONSOLE_LOGS_ENABLED) {
      console.groupCollapsed(`Info: [${moduleName}]: ${message}`);
      console.log(`Module name: ${moduleName}`);
      console.log(`Message: ${message}`);
      console.log('Data:', JSON.stringify(data));
      console.trace();
      console.groupEnd();
    }
  };

  error = (moduleName, message, data) => {
    if (CONSOLE_LOGS_ENABLED) {
      console.groupCollapsed(`Error: [${moduleName}]: ${message}`);
      console.log(`Module name: ${moduleName}`);
      console.log(`Message: ${message}`);
      console.log('Error:', JSON.stringify(data));
      console.trace();
      console.groupEnd();
    }
  };
}

const logger = new Logger();

export default logger;
