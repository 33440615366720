import { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { PLAYER_PERSIST_KEY, setPersistentData } from 'utils/localstorage';
import { ebookDataPropType, playerSharedPropTypes, queuePropType } from 'utils/prop-types';

const QueuePersistence = memo((props) => {
  const { queue, volume, ebookData, activeIndex, playbackRate, playerRef } = props;
  const persistentData = useRef({});

  const persistState = useCallback(() => {
    const { queue: currentQueue, ...restPersistenceData } = persistentData.current;

    const queueWithoutProgressFromHistory = currentQueue.map(({ progress, ...restItem }) => restItem);

    setPersistentData(PLAYER_PERSIST_KEY, {
      ...restPersistenceData,
      queue: queueWithoutProgressFromHistory,
      ...(playerRef?.current ? { position: playerRef?.current?.currentTime || 0 } : {}),
    });
  }, []);

  useEffect(() => {
    persistentData.current = {
      queue,
      volume,
      playbackRate,
      activeIndex,
      ebookData,
    };
  }, [queue, volume, playbackRate, activeIndex, ebookData]);

  useEffect(() => {
    // Listeners to persist data
    window.addEventListener('pagehide', persistState);
    window.addEventListener('visibilitychange', persistState);

    return () => {
      window.removeEventListener('pagehide', persistState);
      window.removeEventListener('visibilitychange', persistState);
    };
  }, []);

  return null;
});

QueuePersistence.propTypes = {
  queue: queuePropType,
  volume: PropTypes.number.isRequired,
  ebookData: ebookDataPropType,
  playbackRate: PropTypes.number.isRequired,
  activeIndex: PropTypes.number,
  playerRef: playerSharedPropTypes.playerRef,
};

export default QueuePersistence;
