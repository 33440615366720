import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginOAuthRoutine } from 'redux-state/ducks/auth';

const createPopup = ({ authWebUrl, appName, width, height }) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const externalPopup = window.open(authWebUrl, appName, `width=${width},height=${height},left=${left},top=${top}`);

  return externalPopup;
};

const useOauthPopup = ({ width = 600, height = 650, onClose } = {}) => {
  const dispatch = useDispatch();
  const appConfig = useSelector((state) => state?.app?.config);
  const { appName, authWebUrl } = appConfig;

  const [externalWindow, setExternalWindow] = useState();

  const handleClick = useCallback(() => {
    setExternalWindow(
      createPopup({
        width,
        height,
        appName,
        authWebUrl,
      }),
    );
  }, [authWebUrl, appName, width, height]);

  const handleMessageEvent = useCallback(
    (event) => {
      if (event.origin === new URL(authWebUrl).origin) {
        const accessToken = event?.data?.token;

        if (accessToken) {
          externalWindow?.close?.();
          dispatch(loginOAuthRoutine.request(accessToken));
          onClose?.();
        }
      }
    },
    [externalWindow],
  );

  useEffect(() => {
    if (externalWindow) {
      window.externalWindow = externalWindow;

      window.addEventListener('message', handleMessageEvent);
    }

    return () => {
      if (externalWindow) {
        externalWindow.close();
        window.removeEventListener('message', handleMessageEvent);
      }
    };
  }, [externalWindow]);

  return handleClick;
};

export default useOauthPopup;
