import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import useStyles from './header-offset.styles';

const HeaderOffset = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.offset}>{children}</Box>;
};

HeaderOffset.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default HeaderOffset;
