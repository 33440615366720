import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ReaderLineHeightSmIcon = (props) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g>
      <path
        d="M13.351 17c.359 0 .649.323.649.722 0 .399-.29.722-.649.722H1.65c-.359 0-.649-.323-.649-.722 0-.399.29-.722.649-.722zm6.214-4.5c.24 0 .435.224.435.5s-.195.5-.435.5h-.983l1.292 1.645c.08.094.126.221.126.354 0 .133-.045.26-.126.354-.08.094-.19.147-.303.147-.114 0-.223-.053-.304-.147l-.767-.995v2.707c0 .24-.224.435-.5.435s-.5-.195-.5-.435v-2.71l-.768.998c-.108.126-.266.176-.414.13-.148-.047-.264-.182-.303-.354-.04-.173.002-.357.11-.484L17.42 13.5h-.984c-.24 0-.435-.224-.435-.5s.195-.5.435-.5h3.13zm-6.214 1c.359 0 .649.323.649.722 0 .399-.29.722-.649.722H1.65c-.359 0-.649-.323-.649-.722 0-.399.29-.722.649-.722zm0-3.5c.359 0 .649.323.649.722 0 .399-.29.722-.649.722H1.65c-.359 0-.649-.323-.649-.722 0-.399.29-.722.649-.722zM18 4c.276 0 .5.195.5.435v2.709l.768-.997c.108-.127.266-.176.414-.13.148.046.264.181.303.354.04.173-.002.357-.11.483L18.581 8.5h.983c.24 0 .435.224.435.5s-.195.5-.435.5h-3.13c-.24 0-.435-.224-.435-.5s.195-.5.435-.5l.984-.001-1.293-1.645c-.08-.093-.126-.22-.126-.353 0-.133.045-.26.126-.354.08-.094.19-.147.303-.147.114 0 .223.053.304.147l.767.995V4.435c0-.24.224-.435.5-.435zM13.35 6.5c.359 0 .649.323.649.722 0 .399-.29.722-.649.722H1.65C1.29 7.944 1 7.62 1 7.222c0-.399.29-.722.649-.722zm0-3.5c.359 0 .649.323.649.722 0 .399-.29.722-.649.722H1.65C1.29 4.444 1 4.12 1 3.722 1 3.323 1.29 3 1.649 3z"
        transform="translate(-752.000000, -239.000000) translate(698.000000, 79.000000) translate(13.000000, 148.750000) translate(41.000000, 11.250000)"
      />
    </g>
  </SvgIcon>
);

export default ReaderLineHeightSmIcon;
