import { connect } from 'react-redux';
import {
  changeEbookReaderOpenRoutine,
  changeFullAudioPlayerViewRoutine,
  changeFullVideoPlayerViewRoutine,
} from 'redux-state/ducks/player';
import TrackInfo from './track-info.component';

const mapStateToProps = ({ player }) => ({
  queue: player.queue,
  activeIndex: player.activeIndex,
});

const mapDispatchToProps = {
  changeFullAudioPlayerViewAction: changeFullAudioPlayerViewRoutine.request,
  changeFullVideoPlayerViewAction: changeFullVideoPlayerViewRoutine.request,
  changeEbookReaderOpenAction: changeEbookReaderOpenRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackInfo);
