import { useMemo } from 'react';
import useWindowSize from 'hook/use-window-size';
import useMediaQuery from 'hook/use-media-query';

const MAX_TRACK_ITEM_WIDTH = 598;
const MAX_TRACK_ITEM_HEIGHT_WITHOUT_PADDINGS = 120;

const useTrackItemSize = (rates) => {
  const { width } = useWindowSize();
  const isTablet = useMediaQuery('tablet');

  const sizeProps = useMemo(() => {
    const maxItemWidth = Math.min(MAX_TRACK_ITEM_WIDTH, width);
    const itemHeight = maxItemWidth * rates.m;
    const isSmallView = itemHeight < MAX_TRACK_ITEM_HEIGHT_WITHOUT_PADDINGS;
    const tabletHeightRate = isSmallView ? rates.l : rates.s;
    const calculatedHeight = maxItemWidth * (isTablet ? tabletHeightRate : rates.xs);
    const itemHeightWithPadding = maxItemWidth * rates.xl;

    return {
      itemHeight,
      isSmallView,
      maxItemWidth,
      calculatedHeight,
      itemHeightWithPadding,
    };
  }, [width, isTablet, rates]);

  return {
    width,
    ...sizeProps,
  };
};

export default useTrackItemSize;
