import makeStyles from '@material-ui/core/styles/makeStyles';
import SpinnerIcon from 'base/icons/icon-spinner.png';

const useStyles = makeStyles((theme) => ({
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  loaderContainer: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.9,
    zIndex: ({ withOverlay }) => (withOverlay ? 10 : undefined),
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'center',
    backdropFilter: ({ withOverlay }) => (withOverlay ? 'blur(50px)' : undefined),
    backgroundColor: ({ withOverlay }) =>
      withOverlay ? theme?.palette?.colors?.backdropColor || 'rgba(255, 255, 255, 0.6)' : undefined,
  },
  spinner: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    animation: `$rotate 2s linear infinite`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${SpinnerIcon})`,
  },
}));

export default useStyles;
