import { connect } from 'react-redux';
import SearchInput from './search-input.component';
import { getUserProps } from 'utils/auth/firebase';

const mapStateToProps = ({ app, player, firebase }) => {
  return {
    withMiniPlayer: Number.isInteger(player.activeIndex),
    // permissions: [firebase?.auth()?.currentUser?.uid.split(':')[0]] || [],
    permissions: getUserProps(app).permissions,
  };
};

export default connect(mapStateToProps)(SearchInput);
