import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 237,
    top: 18,
    left: 0,
    position: 'absolute',
    borderRadius: 13,
  },
  burgerMenuItem: {
    height: 54,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.colors.backdropMutedColor}`,
    backgroundColor: theme.palette.colors.backdropColor,
    fontFamily: 'ARSMaquetteProMedium',
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  openBurgerMenuItem: {
    borderBottom: `1px solid ${theme.palette.colors.backdropMutedColor}`,
  },
  collapsedMenuItem: {
    height: 38,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    justifyContent: 'center',
    backgroundColor: theme.palette.colors.backdropColor,
    fontFamily: 'ARSMaquetteProMedium',
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  burgerMenuItemText: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    fontFamily: 'ARSMaquetteProMedium',
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.colors.surfaceOnColor,
  },
  activeBurgerItemText: {
    color: theme.palette.colors.primaryColor,
  },
  icon: {
    width: 22,
    height: 22,
    right: '-25px',
    position: 'absolute',
    color: theme.palette.colors.surfaceOnColor,
  },
}));

export default useStyles;
