import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { queuePropType } from 'utils/prop-types';
import AudioPlayer from 'components/audio-player';
import VideoPlayer from 'components/video-player';
import ASSET from 'constants/asset-types';

const AudioVideoPlayer = memo((props) => {
  const { queue, activeIndex, preloadQueueTracks } = props;

  const contentType = queue?.[activeIndex]?.contentType;

  // Preload tracks if empty
  useEffect(() => {
    preloadQueueTracks();
  }, [activeIndex]);

  switch (contentType) {
    case ASSET.contentType.audio:
      return <AudioPlayer />;
    case ASSET.contentType.video:
      return <VideoPlayer />;
    default:
      return null;
  }
});

AudioVideoPlayer.propTypes = {
  queue: queuePropType,
  activeIndex: PropTypes.number,
  preloadQueueTracks: PropTypes.func.isRequired,
};

export default AudioVideoPlayer;
