// import logger from 'utils/logger';
import {
  loadAudioLocations, saveAudioBookmarkLocation, deleteAudioBookmarkLocation
} from 'utils/firestore';

export const NAVIGATION_TABS = Object.freeze({
  bookmarks: 0,
});

export const getTabA11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

export const getEmptyMessage = (value) =>
({
  [NAVIGATION_TABS.bookmarks]: {
    title: 'ebook_no_bookmarks_title',
    subtitle: 'ebook_no_bookmarks_subtitle',
  },
}[value]);

export const loadBookmarkItems = async (assetId) => {
  return loadAudioLocations(assetId);
};

export const saveBookmark = async (assetId, progress, duration) => {
  saveAudioBookmarkLocation(assetId, progress, duration);
};

export const deleteBookmark = async (assetId, progress) => {
  deleteAudioBookmarkLocation(assetId, progress);
};

export const TAB_OPTIONS = Object.freeze([
  {
    key: 0,
    value: NAVIGATION_TABS.bookmarks,
    label: 'ebook_navigation_section_bookmarks',
    ...getTabA11yProps(NAVIGATION_TABS.bookmarks),
  },
]);
