import { isExcludedNode, roundNumber } from '../../utils';
import { checkZoomBounds } from '../zoom/zoom.utils';

export const isPinchStartAllowed = (contextInstance, event) => {
  const { disabled, excluded } = contextInstance.setup.pinch;
  const { isInitialized } = contextInstance;
  const { target } = event;
  const isAllowed = isInitialized && !disabled && target;
  if (!isAllowed) return false;
  const isExcluded = isExcludedNode(target, excluded);
  if (isExcluded) return false;
  return true;
};

export const isPinchAllowed = (contextInstance) => {
  const { disabled } = contextInstance.setup.pinch;
  const { isInitialized, pinchStartDistance } = contextInstance;
  const isAllowed = isInitialized && !disabled && pinchStartDistance;
  if (!isAllowed) return false;
  return true;
};

export const calculateTouchMidPoint = (event, scale, contentComponent) => {
  const contentRect = contentComponent.getBoundingClientRect();
  const { touches } = event;
  const firstPointX = roundNumber(touches[0].clientX - contentRect.left, 5);
  const firstPointY = roundNumber(touches[0].clientY - contentRect.top, 5);
  const secondPointX = roundNumber(touches[1].clientX - contentRect.left, 5);
  const secondPointY = roundNumber(touches[1].clientY - contentRect.top, 5);
  return {
    x: (firstPointX + secondPointX) / 2 / scale,
    y: (firstPointY + secondPointY) / 2 / scale,
  };
};

export const getTouchDistance = (event) =>
  Math.sqrt(
    Math.pow(event.touches[0].pageX - event.touches[1].pageX, 2) +
      Math.pow(event.touches[0].pageY - event.touches[1].pageY, 2),
  );

export const calculatePinchZoom = (contextInstance, currentDistance) => {
  const { pinchStartScale, pinchStartDistance, setup } = contextInstance;
  const { maxScale, minScale, zoomAnimation } = setup;
  const { size, disabled } = zoomAnimation;
  if (!pinchStartScale || pinchStartDistance === null || !currentDistance) {
    throw new Error('Pinch touches distance was not provided');
  }
  if (currentDistance < 0) {
    return contextInstance.transformState.scale;
  }
  const touchProportion = currentDistance / pinchStartDistance;
  const scaleDifference = touchProportion * pinchStartScale;
  return checkZoomBounds(roundNumber(scaleDifference, 2), minScale, maxScale, size, !disabled);
};
