import ARSMaquetteProMediumMediumTTF from 'base/fonts/ARSMaquettePro-Medium.ttf';
import ARSMaquetteProMediumWoff2 from 'base/fonts/ARSMaquettePro-Medium.woff2';
import ARSMaquetteProMediumWoff from 'base/fonts/ARSMaquettePro-Medium.woff';
import ARSMaquetteProRegularTTF from 'base/fonts/ARSMaquettePro-Regular.ttf';
import ARSMaquetteProRegularWoff2 from 'base/fonts/ARSMaquettePro-Regular.woff2';
import ARSMaquetteProRegularWoff from 'base/fonts/ARSMaquettePro-Regular.woff';
import RobotoRegularTTF from 'base/fonts/Roboto-Regular.ttf';
import RobotoRegularWoff2 from 'base/fonts/Roboto-Regular.woff2';
import RobotoRegularWoff from 'base/fonts/Roboto-Regular.woff';
import RobotoMediumTTF from 'base/fonts/Roboto-Medium.ttf';
import RobotoMediumWoff2 from 'base/fonts/Roboto-Medium.woff2';
import RobotoMediumWoff from 'base/fonts/Roboto-Medium.woff';
import RobotoBoldTTF from 'base/fonts/Roboto-Bold.ttf';
import RobotoBoldWoff2 from 'base/fonts/Roboto-Bold.woff2';
import RobotoBoldWoff from 'base/fonts/Roboto-Bold.woff';

export const ARSMaquetteProRegular = {
  fontFamily: 'ARSMaquetteProRegular',
  src: `
    url(${ARSMaquetteProRegularTTF}) format('truetype'),
    url(${ARSMaquetteProRegularWoff2}) format('woff2'),
    url(${ARSMaquetteProRegularWoff}) format('woff')
  `,
};

export const ARSMaquetteProMedium = {
  fontFamily: 'ARSMaquetteProMedium',
  src: `
    url(${ARSMaquetteProMediumMediumTTF}) format('truetype'),
    url(${ARSMaquetteProMediumWoff2}) format('woff2'),
    url(${ARSMaquetteProMediumWoff}) format('woff')
  `,
};

export const RobotoRegular = {
  fontFamily: 'Roboto Regular',
  src: `
    url(${RobotoRegularTTF}) format('truetype'),
    url(${RobotoRegularWoff2}) format('woff2'),
    url(${RobotoRegularWoff}) format('woff')
  `,
};

export const RobotoMedium = {
  fontFamily: 'Roboto Medium',
  src: `
    url(${RobotoMediumTTF}) format('truetype'),
    url(${RobotoMediumWoff2}) format('woff2'),
    url(${RobotoMediumWoff}) format('woff')
  `,
};

export const RobotoBold = {
  fontFamily: 'Roboto Bold',
  src: `
    url(${RobotoBoldTTF}) format('truetype'),
    url(${RobotoBoldWoff2}) format('woff2'),
    url(${RobotoBoldWoff}) format('woff')
  `,
};

const allFonts = [RobotoBold, RobotoMedium, RobotoRegular, ARSMaquetteProMedium, ARSMaquetteProRegular];

export default allFonts;
