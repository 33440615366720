import makeStyles from '@material-ui/core/styles/makeStyles';
import { darken } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  controlPanelContainer: {
    display: 'flex',
    flexGrow: 1,

    marginRight: 60,
    justifyContent: 'flex-end',
    '& >:not(:last-child)': {
      marginRight: 18,
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginRight: 20,
    },
  },
  controlButton: {
    width: 42,
    height: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.backgroundLight,
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundLight,
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: ({ activeTheme }) => darken(theme.reader[activeTheme]?.mainColors?.backgroundLight, 0.1),
      },
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.backgroundLight,
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: 36,
      height: 36,
    },
  },
  bookmarkIcon: {
    color: theme.palette.colors.primaryColor,
  },
  controlIcon: {
    width: 22,
    height: 22,
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundLight,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  popperPaper: {
    marginTop: 19,
  },
  moreMenu: {
    backgroundColor: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.backgroundLight,
  },
  moreMenuItem: {
    backgroundColor: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.backgroundLight,
  },
  moreMenuText: {
    color: ({ activeTheme }) => theme.reader[activeTheme]?.mainColors?.foregroundLight,
  },
}));

export default useStyles;
