import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const GlassesIcon = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M20.9793 13.8047L19.1793 5.7047C19.0871 5.29305 18.7218 5.00033 18.3 5H15.6V6.8H17.5782L18.4431 10.6934C17.9686 10.5014 17.4618 10.4019 16.95 10.4C15.1923 10.4 13.7073 11.5322 13.1484 13.1H10.8507C10.2918 11.5322 8.8068 10.4 7.0491 10.4C6.5208 10.4 6.0186 10.5089 5.556 10.6934L6.4218 6.8H8.4V5H5.7C5.27825 5 4.91312 5.293 4.8216 5.7047L3.0216 13.8047L3.0639 13.8146C3.0261 14.0244 3.00473 14.2369 3 14.45C3 16.6829 4.8171 18.5 7.05 18.5C9.129 18.5 10.8273 16.9196 11.0541 14.9H12.9459C13.1727 16.9196 14.871 18.5 16.95 18.5C19.1829 18.5 21 16.6829 21 14.45C21 14.2322 20.9694 14.0225 20.9361 13.8146L20.9793 13.8047ZM7.05 16.7C5.8098 16.7 4.8 15.6902 4.8 14.45C4.8 13.2098 5.8098 12.2 7.05 12.2C8.2902 12.2 9.3 13.2098 9.3 14.45C9.3 15.6902 8.2902 16.7 7.05 16.7ZM16.95 16.7C15.7089 16.7 14.7 15.6902 14.7 14.45C14.7 13.2098 15.7089 12.2 16.95 12.2C18.1911 12.2 19.2 13.2098 19.2 14.45C19.2 15.6902 18.1911 16.7 16.95 16.7Z" />
  </SvgIcon>
);

export default GlassesIcon;
