import { connect } from 'react-redux';
import { logoutRoutine } from 'redux-state/ducks/auth';
import HeaderDesktop from './header.desktop.component';

const mapStateToProps = ({ app }) => ({
  tabs: app?.config?.tabs ?? [],
  appName: app?.config?.appName,
  authType: app?.config?.authType,
  brandImage: app?.config?.brandImage,
});

const mapDispatchToProps = {
  logoutAction: logoutRoutine.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDesktop);
