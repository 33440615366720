// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase/compat/app';
import { createFirestoreInstance } from 'redux-firestore';

import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

const profileSpecificProps = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableRedirectHandling: false,
  resetBeforeLogin: false,
};

export const initFirebase = (config, locales) => {
  firebase.initializeApp(config);
  firebase.analytics().setUserId(firebase.auth().currentUser?.uid);
  if (locales && locales.length > 0){
    firebase.auth().languageCode = locales[0];
  } else {
    firebase.auth().useDeviceLanguage();
  }
};

export const getReactReduxFirebaseProps = (store) => ({
  firebase,
  config: profileSpecificProps,
  dispatch: store.dispatch,
  createFirestoreInstance,
});

export const getUserProps = (app) => {
  var currentUser = firebase.auth()?.currentUser;
  var isTokenized = currentUser?.uid.includes(':');

  var props = {
    displayName: isTokenized ? currentUser?.uid.split(':')[1] : currentUser?.displayName || currentUser?.email || '-',
    permissions: isTokenized ? [currentUser?.uid.split(':')[0]] : app.userData.audiorista_permissions || [],
    uid: currentUser?.uid,
    email: currentUser?.email,
  };

  return props;
};

export default firebase;
