import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  main: {
    flex: '1 auto',
    width: '100%',
    maxWidth: 1024,
    margin: '0 auto',
    backgroundColor: theme.palette.colors.backdropColor,
  },
}));

export default useStyles;
