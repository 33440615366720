import { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { PING_INTERVAL } from 'constants/env';
import { playerSharedPropTypes } from 'utils/prop-types';

const Ping = memo((props) => {
  const { playerRef, duration, callPingAction } = props;

  const intervalIdRef = useRef(null);
  const [timer, setTimer] = useState(0);

  const handlePlaying = useCallback(() => {
    clearInterval(intervalIdRef.current);

    const id = setInterval(() => {
      setTimer((current) => current + 1);
    }, 1000);

    intervalIdRef.current = id;
  }, []);

  const handlePingCall = useCallback(() => {
    callPingAction({
      length: playerRef.current.duration || duration || 0,
      position: playerRef.current.currentTime || 0,
    });
  }, [duration]);

  const handlePause = useCallback(() => {
    clearInterval(intervalIdRef.current);
  }, []);

  const handleLoadStart = useCallback(() => {
    setTimer(0);
    handlePingCall();
    clearInterval(intervalIdRef.current);
  }, [handlePingCall]);

  useEffect(() => {
    const updateIntervalInSec = PING_INTERVAL / 1000;

    if (timer >= updateIntervalInSec) {
      handlePingCall();
      setTimer(0);
    }
  }, [timer]);

  useEffect(() => {
    const playerInstance = playerRef.current;

    if (duration) {
      playerInstance.addEventListener('pause', handlePause);
      playerInstance.addEventListener('playing', handlePlaying);
      playerInstance.addEventListener('loadstart', handleLoadStart);
    }

    return () => {
      if (duration) {
        clearInterval(intervalIdRef.current);
        playerInstance.removeEventListener('pause', handlePause);
        playerInstance.removeEventListener('playing', handlePlaying);
        playerInstance.removeEventListener('loadstart', handleLoadStart);
      }
    };
  }, [duration]);

  return null;
});

Ping.propTypes = {
  duration: PropTypes.number.isRequired,
  playerRef: playerSharedPropTypes.playerRef,
  callPingAction: PropTypes.func.isRequired,
};

export default Ping;
