import React, { memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SpeedIcon } from 'components/icons';
import ControlButton from 'components/common/control-button';
import PopperOverlay from 'components/common/popper-overlay';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { PLAYBACK_RATES } from 'redux-state/ducks/player';
import clsx from 'clsx';
import useStyles from './playback-rate-option.styles';

const PlaybackRateOption = memo((props) => {
  const classes = useStyles();
  const {
    placement,
    className,
    playbackRate,
    paperClassName,
    iconClassName,
    menuListClassName,
    menuListItemClassName,
    changePlaybackRateAction,
  } = props;

  const playbackRateMenuAnchorRef = useRef(null);
  const [isPlaybackRateMenuOpen, setIsPlaybackRateMenuOpen] = useState(false);

  const handlePlaybackRateClick = useCallback(
    (value) => () => {
      changePlaybackRateAction(value);
    },
    [],
  );

  const handleTogglePlaybackRateMenu = useCallback(() => {
    setIsPlaybackRateMenuOpen((currentFlag) => !currentFlag);
  }, []);

  const handleClosePlaybackRateMenu = useCallback((event) => {
    if (playbackRateMenuAnchorRef.current && playbackRateMenuAnchorRef.current.contains(event?.target)) {
      return;
    }

    setIsPlaybackRateMenuOpen(false);
  }, []);

  return (
    <>
      <ControlButton
        icon={SpeedIcon}
        className={className}
        buttonRef={playbackRateMenuAnchorRef}
        onClick={handleTogglePlaybackRateMenu}
        iconClassName={clsx(classes.icon, iconClassName)}
      />
      <PopperOverlay
        isOpen={isPlaybackRateMenuOpen}
        placement={placement}
        onClose={handleClosePlaybackRateMenu}
        anchor={playbackRateMenuAnchorRef.current}
        popperClassName={clsx(classes.paper, paperClassName)}
      >
        <MenuList className={clsx(classes.menuList, menuListClassName)}>
          {Object.entries(PLAYBACK_RATES).map(([name, value], index) => (
            <MenuItem
              key={name}
              onClick={handlePlaybackRateClick(value)}
              divider={index !== Object.keys(PLAYBACK_RATES).length - 1}
              className={clsx(classes.menuListItem, menuListItemClassName)}
            >
              <span className={value === playbackRate && classes.selectedMenuItem}>{name}</span>
            </MenuItem>
          ))}
        </MenuList>
      </PopperOverlay>
    </>
  );
});

PlaybackRateOption.propTypes = {
  placement: PropTypes.string,
  className: PropTypes.string,
  playbackRate: PropTypes.number.isRequired,
  iconClassName: PropTypes.string,
  paperClassName: PropTypes.string,
  menuListClassName: PropTypes.string,
  menuListItemClassName: PropTypes.string,
  changePlaybackRateAction: PropTypes.func.isRequired,
};

PlaybackRateOption.defaultProps = {
  className: '',
  iconClassName: '',
  paperClassName: '',
};

export default PlaybackRateOption;
