import firebase from 'utils/auth/firebase';
import logger from 'utils/logger';
import ROUTES from 'utils/routes';
import { STRIPE_PAYMENT_FUNCTION_NAME } from 'constants/env';
import rgb2hex from 'rgb2hex'

const colorForHighlight = (highlight) => {
  const rgb = highlight.color
  const { hex: hexColor } = rgb2hex(`rgb(${rgb.red},${rgb.green},${rgb.blue})`)
  return hexColor
}

const relativePath = (path) => {
  // The web app needs relative path to href
  const hrefPath = decodeURI(path)
  return hrefPath.startsWith('/') ? hrefPath.slice(1) : hrefPath
}
const absPath = (path) => {
  const hrefPath = decodeURI(path)
  return hrefPath.startsWith('/') ? hrefPath : "/" + hrefPath
}

const dataForAnnotation = (assetId, location) => {
  const underline = location.highlight.marker == 1
  const color = colorForHighlight(location.highlight)
  return {
    id: location.id,
    asset_id: assetId,
    locator: JSON.stringify({ ...location, href: absPath(location.href) }),
    progress: location.locations.position,
    progress_percent: location.locations.totalProgression,
    timestamp: new Date(),
    note: location?.highlight?.note ?? "",
    color: color,
    underline: underline,
    is_web: true,
    // text: {
    //   after: "",
    //   before: "",
    //   highlight: location?.highlight?.selectionInfo?.rawText ?? ""
    // },
  }
}

export const addListenerForUserData = async (updateUserData) => {
  // Could be modified to just store the entire document if we ever need more data than just permissions
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;

  try {
    firestore
      .collection('users')
      .doc(userId)
      .onSnapshot(async (snapshot) => {
        if (snapshot.exists) {
          logger.log('AppHandlers', 'User document was loaded successfully.', snapshot.data());
          updateUserData(snapshot.data() || {});
        } else {
          logger.log('AppHandlers', 'User document does not exist.');
          updateUserData({});
        }
      });
  } catch (error) {
    logger.error('AppHandlers', 'Unable to receive user document.', error);
  }
};

export const addListenerForUserEntitlements = async (updateUserEntitlements) => {
  // Could be modified to just store the entire document if we ever need more data than just permissions
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;
  try {
    firestore
      .collection('users')
      .doc(userId)
      .collection('revenuecat')
      .onSnapshot(async (snapshot) => {
        if (snapshot.empty) {
          // logger.log('AppHandlers', 'User subscriptions was loaded successfully. No trialing or active subscriptions.');
          updateUserEntitlements({});
          return;
        }

        // In this implementation we only expect one Subscription to exist
        const entitlements = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const subscriptionData = doc.data();
            return {
              ...subscriptionData.entitlements,
            };
          }),
        );
        const currentDate = new Date();
        const isActive = Object.values(entitlements[0]).some(item => new Date(item.expires_date) > currentDate);
        if (isActive) {
          updateUserEntitlements(entitlements[0]);
        } else {
          updateUserEntitlements({});
        }        
      });
  } catch (error) {
    logger.error('AppHandlers', 'Unable to receive revenuecat document.', error);
  }
};

export const addListenerForUserSubscriptions = async (updateUserSubscriptions) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;

  try {
    firestore
      .collection('customers')
      .doc(userId)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        if (snapshot.empty) {
          logger.log('AppHandlers', 'User subscriptions was loaded successfully. No trialing or active subscriptions.');
          updateUserSubscriptions([]);
          return;
        }

        // In this implementation we only expect one Subscription to exist
        const subscriptions = await Promise.all(
          snapshot.docs.map(async (doc) => {
            return doc.data();
          }),
        );

        logger.log('AppHandlers', 'User subscriptions was loaded successfully.', subscriptions);
        updateUserSubscriptions(subscriptions);
      });
  } catch (error) {
    logger.error('AppHandlers', 'Unable to receive user subscriptions.', error);
  }
};

export const generateUserSubscriptionPortalLink = async (functionLocation) => {
  const functionRef = firebase.app().functions(functionLocation).httpsCallable(STRIPE_PAYMENT_FUNCTION_NAME);
  const { data } = await functionRef({ returnUrl: `${window.origin}${ROUTES.root}` });

  return data.url;
};

export const saveUserHistory = async (assetId, newHistoryItem) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;
  try {
    firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_history')
      .doc(`${assetId}`)
      .set(newHistoryItem)
  } catch (error) {
    logger.error('Firestore', 'Unable to save user history.', error);
  }
}

export const loadUserHistory = async () => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;
  const history = await firestore
    .collection('users')
    .doc(userId)
    .collection('audiorista_history')
    .orderBy("timestamp", "desc")
    .get()
    .then((collection) => {
      return collection.docs.map((doc) => doc.data()).reduce(
        (acc, item) => ({
          ...acc,
          [`${item.track_id}_${item.asset_id}`]: {
            trackId: item.track_id,
            assetId: item.asset_id,
            timestamp: item.timestamp,
            progress: item.progress,
          },
        }),
        {},
      )
    });

  return history
}


export const saveFavorite = async (trackId) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;

  try {
    firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_favorites')
      .doc(`${trackId}`)
      .set({ track_id: trackId, timestamp: new Date() })
  } catch (error) {
    logger.error('Firestore', 'Unable to save favorite.', error);
  }
}
export const removeFavorite = async (trackId) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;

  try {
    firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_favorites')
      .doc(`${trackId}`)
      .delete()
  } catch (error) {
    logger.error('Firestore', 'Unable to delete favorite.', error);
  }
}
export const loadUserFavorites = async () => {
  // loads favorites for all books
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;
  const favorites = await firestore
    .collection('users')
    .doc(userId)
    .collection('audiorista_favorites')
    .orderBy("timestamp", "desc")
    .get()
    .then((collection) => {
      return collection.docs.map((doc) => doc.data()).map((item) => item?.track_id).filter(Boolean);
    });
  return favorites
}

export const saveAudioBookmarkLocation = async (assetId, progress, duration) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;

  const dataObject = {
    asset_id: assetId,
    locator: "",
    progress: Math.floor(progress),
    progress_percent: Math.floor(progress)/duration,
    timestamp: new Date(),
  }

  try {
    firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_markings')
      .doc(`${assetId}`)
      .collection('bookmarks')
      .doc(`${dataObject.progress}`)
      .set(dataObject)
  } catch (error) {
    logger.error('Firestore', 'Unable to save bookmark.', error);
  }
}

export const deleteAudioBookmarkLocation = async (assetId, progress) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;

  try {
    firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_markings')
      .doc(`${assetId}`)
      .collection('bookmarks')
      .doc(`${Math.floor(progress)}`)
      .delete()
  } catch (error) {
    logger.error('Firestore', 'Unable to delete bookmark.', error);
  }
}

export const loadAudioLocations = async (assetId) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;
  try {
    const bookmarks = await firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_markings')
      .doc(`${assetId}`)
      .collection('bookmarks')
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return doc.data()
        });
        return data
      });
    return bookmarks;
  } catch (error) {
    logger.error('Firestore', 'Unable to load locations.', error);
  }
}

export const saveBookmarkLocation = async (assetId, location) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;

  const dataObject = {
    asset_id: assetId,
    locator: JSON.stringify({ ...location, href: absPath(location.href) }),
    progress: location.locations.position,
    progress_percent: location.locations.totalProgression,
    timestamp: location.created,
  }

  try {
    firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_markings')
      .doc(`${assetId}`)
      .collection('bookmarks')
      .doc(`${dataObject.progress}`)
      .set(dataObject)
  } catch (error) {
    logger.error('Firestore', 'Unable to save bookmark.', error);
  }
}
export const deleteBookmarkLocation = async (assetId, location) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;

  try {
    firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_markings')
      .doc(`${assetId}`)
      .collection('bookmarks')
      .doc(`${location.locations.position}`)
      .delete()
  } catch (error) {
    logger.error('Firestore', 'Unable to delete bookmark.', error);
  }
}
export const saveAnnotationLocation = async (assetId, location) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;

  const dataObject = dataForAnnotation(assetId, location)
  try {
    await firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_markings')
      .doc(`${assetId}`)
      .collection('annotations')
      .doc(location.id)
      .set(dataObject)
    return location
  } catch (error) {
    logger.error('Firestore', 'Unable to save annotation.', error);
  }
}


export const deleteAnnotationLocation = async (assetId, location) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;
  try {
    await firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_markings')
      .doc(`${assetId}`)
      .collection('annotations')
      .doc(location.id)
      .delete()
  } catch (error) {
    logger.error('Firestore', 'Unable to delete annotation.', error);
  }
}

export const updateAnnotationLocation = async (assetId, location) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;

  const dataObject = dataForAnnotation(assetId, location)

  try {
    await firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_markings')
      .doc(`${assetId}`)
      .collection('annotations')
      .doc(location.id)
      .update(dataObject)
  } catch (error) {
    logger.error('Firestore', 'Unable to update annotation note.', error);
  }
}

export const loadLocations = async (assetId) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;
  try {
    const bookmarks = await firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_markings')
      .doc(`${assetId}`)
      .collection('bookmarks')
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          const locatorData = JSON.parse(doc.data().locator)
          return { ...locatorData, href: relativePath(locatorData.href), id: Math.random() }
        });
        return data
      });
    const history = await firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_history')
      .doc(`${assetId}`)
      .get()
      .then((doc) => {
        return doc.data() || {}
      });
    const annotations = await firestore
      .collection('users')
      .doc(userId)
      .collection('audiorista_markings')
      .doc(`${assetId}`)
      .collection('annotations')
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.filter((doc) => {
          return doc.data()?.is_web === true
        }).map((doc) => {
          const locatorData = JSON.parse(doc.data().locator)
          return { ...locatorData, href: relativePath(locatorData.href) }
        });
        return data
      });
    return { bookmarks, history, annotations }
  } catch (error) {
    logger.error('Firestore', 'Unable to load locations.', error);
  }
}
