import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  timelineContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '0 60px',
    height: 28,
    minHeight: 99,
    maxHeight: 99,
    zIndex: 999,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '0 20px',
    },
  },
  timeline: {
    flex: 1,
    width: '100%',
    padding: '47px 0 4px',
  },
  timelineMiniPlayer: {
    position: 'absolute',
    left: 0,
    top: '-3px',
    width: '100%',
    padding: '3px 0',
  },
  timelineIndicator: {
    width: 18,
    height: 18,
    marginTop: '-9px',
  },
  timelineIndicatorViewOnly: {
    opacity: 0,
    cursor: 'default',
    '@media (hover: hover)': {
      '&:hover': {
        boxShadow: 'unset',
      },
    },
  },
  timelineRail: {
    color: theme.palette.colors.backdropMutedColor,
  },
  currentPageNumber: {
    paddingBottom: 12,
  },
  timelineDistractionFreeMode: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
  },
  timelineRailDistractionFreeMode: {
    color: theme.palette.colors.backdropMutedColor,
  },
}));

export default useStyles;
