import PropTypes from 'prop-types';
import { READER_THEMES } from 'utils/themes';

export const ebookSettingsSharedProps = {
  activeTheme: PropTypes.oneOf(Object.keys(READER_THEMES)),
  updateOptions: PropTypes.func.isRequired,
  commonClasses: PropTypes.shape({
    iconSm: PropTypes.string.isRequired,
    iconMd: PropTypes.string.isRequired,
    iconBtn: PropTypes.string.isRequired,
    activeIcon: PropTypes.string.isRequired,
  }).isRequired,
};

export const readerRefPropType = PropTypes.shape({
  current: PropTypes.shape({
    options: PropTypes.shape({
      getOptions: PropTypes.func,
      setOptions: PropTypes.func,
    }),
    navigation: PropTypes.shape({
      goToPage: PropTypes.func,
    }),
    annotations: PropTypes.shape({
      setAnnotation: PropTypes.func,
    }),
    eventListeners: PropTypes.shape({
      addListener: PropTypes.func,
      removeListener: PropTypes.func,
    }),
  }),
});

export const ebookDataPropType = PropTypes.shape({
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  activeTheme: PropTypes.oneOf(Object.keys(READER_THEMES)),
});
