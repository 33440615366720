const ROUTES = {
  root: '/',
  login: '/login',
  passwordReset: '/reset-password',
  signup: '/signup',
  feed: '/feed/:targetId',
  browse: '/browse/:targetId',
  library: '/library',
  playlist: '/playlist/:playlistId',
  track: '/track/:trackId',
  paywall: '/paywall',
};

export const getTabPageRoute = (target, targetId) => `/${target}${targetId ? `/${targetId}` : ''}`;

export const getPlaylistPageRoute = (playlistId) => `/playlist/${playlistId}`;

export const getTrackPageRoute = (trackId) => `/track/${trackId}`;

export const getBrowsePageRoute = (targetId) => `/browse/${targetId}`;

export const getFeedPageRoute = (targetId) => `/feed/${targetId}`;

export default ROUTES;
