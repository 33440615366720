import makeStyles from '@material-ui/core/styles/makeStyles';
import { darken } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 22,
    height: 22,
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  button: {
    width: 42,
    height: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: theme.palette.colors.surfaceColor,
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: darken(theme.palette.colors.surfaceColor, 0.1),
      },
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: theme.palette.colors.surfaceColor,
      },
    },
  },
}));

export default useStyles;
