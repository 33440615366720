import firebase from 'utils/auth/firebase';
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from "firebase/auth";

export const signUp = async ({ email, password }) => firebase.auth().createUserWithEmailAndPassword(email, password);

export const login = async ({ email, password }) => firebase.auth().signInWithEmailAndPassword(email, password);

export const resetPassword = async ({ email }) => firebase.auth().sendPasswordResetEmail(email);

export const loginWithCustomToken = async (token) => firebase.auth().signInWithCustomToken(token);

export const logout = async () => firebase.auth().signOut();

export const loginWithGoogle = async () => {
    const auth = firebase.auth();
    const locale = auth.languageCode;
    auth.languageCode = locale;
    const provider = new GoogleAuthProvider();
    provider.setDefaultLanguage(locale);
    provider.setCustomParameters({ locale });
    return signInWithPopup(auth, provider);
};

export const loginWithFacebook = async () => {
    const auth = firebase.auth();
    const locale = auth.languageCode;
    const provider = new FacebookAuthProvider();
    provider.setDefaultLanguage(locale);
    provider.setCustomParameters({ locale });
    return signInWithPopup(auth, provider);
};

export const loginWithApple = async () => {
    const locale = firebase.auth().languageCode;
    const provider = new OAuthProvider('apple.com');
    provider.setDefaultLanguage(locale);
    provider.setCustomParameters({ locale });
    provider.addScope('email');
    provider.addScope('name');
    return firebase.auth().signInWithPopup(provider);
}
