import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ebookSettingsSharedProps } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Icons from 'components/icons';
import { FONT_SCALE_STEPS, FONT_SCALE_CHANGE_TYPE } from '../settings-menu.constants';
import useStyles from './font-panel.styles';

const FontPanel = memo(({ readerRef, fontSize, updateOptions, commonClasses }) => {
  const classes = useStyles();

  const changeFontScale = useCallback(
    (scaleValue) => () => {
      const currentFontSize = readerRef.current.currentSettings.fontSize;
      const nextValue = FONT_SCALE_STEPS[FONT_SCALE_STEPS.indexOf(currentFontSize ?? 100) + Number(scaleValue)];

      if (nextValue) {
        readerRef.current.applyUserSettings({ fontSize: nextValue });
        updateOptions({ fontSize: nextValue })

      }
    },
    [fontSize],
  );

  return (
    <Box className={classes.fontSizeContainer}>
      <IconButton onClick={changeFontScale(FONT_SCALE_CHANGE_TYPE.decrease)} className={commonClasses.iconBtn}>
        <Icons.ReaderFontDecrease className={commonClasses.iconSm} />
      </IconButton>
      <IconButton onClick={changeFontScale(FONT_SCALE_CHANGE_TYPE.increase)} className={commonClasses.iconBtn}>
        <Icons.ReaderFontIncrease className={commonClasses.iconSm} />
      </IconButton>
    </Box>
  );
});

FontPanel.propTypes = {
  fontSize: PropTypes.number.isRequired,
  ...ebookSettingsSharedProps,
};

export default FontPanel;
