import makeStyles from '@material-ui/core/styles/makeStyles';
import { darken } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 16,
    height: 16,
    color: theme.palette.colors.surfaceOnColor,
  },
  audioPlayIcon: {
    marginLeft: 4,
  },
  videoPlayIcon: {
    marginLeft: 4,
  },
  audioInfoIcon: {
    marginTop: '-2px',
  },
  button: {
    width: 48,
    height: 48,
    display: 'flex',
    position: 'relative',
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid #ffffff`,
    backgroundColor: 'rgba(255,255,255,0.75)',
    '& $icon': {
      width: 32,
      height: 32,
    },
    '@media (hover: hover)': {
      '&:hover': {
        '& $icon': {
          color: theme.palette.colors.primaryColor,
        },
        backgroundColor: darken('rgba(255,255,255,0.75)', 0.1),
      },
    },
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: theme.palette.colors.surfaceColor,
      },
    },
  },
}));

export default useStyles;
