import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getMediaKey } from 'api/media';
import logger from 'utils/logger';
import { isTouchDevice } from 'utils/screen';
import { isEbookContentType } from 'utils/assets';
import { Helmet } from 'react-helmet';
import { READER_THEMES } from 'utils/themes';
import { ebookDataPropType, queuePropType } from 'utils/prop-types';
import useMediaQuery from 'hook/use-media-query';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FullScreenHeader from 'components/full-screen-components/header';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import useStyles from './pubhub-reader.styles';
import ControlPanel from './components/control-panel';
import EbookTimeline from '../components/ebook-timeline';
import { v4 as uuidv4 } from 'uuid';

import useScript from 'hook/use-script';
import {
  deleteBookmarkLocation,
  loadLocations,
  saveBookmarkLocation,
  saveAnnotationLocation,
  deleteAnnotationLocation,
  updateAnnotationLocation,
} from 'utils/firestore';

const EPUB_READER_FOLDER = `${process.env.PUBLIC_URL}/epub-reader`;
const FONT_READER_SCRIPT_PATH = `${EPUB_READER_FOLDER}/font-faces/fonts.js`;

const CSS_READER_PATH = `${EPUB_READER_FOLDER}/css/readium-css/`;
const MAIN_READER_SCRIPT_PATH = `${EPUB_READER_FOLDER}/scripts/reader.js`;

const INJECTABLES_CSS_PATH = `${window.location.origin}/epub-reader/injectables/readium-css/`; // `${EPUB_READER_FOLDER}/injectables/readium-css/`;
const READIUMCSS_BEFORE = `${INJECTABLES_CSS_PATH}/ReadiumCSS-before.css`; // Why is this not used?
const READIUMCSS_DEFAULT = `${INJECTABLES_CSS_PATH}/ReadiumCSS-default.css`;
const READIUMCSS_AFTER = `${INJECTABLES_CSS_PATH}/ReadiumCSS-after.css`;

const ENVIRONMENTS = Object.freeze({
  live: 0,
  qa: 1,
});

// env.PUBLIC_URL points at https://pubfront-reader.storage.googleapis.com/ for some reason...
// hacking this for local development but need a fix
const injectables = [
  {
    type: 'style',
    url: READIUMCSS_BEFORE,
    r2before: true,
  },
  {
    type: 'style',
    url: READIUMCSS_DEFAULT,
    r2default: true,
  },
  {
    type: 'style',
    url: READIUMCSS_AFTER,
    r2after: true,
  },
];

const getCurrentBookmark = (d2reader) => {
  const {
    locations: { position: position },
  } = d2reader.currentLocator;
  const currentBookmark = d2reader.bookmarks.find((bookmark) => bookmark.locations.position === position);

  return currentBookmark || false;
};

const PubhubReader = memo((props) => {
  useScript(FONT_READER_SCRIPT_PATH);
  useScript(MAIN_READER_SCRIPT_PATH);

  const theme = useTheme();
  const isTablet = useMediaQuery('tablet');

  const {
    queue,
    ebookData: { activeTheme },
    activeIndex,
    resetPlayerAction,
    changeEbookReaderOpenAction,
    updateEBookDataAction,
  } = props;
  const isTouchableDevice = isTouchDevice();
  const activeQueueItem = queue[activeIndex];
  const readerRef = useRef();

  const [isDistractionFreeMode, setIsDistractionFreeMode] = useState(true);
  const [isBookmarkActive, setIsBookmarkActive] = useState(false);
  const [isReaderInstanceSaved, setIsReaderInstanceSaved] = useState(false);
  const [isEBookLoaded, setIsEBookLoaded] = useState(false);
  const [goToPage, setGoToPage] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100);

  const classes = useStyles({ activeTheme, activeQueueItem, zoomLevel });

  const toggleZoom = useCallback(() => {
    setZoomLevel((current) => {
      if (current == 100) {
        return 200;
      } else if (current == 200) {
        return 300;
      } else {
        return 100;
      }
    });
  }, []);

  const toggleBookmark = useCallback(() => {
    readerRef.current.saveBookmark().then((result) => {

      if (!result) {
        // use position to figure out if it's the same bookmark
        const currentBookmark = getCurrentBookmark(readerRef.current);
        if (currentBookmark) {
          readerRef.current.deleteBookmark(currentBookmark);
          setIsBookmarkActive(false);
        }
      } else {
        setIsBookmarkActive(true);
      }
    });
  }, []);

  const goToPrevPage = useCallback((e) => {
    e.stopPropagation();
    readerRef.current.navigator.previousPage();
  }, []);

  const goToNextPage = useCallback((e) => {
    e.stopPropagation();
    readerRef.current.navigator.nextPage();
  }, []);

  const handleTimelineVisibleRangeChanged = useCallback((location) => {
    const newTotalPages = location.locations.position + location.locations.totalRemainingPositions; //event.totalNumberOfPages;
    const newCurrentPage = location.locations.position; //event.timelineVisibleStartPageIndex;

    updateEBookDataAction({
      totalPages: newTotalPages,
      currentPage: newCurrentPage,
      duration: location.locations.position + location.locations.totalRemainingPositions,
      progress: location.locations.position,
      skipHistoryUpdate: false,
    });
  }, []);

  const closeEbookReader = useCallback(() => {
    changeEbookReaderOpenAction(false);
  }, []);

  // External link popup
  const handleExternalLinkClick = useCallback((event) => {
    const newWindow = window.open(event.href, '_blank');

    if (newWindow !== null) {
      newWindow.focus();
    }
  }, []);

  const handleError = useCallback((event) => {
    logger.log('PubhubReader', event.errorMessage, event.error);
    resetPlayerAction();
    setIsEBookLoaded(false);
  }, []);

  const handleClick = useCallback((event) => {

    if (!readerRef.current.highlighter.isSelectionMenuOpen) {
      setIsDistractionFreeMode((current) => !current);
    }
  }, []);

  const waitForReadium = useCallback(() => {
    if (window?.D2Reader && window?.getFontFaces) {
      updateEBookDataAction({ totalPages: 0, currentPage: 1, skipHistoryUpdate: true });
      loadEbookAsset(window.D2Reader);
    } else {
      setTimeout(waitForReadium, 250);
    }
  }, []);

  const updateOrientation = event => {
    let colCount = readerRef.current.settings.columnCount;
    let newColCount = getReadiumColumnCount();
    if (newColCount != colCount) {
      readerRef.current.applyUserSettings({ columnCount: newColCount })
    }
  };

  useEffect(() => {
    if (isReaderInstanceSaved) {
      setIsEBookLoaded(true);
      // window.addEventListener('resize', updateOrientation);
    }
    return () => {
      // window.removeEventListener('resize', updateOrientation);
    }
  }, [isReaderInstanceSaved]);

  useEffect(() => {
    if (readerRef.current) {
      readerRef.current.navigator.handleResize();
    }
  }, [zoomLevel]);

  useEffect(() => {
    if (readerRef.current && goToPage) {
      readerRef.current.goToPosition(goToPage);
    }
  }, [goToPage]);

  // wait for reader
  useEffect(() => {
    waitForReadium();
    
    // Clean up
    return () => {
      if (isEBookLoaded) {
        updateEBookDataAction({ totalPages: 0, currentPage: 1, skipHistoryUpdate: true });
        setIsEBookLoaded(false);
      }
    };
  }, []);

  function getReadiumAppearance() {
    switch (activeTheme) {
      case READER_THEMES.dark:
        return 'readium-night-on';
      case READER_THEMES.sepia:
        return 'readium-sepia-on';
      default:
        return 'readium-default-on';
    }
  }

  function getReadiumColumnCount() {
    if (activeQueueItem.fixedLayout) {
      return window.innerWidth < 1600 ? '1' : '1'; // ["auto" or "-1", "1", "2"]
    }
    return 'auto';
  }

  const loadEbookAsset = useCallback(
    (D2Reader) => {
      const { downloadUrl, assetId, trackId, contentType, fixedLayout } = activeQueueItem ?? {};
      if (downloadUrl && isEbookContentType({ contentType })) {
        Promise.all([getMediaKey(downloadUrl), loadLocations(assetId)])
          .then(([{ key }, { bookmarks, history, annotations }]) => {
            if (key) {
              D2Reader.load({
                url: new URL(`${key}/manifest.json`),
                injectables: injectables,
                injectablesFixed: [],
                initialAnnotations: {
                  bookmarks: bookmarks,
                  highlights: annotations,
                },
                userSettings: {
                  verticalScroll: 'readium-scroll-off',
                  // lineHeight: 1.5,
                  textAlignment: 'auto', // ["auto","justify","start"]
                  columnCount: fixedLayout ? '1' : 'auto', //getReadiumColumnCount(), // fixedLayout ? 'auto' : 'auto', // ["auto", "1", "2"]
                  fontFamily: 'Original', // ["Original","serif","sans-serif"] ["Original", "Helvetica Neue", "Iowan Old Style", "Athelas", "Seravek", "OpenDyslexic", "AccessibleDfA", "IA Writer Duospace"]
                  // fontOverride: "readium-font-off", // [readium-font-off, readium-font-on]
                  advancedSettings: "readium-advanced-off", // [readium-advanced-on, readium-advanced-off]
                  appearance: getReadiumAppearance(), //["readium-default-on", "readium-sepia-on", "readium-night-on"]
                },
                rights: {
                  autoGeneratePositions: true,
                  enableAnnotations: true,
                  enableBookmarks: true,
                  enableContentProtection: true,
                  enableDefinitions: true,
                  enableLineFocus: true,
                  enableMediaOverlays: true,
                  enablePageBreaks: false,
                  enableSearch: true,
                  enableTTS: true,
                  enableTimeline: true,
                  customKeyboardEvents: false,
                  enableHistory: true,
                  enableCitations: true,
                },
                attributes: {
                  margin: fixedLayout ? 0 : 0,
                  navHeight: fixedLayout ? 0 : 30, // number,
                  iframePaddingTop: fixedLayout ? 0 : 50,
                  bottomInfoHeight: 0, // number,
                  sideNavPosition: 'left', // "left" | "right";
                },
                contentBytesLengthService: fixedLayout
                  ? undefined
                  : function (href, requestConfig) {
                      window.contentBytesLengths = window.contentBytesLengths || [];
                      window.contentBytesLengths[key] =
                        window.contentBytesLengths[key] ||
                        fetch(`${key}/contentBytesLength.json`).then((result) => result.json());

                      return window.contentBytesLengths[key].then((content) => content[href.substring(key.length + 1)]);
                    },
                annotations: {
                  enableComments: true,
                  // initialAnnotationColor: "var(--RS__highlightColor)",
                  initialAnnotationColor: '#ff8500',
                  hideLayer: false,
                  api: {
                    addAnnotation: function (highlight) {
                      highlight.id = uuidv4().toUpperCase();
                      return new Promise(function (resolve, reject) {
                        resolve(saveAnnotationLocation(assetId, highlight));
                      });
                    },
                    deleteAnnotation: function (highlight) {
                      return new Promise(function (resolve, reject) {
                        resolve(deleteAnnotationLocation(assetId, highlight));
                      });
                    },
                    selectedAnnotation: function (highlight) {
                      
                      readerRef.current.highlighter.isSelectionMenuOpen = true;
                      return new Promise(function (resolve, reject) {
                        resolve(highlight);
                      });
                    },
                    updateAnnotation: function (highlight) {
                      return new Promise(function (resolve, reject) {
                        resolve(updateAnnotationLocation(assetId, highlight));
                      });
                    },
                    addCommentToAnnotation: function (highlight) {
                      let h = swal({
                        // text: "Update or Add Note Here:",
                        content: {
                          element: 'textarea',
                          attributes: {
                            value: highlight.highlight.note ?? '',
                          },
                        },
                        buttons: true,
                      }).then((value) => {
                        value = document.querySelector('.swal-content__textarea').value;
                        highlight.highlight.note = value;
                        swal.stopLoading();
                        swal.close();
                        return highlight;
                      });
                      return new Promise(function (resolve, reject) {
                        resolve(h);
                      });
                    },
                  },
                },
                bookmarks: {
                  // hideLayer:true,
                  api: {
                    addBookmark: function (bookmark) {
                      return new Promise(function (resolve, reject) {
                        bookmark.id = Math.random();
                        resolve(saveBookmarkLocation(assetId, bookmark));
                      });
                    },
                    deleteBookmark: function (bookmark) {
                      return new Promise(function (resolve, reject) {
                        resolve(deleteBookmarkLocation(assetId, bookmark));
                      });
                    },
                  },
                },
                protection: {
                  enforceSupportedBrowsers: false,
                  enableEncryption: true,
                  enableObfuscation: true,
                  disablePrint: false,
                  disableCopy: false,
                  canCopy: true,
                  charactersToCopy: 2500,
                  detectInspect: false,
                  clearOnInspect: false, // make sure detectInspect is true, otherwise this won't work
                  detectInspectInitDelay: 10,
                  disableKeys: true,
                  disableContextMenu: false,
                  hideTargetUrl: false,
                  disableDrag: false,
                  supportedBrowsers: [
                    // this will only be used if enforceSupportedBrowsers is true, and has nothing to do with the r2d2bc modules supported browsers
                    'Chrome',
                    'ChromeAndroid',
                    'Edge',
                    'Firefox',
                    'iOS',
                    'Safari',
                  ],
                  excludeNodes: [],

                  api: {
                    // make sure detectInspect is true, otherwise this won't be called
                    // this callback will be executed in a loop while inspect is visible
                    inspectDetected: function () {
                      console.log('inspect detected');
                    },
                  },
                },
                mediaOverlays: {
                  autoScroll: true,
                  autoTurn: true,
                  // color: "redtext",
                  volume: 0.7,
                  rate: 1.2,
                  wait: 1,
                  // hideLayer:true,
                  api: {},
                },
                api: {
                  getContent: function (href) {
                    return new Promise(async function (resolve) {
                        await fetch(href)
                          .then((r) => r.text())
                          .then(async (data) => {
                              resolve(data);
                          });
                    });
                  },
                  updateCurrentLocation: function (location) {
                    getCurrentBookmark(readerRef.current) ? setIsBookmarkActive(true) : setIsBookmarkActive(false);
                    handleTimelineVisibleRangeChanged(location);

                    return new Promise(function (resolve, reject) {
                      resolve(location);
                    });
                  },
                  updateSettings: function (settings) {
                    return new Promise(function (resolve, reject) {
                      resolve(settings);
                    });
                  },
                  resourceReady: function () {
                    if (history?.progress && !goToPage) {
                      setGoToPage(history?.progress);
                    }
                  },
                  onError: function (error) {
                    console.log(error);
                  },
                },
              })
              .then((instance) => {
                instance.navigator.handleClickThrough = handleClick;
                readerRef.current = instance;
                setIsReaderInstanceSaved(true);

              })
              .catch((error) => {
                console.error({ error });
                return swal({
                  text: error.message,
                }).then((value) => {
                  resetPlayerAction();
                });
              });
            }
          })
          .catch((error) => {
            let text = error.response.data.error ?? error.message;
            return swal({
              text: text,
              focusConfirm: false,
            }).then((value) => {
              resetPlayerAction();
            });
          });
      }

      return () => {
        updateEBookDataAction({ totalPages: 0, currentPage: 1, skipHistoryUpdate: true });
      };
    },
    [activeQueueItem],
  );

  const renderControlPanel = useCallback(
    () =>
      isEBookLoaded ? (
        <ControlPanel
          readerRef={readerRef}
          activeTheme={activeTheme}
          isBookmarkActive={isBookmarkActive}
          toggleBookmark={toggleBookmark}
          toggleZoom={toggleZoom}
          isZoomActive={zoomLevel > 200}
        />
      ) : null,
    [activeTheme, toggleBookmark, isBookmarkActive, isEBookLoaded, toggleZoom, zoomLevel],
  );

  const handleTimelineChange = useCallback(
    (newPageNumber) => {
      readerRef?.current?.goToPosition?.(newPageNumber);
    },
    [readerRef],
  );

  return (
    <>
      <Helmet>
        {/* <link rel="stylesheet" href="https://cdn.muicss.com/mui-latest/css/mui.min.css" /> */}
        <script src="https://cdn.muicss.com/mui-latest/js/mui.min.js"></script>
        <script src="https://code.jquery.com/jquery-2.1.4.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/sweetalert/2.1.2/sweetalert.min.js"></script>

        <link rel="stylesheet" type="text/css" href={`${EPUB_READER_FOLDER}/injectables/mui/style.css`} />
        <link rel="stylesheet" type="text/css" href={`${EPUB_READER_FOLDER}/injectables/style/linefocus.css`} />
        <link rel="stylesheet" type="text/css" href={`${EPUB_READER_FOLDER}/injectables/style/popover.css`} />
        <link rel="stylesheet" type="text/css" href={`${EPUB_READER_FOLDER}/injectables/style/popup.css`} />
        <link rel="stylesheet" type="text/css" href={`${EPUB_READER_FOLDER}/injectables/style/style.css`} />
        <link rel="stylesheet" type="text/css" href={`${EPUB_READER_FOLDER}/css/reader.css`} />

        <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Helmet>
      {activeQueueItem && (
        <Fade in={!isDistractionFreeMode}>
          <Box className={classes.readerHeaderContainer}>
            <FullScreenHeader
              height={99}
              item={activeQueueItem}
              onClose={closeEbookReader}
              nameClassName={classes.textThemeColor}
              creatorClassName={classes.textThemeColor}
              renderControlPanel={renderControlPanel}
            />
          </Box>
        </Fade>
      )}
      <Box className={classes.readerViewContainer}>
        {!isTouchableDevice && (
          <Box className={clsx(classes.arrowBg, classes.leftArrow)}>
            <IconButton onClick={goToPrevPage} className={classes.arrowIconBtn}>
              <ArrowBackIosIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
        {!isTouchableDevice && (
          <Box className={clsx(classes.arrowBg, classes.rightArrow)}>
            <IconButton onClick={goToNextPage} className={classes.arrowIconBtn}>
              <ArrowForwardIosIcon className={classes.icon} />
            </IconButton>
          </Box>
        )}
        <Box id="D2Reader-Container" className={classes.readerView}>
          <main  id="iframe-wrapper" tabIndex={-1} className={classes.iframeWrapper}>
            <div id="reader-loading" className={clsx(classes.loading, "loading")}></div>
            <div id="reader-error" className="error"></div>
            <div style={{ height: '0px' }}>
              <div id="highlight-toolbox" className="highlight-toolbox">
                <div id="highlight-toolbox-mode-colors">
                  <button style={{ backgroundColor: 'gainsboro' }} id="dismissIcon">
                    <i className="material-icons" style={{ color: 'gray' }}>
                      close
                    </i>
                  </button>
                </div>
                <div id="highlight-toolbox-mode-add">
                  <button style={{ display: 'unset' }} id="colorIcon" className="color-option">
                    <span />
                  </button>
                  <button style={{ backgroundColor: 'gainsboro' }} id="highlightIcon">
                    <span
                      style={{
                        background: 'yellow',
                        display: 'inline-block',
                        width: 24,
                        height: 24,
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        preserveAspectRatio="xMidYMid meet"
                        role="img"
                        className="icon open"
                        aria-labelledby="text-icon"
                      >
                        <title id="text-icon">Text</title>
                        <path d="M5 4v3h5.5v12h3V7H19V4z" />
                      </svg>
                    </span>
                  </button>
                  <button style={{ backgroundColor: 'gainsboro' }} id="underlineIcon">
                    <span
                      style={{
                        display: 'inline-block',
                        width: 24,
                        height: 24,
                        borderBottom: 'yellow solid 4px',
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        preserveAspectRatio="xMidYMid meet"
                        role="img"
                        className="icon open"
                        aria-labelledby="text-icon"
                      >
                        <title id="text-icon">Text</title>
                        <path d="M5 4v3h5.5v12h3V7H19V4z" />
                      </svg>
                    </span>
                  </button>
                </div>
                <div id="highlight-toolbox-mode-edit">
                  <button style={{ backgroundColor: 'gainsboro', display: 'none' }} id="commentIcon">
                    <i className="material-icons" style={{ color: 'black' }}>
                      insert_comment
                    </i>
                  </button>
                  <button style={{ backgroundColor: 'gainsboro', display: 'none' }} id="deleteIcon">
                    <i className="material-icons" style={{ color: 'black' }}>
                      delete
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </main>
          <div id="container-view-security" className="security" />
        </Box>
      </Box>
      <EbookTimeline
        onChange={handleTimelineChange}
        textClassName={classes.textThemeColor}
        isDistractionFreeMode={isDistractionFreeMode}
        timelineContainerClassName={classes.timelineContainerEpub}
      />
    </>
  );
});

PubhubReader.propTypes = {
  queue: queuePropType,
  ebookData: ebookDataPropType,
  activeIndex: PropTypes.number,
  resetPlayerAction: PropTypes.func.isRequired,
  updateEBookDataAction: PropTypes.func.isRequired,
  changeEbookReaderOpenAction: PropTypes.func.isRequired,
};

export default PubhubReader;
